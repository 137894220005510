import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { routeHome, routeMyLibrary, routeNewSettings, routeScreenManager, routeNewCuration } from "../../Routes";
import homeIcon from "../../assets/SideBarIcon/home.svg";
import screenManagerIcon from "../../assets/SideBarIcon/screen-manager.svg";
import libraryIcon from "../../assets/SideBarIcon/library.svg";
import curationIcon from "../../assets/SideBarIcon/curation.svg";
import settingsIcon from "../../assets/SideBarIcon/settings.svg";
import Image from "../Image/Image";
import { FlatList } from "react-native";
import { AsyncStorageKeys, Pages, WheelPosition } from "../../Types";
import useGenericContentStore from "../../store/genericContent.store";
import { globalAny } from "../../utils/Utils";
import useToggleStore from "../../store/useToggle.store";

interface StyledIconContainerProps {
	highlight?: boolean;
}

const StyledView = styled.View<any>`
	width: 120px;
	height: auto;
	box-sizing: border-box;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	backdrop-filter: blur(10px);
	${(props) =>
		props.wheelPosition === WheelPosition.RIGHT
			? css`
					right: 0;
				`
			: css`
					left: 0;
				`}
	top: 112px;
	bottom: 0;
	position: fixed;
	z-index: 0;
`;

const StyledIconContainer = styled.TouchableOpacity<StyledIconContainerProps>`
	height: 112px;
	width: 100%;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.highlight &&
		css`
			background-color: #2362be;
		`}
`;

const StyledLoader = styled.View`
	height: 56px;
	width: 56px;
	border-radius: 16px;
	overflow: hidden;
	position: absolute;
`;

enum SelectedNav {
	Home = "Home",
	ScreenManager = "ScreenManager",
	Library = "MyLibrary",
	Curation = "StudioAccess",
	Settings = "Settings",
}

interface Props {}

const SideNav = (props: any) => {
	const [highlightHome, setHighlightHome] = useState(false);
	const [highlightScreenManager, setHighlightScreenManager] = useState(false);
	const [highlightLibrary, setHighlightLibrary] = useState(false);
	const [highlightCuration, setHighlightCuration] = useState(false);
	const [highlightSettings, setHighlightSettings] = useState(false);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const isToggleMQTT = useToggleStore((state: any) => state.isToggleMQTT);
	const wheelPosition = localStorage.getItem(AsyncStorageKeys.wheelPosition);

	const navigation = useNavigation();
	const item = isToggleMQTT
		? [
				{ name: SelectedNav.Home, icon: homeIcon, highlight: highlightHome },
				{ name: SelectedNav.ScreenManager, icon: screenManagerIcon, highlight: highlightScreenManager },
				{ name: SelectedNav.Library, icon: libraryIcon, highlight: highlightLibrary },
				{ name: SelectedNav.Curation, icon: curationIcon, highlight: highlightCuration },
				{ name: SelectedNav.Settings, icon: settingsIcon, highlight: highlightSettings },
			]
		: [
				{ name: SelectedNav.Home, icon: homeIcon, highlight: highlightHome },
				{ name: SelectedNav.Library, icon: libraryIcon, highlight: highlightLibrary },
				{ name: SelectedNav.Curation, icon: curationIcon, highlight: highlightCuration },
				{ name: SelectedNav.Settings, icon: settingsIcon, highlight: highlightSettings },
			];

	useEffect(() => {
		let mounted = true;

		if (mounted) onSelectedHighlight(props.currentSelected);

		return () => {
			mounted = false;
		};
	}, []);

	const onSelectedHighlight = (value: SelectedNav | string) => {
		setHighlightHome(value === globalAny.language.header_home);
		setHighlightScreenManager(value === SelectedNav.ScreenManager);
		setHighlightLibrary(value === SelectedNav.Library);
		setHighlightCuration(value === SelectedNav.Curation);
		setHighlightSettings(value === globalAny.language.header_settings);
	};

	const onSelectedNav = (name: any) => {
		switch (name) {
			case SelectedNav.Home:
				//@ts-ignore
				navigation.navigate(routeHome);
				setSelectedTabs(0);
				break;

			case SelectedNav.ScreenManager:
				//@ts-ignore
				navigation.navigate(routeScreenManager, { location: Pages.main, parentProductId: Pages.controller, redirectTo: "screen" });
				setSelectedTabs(0);
				break;

			case SelectedNav.Settings:
				//@ts-ignore
				navigation.navigate(routeNewSettings);
				setSelectedTabs(0);
				break;

			case SelectedNav.Curation:
				//@ts-ignore
				navigation.navigate(routeNewCuration);
				setSelectedTabs(0);
				break;

			case SelectedNav.Library:
				//@ts-ignore
				navigation.navigate(routeMyLibrary);
				setSelectedTabs(0);
				break;

			default:
				break;
		}
	};

	const renderIcon = ({ item }: any) => (
		// @ts-ignores
		<StyledIconContainer key={`sidenav-list-${item.name}`} highlight={item.highlight} onPress={() => onSelectedNav(item.name)}>
			<Image source={{ uri: item.icon }} width={56} height={56} key={`sidenav-icon-${item.name}`} />
		</StyledIconContainer>
	);

	const renderNav = useMemo(
		() => (
			<FlatList
				showsVerticalScrollIndicator={false}
				data={item}
				extraData={item}
				renderItem={renderIcon}
				keyExtractor={(item: any) => item.name}
				style={{ width: "100%" }}
			/>
		),
		[item],
	);

	return (
		// @ts-ignore
		<StyledView wheelPosition={wheelPosition}>{renderNav}</StyledView>
	);
};

export default SideNav;

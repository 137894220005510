import SettingsContainer from "../../components/Layout/SettingsContainer";
import LabelContainer from "../../components/SettingsComponent/labelContainer";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import TouchableButton from "../../components/TouchableButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components/native";
import { spacing_l } from "../../StyleHelpers";
import { StyleSheet, View } from "react-native";
import H4 from "../../components/Typography/H4";
import { Dropdown } from "react-native-element-dropdown";
import localStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys, CarActivity } from "../../Types";
import ButtonToggle from "../../components/ButtonToggle";
import iconChevron from "../../assets/Icons/icon_chevron-down.svg";
import Image from "../../components/Image/Image";
import Toast from "../../components/Toast";
import { checkApiKey } from "../../services/apiKeyService";
import { removeCacheItem } from "../../utils/CacheStorageUtils";
import useGenericContentStore from "../../store/genericContent.store";
import ENVIRONMENT from "../../config/ENVIRONMENT";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";
import useDrivingStore from "../../store/useDriving.store";
import languagesList from "../../assets/Language/languages.json";
import localeByCountry from "../../assets/Language/localeByCountry.json";
import { globalAny } from "../../utils/Utils";
import { AppLogger } from "../../utils/AppLogger";
import useLegalContentStore from "../../store/useLegalContent.store";

interface LanguageModel {
	value: string;
	label: string;
}
interface RegionsModel {
	value: string;
	label: string;
}
interface CountryModel {
	shortName: string;
	name: string;
	native: string;
	phone: string;
	continent: string;
	capital: string;
	currency: string;
	languages: string[];
	emoji: string;
	emojiU: string;
}

const StyledContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
`;

const StyledCenteredContainer = styled.View`
	flex-direction: row;
	justify-content: center;
	margin-bottom: 8%;
`;
const PaddedContainer = styled.View`
	flex-direction: row;
	justify-content: start;
`;

const Label = styled(LabelContainer)`
	padding-bottom: 0px;
`;
const StyledButton = styled(TouchableButton)`
	max-width: 23%;
`;

const StyledPartialSettingsContainer = styled.View`
	padding-bottom: 30px;
`;

const StyledMarginTop = styled.View`
	margin-top: 30px;
`;

const SettingsAdvance = () => {
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setCarActivity = useDrivingStore((state: any) => state.setCarActivity);
	const isEnabledFloatingButton = useGenericContentStore((state: any) => state.isEnabledFloatingButton);
	const setIsEnabledFloatingButton = useGenericContentStore((state: any) => state.setIsEnabledFloatingButton);
	const [selectedRegion, setSelectedRegion] = useState("Unset");
	const [selectedLanguage, setSelectedLanguage] = useState("Unset");
	const [selectedEnvironment, setSelectedEnvironment] = useState("Unset");
	const [selectedCarActivity, setSelectedCarActivity] = useState<any>(carActivity);
	const [disableMqtt, setDisableMqtt] = React.useState(true);
	const [disableReload, setDisableReload] = React.useState(false);
	const [enableFloatingButton, setEnableFloatingButton] = React.useState<any>(isEnabledFloatingButton);
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState("");
	const [countryData, setCountryData] = useState<any>([]);
	const [languages, setLanguages] = useState<any>([]);
	const onDismissSnackBar = () => setVisible(false);
	const fetchLegals = useLegalContentStore((state: any) => state.fetchLegals);
	const fetchAcceptOtherData = useLegalContentStore((state: any) => state.fetchAcceptOtherData);

	const getCountries = () => {
		const data = localeByCountry.map((item: any) => {
			let languageCode = item.locales.toUpperCase();
			let countryName = `${item.en_name} - ${item.country.toUpperCase()}`;
			let countryCode = item.country.toUpperCase();
			return {
				id: languageCode,
				label: countryName,
				value: countryCode,
			};
		});

		setCountryData(data);
	};

	const getLanguages = () => {
		const data = languagesList.map((item: any) => {
			let languageCode = item.code;
			return {
				id: languageCode,
				label: item.language,
				value: languageCode,
			};
		});

		setLanguages(data);
	};

	const getLocaleByCountry = (countryCode: string) => {
		if (countryCode.includes(",")) {
			const multipleCountryCode = countryCode.split(",");
			const multipleLanguages = multipleCountryCode.map((code) => filteredLanguages(code));
			setLanguages(multipleLanguages);
			return;
		}

		setLanguages([filteredLanguages(countryCode)]);
	};

	const filteredLanguages = (countryCode: string) => {
		const [locale] = languagesList.filter((val) => val.code.trim() === countryCode.trim());
		let languageCode = locale.code;
		return {
			id: languageCode,
			label: locale.language,
			value: languageCode,
		};
	};

	const initializeCountries = async () => {
		const regionCode: any = await localStorage.getItem(AsyncStorageKeys.RegionOverride);
		setSelectedRegion(regionCode);
		const langCode: any = await localStorage.getItem(AsyncStorageKeys.LanguageOverride);
		setSelectedLanguage(langCode);

		localStorage.getItem(AsyncStorageKeys.EnvironmentOverride).then((value) => {
			setSelectedEnvironment(value ?? "Unset");
		});

		configureMqttSettings();
	};

	const configureMqttSettings = async () => {
		const disableMqttStore = sessionStorage.getItem("disableMqtt");

		setDisableMqtt(disableMqttStore === "true");
	};

	const setLanguageOverride = (lang: string) => {
		setSelectedLanguage(lang);
	};

	const setRegionOverride = (region: string, id: string) => {
		getLocaleByCountry(id);
		setSelectedRegion(region);
	};

	function setEnvironmentOverride(env: string) {
		setSelectedEnvironment(env);
		localStorage.setItem(AsyncStorageKeys.EnvironmentOverride, env);
	}
	function unsetLanguageOverride() {
		setSelectedLanguage("Unset");
	}

	function unsetRegionOverride() {
		setSelectedRegion("Unset");
	}

	function unsetEnvironmentOverride() {
		setSelectedEnvironment("Unset");
		localStorage.removeItem(AsyncStorageKeys.EnvironmentOverride);
	}

	function setDisableMqttOverride(disable: boolean) {
		// @ts-ignore
		sessionStorage.setItem("disableMqtt", disable);
		setDisableMqtt(disable);
	}

	const onReload = async () => {
		const result: any = await checkApiKey(selectedRegion);

		if (result?.apiKey && result?.responseCode) {
			removeCacheItem(AsyncStorageKeys.languageJson);
			removeCacheItem(AsyncStorageKeys.session);
			removeCacheItem(AsyncStorageKeys.sessionExpired);
			removeCacheItem(AsyncStorageKeys.email);
			removeCacheItem(AsyncStorageKeys.idleExpiry);
			removeCacheItem(AsyncStorageKeys.refreshToken);
			removeCacheItem(AsyncStorageKeys.subscription);
			removeCacheItem(AsyncStorageKeys.svodCountryId);
			localStorage.setItem(AsyncStorageKeys.LanguageOverride, selectedLanguage);
			localStorage.setItem(AsyncStorageKeys.RegionOverride, selectedRegion);
			localStorage.setItem(AsyncStorageKeys.isOverride, "true");
			document.documentElement.setAttribute("lang", selectedLanguage);

			try {
				await fetchLegals(AsyncStorageKeys.legals);
				await fetchAcceptOtherData(AsyncStorageKeys.acceptOtherData);
			} catch (err) {
				AppLogger.log(err);
			}
			window.location.replace("/landing");

			return;
		}

		setToastMessage(globalAny.language.territory_lang_not_supported);
		setLabelText("");
		setVisible(true);
	};

	useEffect(() => {
		if (selectedLanguage === "Unset" || selectedRegion === "Unset") {
			return setDisableReload(true);
		}
		setDisableReload(false);
	}, [selectedRegion, selectedLanguage]);

	useEffect(() => {
		const initCarActivity = async () => {
			setSelectedCarActivity(carActivity);
			setEnableFloatingButton(isEnabledFloatingButton);
		};
		initCarActivity();
	}, []);

	useEffect(() => {
		localStorage.setItem(AsyncStorageKeys.carActivity, selectedCarActivity);
		setCarActivity(selectedCarActivity);
	}, [selectedCarActivity]);

	useEffect(() => {
		localStorage.setItem(AsyncStorageKeys.carActivity, carActivity);
	}, [carActivity]);

	useEffect(() => {
		localStorage.setItem(AsyncStorageKeys.floatingButton, enableFloatingButton);
		setIsEnabledFloatingButton(enableFloatingButton);
	}, [enableFloatingButton]);

	//@ts-ignore
	useEffect(() => {
		getCountries();
		getLanguages();
	}, []);

	useEffect(() => {
		initializeCountries();
	}, []);

	return (
		<View>
			{/* @ts-ignore */}
			<StyledMarginTop>
				<SettingsContainer>
					<LabelContainer body={"Clicking UNSET will revert to the default/detected setting."} />
					{/* @ts-ignore */}
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						/* @ts-ignore */
						<StyledPartialSettingsContainer>
							<LabelContainer header={"Environment"} />

							{/* @ts-ignore */}
							<StyledContainer>
								<StyledButton
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "Unset" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={unsetEnvironmentOverride}
								>
									UNSET
								</StyledButton>
								<StyledButton
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "STAGING" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => setEnvironmentOverride("STAGING")}
								>
									STAGING
								</StyledButton>
								<StyledButton
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "UAT" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => setEnvironmentOverride("UAT")}
								>
									UAT
								</StyledButton>
								<StyledButton
									componentTheme={ComponentThemeType.VinFast}
									type={selectedEnvironment == "PRODUCTION" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
									onPress={() => setEnvironmentOverride("PRODUCTION")}
								>
									PRODUCTION
								</StyledButton>
							</StyledContainer>
						</StyledPartialSettingsContainer>
					) : (
						<></>
					)}
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<LabelContainer header={"Car Activity"} />

						{/* @ts-ignore */}
						<PaddedContainer>
							<StyledButton
								componentTheme={ComponentThemeType.VinFast}
								type={carActivity === CarActivity.INMOTION ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={() => setSelectedCarActivity(CarActivity.INMOTION)}
							>
								{" "}
								IN-MOTION{" "}
							</StyledButton>
							<StyledButton
								componentTheme={ComponentThemeType.VinFast}
								type={carActivity === CarActivity.PARKED ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={() => setSelectedCarActivity(CarActivity.PARKED)}
								style={{
									marginLeft: spacing_l,
								}}
							>
								{" "}
								PARKED{" "}
							</StyledButton>
						</PaddedContainer>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<View
							style={{
								alignItems: "center",
								flexBasis: "100%",
								width: "100%",
								flexDirection: "row",
								justifyContent: "flex-start",
								paddingVertical: 15,
							}}
						>
							<ButtonToggle defaultValue={enableFloatingButton} onPress={(state) => setEnableFloatingButton(state)} />
							<View style={{ marginLeft: "2%" }}>
								<H4>Show this as a floating button</H4>
							</View>
						</View>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<Label header={"Territory"} />

						{/* @ts-ignore */}
						<PaddedContainer>
							<StyledButton
								componentTheme={ComponentThemeType.VinFast}
								type={selectedRegion == "Unset" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={unsetRegionOverride}
							>
								{" "}
								UNSET{" "}
							</StyledButton>

							<Dropdown
								style={[styles.dropdown]}
								placeholderStyle={styles.placeholderStyle}
								selectedTextStyle={styles.selectedTextStyle}
								inputSearchStyle={styles.inputSearchStyle}
								searchPlaceholder="Search..."
								data={countryData}
								labelField={"label"}
								valueField={"value"}
								dropdownPosition={"bottom"}
								search
								onChange={(item) => setRegionOverride(item.value, item.id)}
								value={selectedRegion}
								renderRightIcon={() => (
									<Image
										source={{
											uri: iconChevron,
										}}
										resizeMode="stretch"
										width={24}
										height={24}
									/>
								)}
							/>
						</PaddedContainer>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					<StyledPartialSettingsContainer>
						<LabelContainer header={"Language"} />

						{/* @ts-ignore */}
						<PaddedContainer>
							<StyledButton
								componentTheme={ComponentThemeType.VinFast}
								type={selectedLanguage == "Unset" ? ComponentTypeEnum.Primary : ComponentTypeEnum.Secondary}
								onPress={unsetLanguageOverride}
							>
								{" "}
								UNSET{" "}
							</StyledButton>

							<Dropdown
								style={[styles.dropdown]}
								placeholderStyle={styles.placeholderStyle}
								selectedTextStyle={styles.selectedTextStyle}
								inputSearchStyle={styles.inputSearchStyle}
								searchPlaceholder="Search..."
								data={languages}
								labelField={"label"}
								valueField={"value"}
								dropdownPosition={"bottom"}
								search
								onChange={(item) => setLanguageOverride(item.id)}
								value={selectedLanguage}
								renderRightIcon={() => (
									<Image
										source={{
											uri: iconChevron,
										}}
										resizeMode="stretch"
										width={24}
										height={24}
									/>
								)}
							/>
						</PaddedContainer>
					</StyledPartialSettingsContainer>
					{/* @ts-ignore */}
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						//@ts-ignore
						<StyledPartialSettingsContainer>
							<LabelContainer header={"Theme"} />
							{/* @ts-ignore */}
							<PaddedContainer>
								<StyledButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary}>
									DARK
								</StyledButton>
								<StyledButton
									componentTheme={ComponentThemeType.VinFast}
									type={ComponentTypeEnum.Secondary}
									disabled={true}
									style={{
										marginLeft: spacing_l,
									}}
								>
									LIGHT
								</StyledButton>
							</PaddedContainer>
						</StyledPartialSettingsContainer>
					) : (
						<></>
					)}
					{/* @ts-ignore */}
					{DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
						//@ts-ignore
						<StyledPartialSettingsContainer>
							<View
								style={{
									alignItems: "center",
									flexBasis: "100%",
									width: "100%",
									flexDirection: "row",
									justifyContent: "flex-start",
									paddingVertical: 15,
								}}
							>
								<ButtonToggle defaultValue={!disableMqtt} onPress={(state) => setDisableMqttOverride(!state)} />
								<View style={{ marginLeft: "2%" }}>
									<H4>MQTT Functionality</H4>
								</View>
							</View>
						</StyledPartialSettingsContainer>
					) : (
						<></>
					)}
				</SettingsContainer>

				{/* @ts-ignore */}
				<StyledCenteredContainer>
					<StyledButton
						componentTheme={ComponentThemeType.VinFast}
						disabled={disableReload}
						type={ComponentTypeEnum.Secondary}
						onPress={onReload}
					>
						{" "}
						Reload{" "}
					</StyledButton>
				</StyledCenteredContainer>
			</StyledMarginTop>
			<Toast visible={visible} text={toastMessage} label={labelText} onDismissSnackBar={onDismissSnackBar}></Toast>
		</View>
	);
};

export default SettingsAdvance;

const styles = StyleSheet.create({
	centeredContainer: {
		backgroundColor: "#111111",
	},
	container: {
		backgroundColor: "#111111",
	},
	dropdownContainer: {
		paddingHorizontal: 30,
		paddingBottom: 20,
	},
	dropdown: {
		height: "72px",
		width: "75%",
		borderColor: "#2362be",
		borderWidth: 2,
		fontSize: 18,
		borderRadius: 16,
		paddingHorizontal: 24,
		marginLeft: spacing_l,
		backgroundColor: "black",
	},
	icon: {
		marginRight: 5,
	},
	placeholderStyle: {
		fontSize: 16,
		color: "white",
	},
	selectedTextStyle: {
		fontSize: 24,
		color: "white",
	},
	inputSearchStyle: {
		padding: 10,
		height: 40,
		fontSize: 18,
		borderRadius: 8,
		color: "black",
		borderWidth: 0,
	},
});

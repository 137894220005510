import { USER_BASEURL_V6, USER_BASEURL_V7 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const getLegals = () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "Legals/", { headers: getHeaders() })
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

export const getOptions = (type: number) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V7 + "options?type=" + type, { headers: getHeaders() })
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

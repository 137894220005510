import { Animated, Easing } from "react-native";
import { useEffect, useRef, useState } from "react";
import { BtnToggleProps } from "./model";
import { StyledButton, StyledInnerButton, StyledText } from "./styled";
import { globalAny } from "../../utils/Utils";

const AnimatedView = (props: any) => {
	const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
	const animDuration = 100;

	useEffect(() => {
		if (props.enabled) {
			Animated.timing(
				fadeAnim,
				// @ts-ignore
				{
					toValue: 0,
					easing: Easing.quad,
					duration: animDuration,
					useNativeDriver: true,
				},
			).start();
		} else {
			Animated.timing(
				fadeAnim,
				// @ts-ignore
				{
					toValue: 100,
					easing: Easing.quad,
					duration: animDuration,
					useNativeDriver: true,
				},
			).start();
		}
	}, [fadeAnim, props.enabled]);

	return (
		<Animated.View // Special animatable View
			style={{
				...props.style,
				width: "80px",
				position: "absolute",
				top: "-1%",
				height: "100%",
				borderRadius: "8px",
				left: fadeAnim.interpolate({
					inputRange: [0, 100],
					outputRange: ["-1%", "27%"],
				}), // Bind opacity to animated value
			}}
		>
			{props.children}
		</Animated.View>
	);
};

const ButtonToggle = (props: BtnToggleProps) => {
	const [disabled, setDisabled] = useState(false);

	const onBtnPress = () => {
		setDisabled(!disabled);

		if (props.onPress) {
			props.onPress(disabled);
		}
	};

	useEffect(() => {
		if (props.defaultValue != undefined) {
			setDisabled(!props.defaultValue);
		}
	}, [props.defaultValue]);

	// Remove this once done
	useEffect(() => {
		if (props.onTrigger != undefined) {
			setDisabled(!props.onTrigger);
		}
	}, [props.onTrigger]);

	return (
		// @ts-ignore
		<StyledButton btnDisabled={disabled} onPress={props.toggleDisabled ? () => {} : onBtnPress}>
			<AnimatedView enabled={disabled}>
				{/* @ts-ignore */}
				<StyledInnerButton btnDisabled={disabled} onPress={props.toggleDisabled ? () => {} : onBtnPress}>
					{/* @ts-ignore */}
					<StyledText>{disabled ? globalAny.language.off : globalAny.language.on}</StyledText>
				</StyledInnerButton>
			</AnimatedView>
		</StyledButton>
	);
};

export default ButtonToggle;

import styled, { css } from "styled-components/native";
import styledRN from "rn-css";
import { black, spacing_l, spacing_m, white } from "../../StyleHelpers";
import { StyleSheet } from "react-native";
import { Props } from "./model";

//#region TDP index styles
export const StyledContainerTdp = styled.View`
	height: 100vh;
	background-color: ${black};
`;

export const StyledTabsContainer = styled.View`
	width: 100%;
	background-color: ${black};
`;

export const StyledMain = styled.View<Props>`
	height: 100%;
	width: 100%;
	z-index: -1;
	position: relative;

	${(props) =>
		!props.bgImage &&
		css`
			background-color: black;
		`}

	${(props) =>
		props.bgImage &&
		css`
			background-image: url(${props.bgImage});
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
		`}
`;

export const StyledMarquee = styled.View<any>`
	cursor: ${(props) => (props.hover ? "pointer" : "normal")};
`;

export const TabsContainer = styled.View`
	margin: 120px 0 0 0;
	position: absolute;
	width: 100%;
	z-index: -1;
	background-color: black;
`;

export const StyledContainerColumn = styled.View`
	flex-direction: row;
	height: 100%;
`;

export const StyledImage = styled.View`
	margin-top: ${spacing_l};
	display: flex;
`;

export const StyledPackshot = styledRN.View`
	width: 420px;
	height: 630px;
	border-radius: 16px;
	overflow: hidden;
	position: absolute;

	@media (max-width: 640px) {
		margin-top:50px;
		width: 160px;
		height: 270px;
	}
	@media (min-width: 640px) {
		width: 300px;
		height: 480px;
	}
	@media (min-width: 768px) {
		margin-left:60px;
		width: 320px;
		height: 500px;
	}
	@media (min-width: 1536px) {
		margin-left:112px;
		width: 420px;
		height: 630px;
	}
`;

export const StyledLoading = styledRN.View`
	width: 420px;
	height: 630px;
	border-radius: 16px;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	@media (max-width: 640px) {
		margin-top:50px;
		width: 160px;
		height: 270px;
	}
	@media (min-width: 640px) {
		width: 300px;
		height: 480px;
	}
	@media (min-width: 768px) {
		margin-left:60px;
		width: 300px;
		height: 480px;
	}
	@media (min-width: 1536px) {
		margin-left:112px;
		width: 420px;
		height: 630px;
	}
`;
//#endregion

//#region Details Style
export const StyledContainer = styled.View<{ isPreview: boolean }>`
	padding: ${(props) => (props.isPreview ? "0px 122px 70px 122px" : "0px 122px 0px 122px")};
	height: 100%;
	z-index: -1;
`;

export const StyledOverflow = styled.View`
	padding-right: 20px;
`;

export const StyledText = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${spacing_l};
`;

export const StyledCast = styled.Text`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const StyledColumn1 = styled.View`
	width: 15%;
`;

export const StyledColumn2 = styled.View`
	width: 81%;
`;

export const StyledColumnText = styled.Text`
	text-align: left;
	color: ${white};
`;

export const styles = StyleSheet.create({
	metadata: {
		color: "white",
	},
	button: {
		fontSize: 25,
		color: "white",
	},
});
//#endregion

// #region Extras Style
export const StyledContainerExtras = styled.View`
	padding: ${spacing_l} 0 8% 0;
	height: 100%;
	z-index: -1;
`;
//#endregion

// #region ViewDetails Style
export const StyledContainerViewDetails = styled.View`
	width: 58%;
	height: 100vh;
	padding: 0 0 0 122px;
	z-index: -1;
`;

export const StyledButtonLoader = styled.View`
	height: 72px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_m};
`;

export const StyledButtonContainer = styled.View<any>`
	width: 81%;
	${(props) =>
		!props.isButtonVisible
			? css`
					display: block;
				`
			: css`
					display: none;
				`}
`;

export const StyledButton = styled.View`
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

export const StyledButtonText = styled.View`
	left: -10;
`;

export const stylesViewDetails = StyleSheet.create({
	metadata: {
		color: "white",
	},
	button: {
		fontSize: 25,
	},
});
//#endregion

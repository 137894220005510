import { View, Text } from "react-native";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import { globalAny } from "../../utils/Utils";
import { StyledContainer, StyledGradient, StyledGridPackshots, StyledImage, StyledSecondaryText, StyledText } from "./styled";
import { Props } from "./model";
import { spacing_s } from "../../StyleHelpers";

const Banner = (props: Props) => {
	return (
		<View>
			{/* @ts-ignore */}
			<StyledContainer banner={props.banner}>
				{/* @ts-ignore */}
				<StyledText>
					<Text numberOfLines={1} ellipsizeMode="tail" style={{ width: 470, color: "white", fontSize: 30, marginBottom: spacing_s }}>
						{globalAny.language.empty_library}
					</Text>
					<Text numberOfLines={2} ellipsizeMode="tail" style={{ width: 470, color: "white", fontSize: 25 }}>
						{globalAny.language.bookmarks_content}
					</Text>
				</StyledText>
				{/* @ts-ignore */}
				<StyledImage>
					<StyledGradient />
				</StyledImage>
			</StyledContainer>
			{/* @ts-ignore */}
			<StyledSecondaryText>
				{props?.data && props?.data.length > 0 ? (
					<Text style={{ width: 495, color: "white", fontSize: 25 }}>{globalAny.language.popular_titles}</Text>
				) : (
					<></>
				)}
			</StyledSecondaryText>
			{props?.data && (
				// @ts-ignore
				<StyledGridPackshots>
					{props?.data?.length > 0 ? (
						<PlaylistContainer startIndex={0} setStartIndex={""} setIsShowLoader={""} categories={props?.data} hideTitle={true} />
					) : (
						<></>
					)}
				</StyledGridPackshots>
			)}
		</View>
	);
};

export default Banner;

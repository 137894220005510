import { globalAny } from "../../utils/Utils";
import { AsyncStorageKeys, CarActivity } from "../../Types";
import useLoginStore from "../../store/useLogin.store";
import useDrivingStore from "../../store/useDriving.store";
import useGenericContentStore from "../../store/genericContent.store";
import FloatingButton from "../FloatingButton";
import CarActivityDialog from "../CarActivityDialog";
import { useEffect, useState } from "react";
//@ts-ignore
const CarActivityWrapper = ({ children }) => {
	const isEnabledFloatingButton = useGenericContentStore((state: any) => state.isEnabledFloatingButton);
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const setCarActivity = useDrivingStore((state: any) => state.setCarActivity);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const [showDialog, setShowDialog] = useState(true);
	useEffect(() => {
		const session = localStorage.getItem(AsyncStorageKeys.session) ?? null;
		if (session) setShowDialog(false);
		localStorage.setItem(AsyncStorageKeys.carActivity, carActivity);
	}, [carActivity]);
	useEffect(() => {
		if (isLoggedIn) localStorage.setItem(AsyncStorageKeys.floatingButton, isEnabledFloatingButton);
	}, [isEnabledFloatingButton]);

	return (
		<>
			{children}
			<CarActivityDialog
				title={globalAny?.language?.car_in_motion_title}
				body={globalAny?.language?.car_in_motion_body_login}
				show={carActivity === CarActivity.INMOTION && showDialog}
			/>
			{isEnabledFloatingButton ? (
				<FloatingButton
					onPress={() => setCarActivity(carActivity === CarActivity.PARKED ? CarActivity.INMOTION : CarActivity.PARKED)}
					isActive={carActivity === CarActivity.INMOTION}
				/>
			) : (
				<></>
			)}
		</>
	);
};
export default CarActivityWrapper;

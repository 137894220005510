import { View } from "react-native";
import styled, { css } from "styled-components/native";
import ImgProgressBar from "./ImgProgressBar";
import { spacing_l, spacing_m, spacing_s } from "../../StyleHelpers";
import { v4 as uuidv4 } from "uuid";

interface Props {
	show?: boolean;
	data?: any;
	hideTitle?: boolean;
	isBanner?: boolean;
	isHeroBanner?: boolean;
	isPlaylist?: boolean;
	isVAM?: boolean;
	marginTop?: number;
	isPreview?: boolean;
}
interface StyledProps {
	isLandscape?: boolean;
	hideBanner?: boolean;
}

const StyledHeroBannerContainer = styled.View`
	margin: ${spacing_l} ${spacing_l} 0 ${spacing_l};
	aspect-ratio: 4 / 1;
`;

const StyledSwiperContainer = styled.View`
	margin: 0 0 ${spacing_l} ${spacing_l};
`;

const StyledLoadingContainer = styled.View`
	flex-direction: column;
`;

const StyledLoading = styled.View<StyledProps>`
	margin-right: ${spacing_m};
	border-radius: 16px;
	overflow: hidden;

	${(props) =>
		props.isLandscape &&
		css`
			width: 25vw;
			height: auto;
			aspect-ratio: 16 / 9;
		`}
	${(props) =>
		!props.isLandscape &&
		css`
			height: auto;
			width: 15vw;
			aspect-ratio: 2 / 3;
		`}
`;

const StyledTitle = styled.View<any>`
	margin: 40px 0 ${spacing_s} 0;
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
	width: 20vw;
	${(props) =>
		props.isVAM &&
		css`
			margin-top: 10px;
			width: 15vw;
		`}
`;

const StyledHeroBanner = styled.View<any>`
	border-radius: 16px;
	overflow: hidden;
	width: 100%;
	${(props) =>
		props?.isPreview &&
		css`
			width: 93%;
		`}
	aspect-ratio: 4 / 1;
`;

const StyledLoaderGrid = styled.View`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	gap: 5px;
`;

const CustomizeLoader = (props: Props) => {
	if (props.show) {
		return (
			<View
				style={{
					marginLeft: props?.isPreview ? "7.5rem" : 115,
					marginTop: props?.marginTop ? props.marginTop : 112,
				}}
			>
				{props.isBanner || props.isHeroBanner ? (
					// @ts-ignore
					<StyledHeroBannerContainer key={`customize-loader-herobanner-container-${uuidv4()}`}>
						{props.isBanner ? (
							//@ts-ignore
							<StyledTitle key={`customize-loader-herobanner-title-${uuidv4()}`}>
								<ImgProgressBar />
							</StyledTitle>
						) : (
							<></>
						)}
						{/* @ts-ignore */}
						<StyledHeroBanner isPreview={props?.isPreview} key={`customize-loader-herobanner-${uuidv4()}`}>
							<ImgProgressBar />
						</StyledHeroBanner>
					</StyledHeroBannerContainer>
				) : (
					<></>
				)}
				{props.isPlaylist ? (
					// @ts-ignore
					<StyledSwiperContainer>
						{props.data?.map((val: any) => {
							return (
								<View key={`customize-loader-${val.id}`}>
									{/* @ts-ignore  */}
									{!props.hideTitle && (
										// @ts-ignore
										<StyledTitle key={`customize-loader-title-${val.id}`}>
											<ImgProgressBar />
										</StyledTitle>
									)}
									{props.hideTitle && <View style={{ marginBottom: spacing_m }} key={`margin-loader-${val.id}`}></View>}
									{/* @ts-ignore  */}
									<StyledLoaderGrid>
										{val?.packshot.map((item: any) => {
											return (
												//@ts-ignore
												<StyledLoadingContainer key={`customize-loader-container-${item}`}>
													{/* @ts-ignore */}
													<StyledLoading key={`customize-loader-image-${item}`} isLandscape={val?.isLandscape}>
														<ImgProgressBar />
													</StyledLoading>
													{props.isVAM && (
														// @ts-ignore
														<StyledTitle isVAM={true} key={`vam-loader-title-${item}`}>
															<ImgProgressBar />
														</StyledTitle>
													)}
												</StyledLoadingContainer>
											);
										})}
									</StyledLoaderGrid>
								</View>
							);
						})}
					</StyledSwiperContainer>
				) : (
					<></>
				)}
			</View>
		);
	}
	return null;
};

export default CustomizeLoader;

import { useEffect, useState } from "react";
import { Metadata } from "../../models/Metadata";
import { useNavigation } from "@react-navigation/native";
import {
	routeSearchScreen,
	routeHome,
	routePlayer,
	routeVamPlayer,
	routeTitleDetails,
	TitleDetailsScreenNavigationProp,
	routeNewCuration,
} from "../../Routes";
import { View } from "react-native";
import { MainAppState, Pages, direction } from "../../Types";
import { globalAny, pushMainAppStateToBrowserHistory } from "../../utils/Utils";
import MainLayoutWithSideNav from "../../components/Layout/MainLayoutWithSideNav";
import ScreenManager from "./ScreenManager";
import AssignScreen from "../../components/Layout/AssignScreen";
import TopAppBar from "../../components/TopAppBar";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import IconLink from "../../assets/ControllerIcon/Link.svg";
import IconPause from "../../assets/ControllerIcon/Pause.svg";
import IconRestart from "../../assets/ControllerIcon/Restart.svg";
import IconStop from "../../assets/ControllerIcon/Stop.svg";
import IconPlay from "../../assets/ControllerIcon/Play.svg";
import useGenericContentStore from "../../store/genericContent.store";
import { light_black } from "../../StyleHelpers";
import { AppLogger } from "../../utils/AppLogger";
import useMovieContentStore from "../../store/useMovieContent.store";
import { StyledContainer, StyledContainerWithMargin } from "./styled";

const NewScreenManager = (props: any) => {
	const navigation = useNavigation<any>();
	const navigationTitleScreen = useNavigation<TitleDetailsScreenNavigationProp>();
	const { location, parentProductId, vamId, redirectTo, customListId } = props.route.params;
	const { route } = props;
	const [metadata, setMetadata] = useState<Metadata>();
	const [packshot, setPackshot] = useState();
	const [backgroundImage, setBackgroundImage] = useState("");
	const movieStoreValue = useMovieContentStore((state) => state.data);
	const fetchTitleDetails = useMovieContentStore((state) => state.fetchTitleDetails);
	const vamDetails = useGenericContentStore((state: any) => state.vamDetails);
	const currentResumeProgress = useGenericContentStore((state: any) => state.currentResumeProgress);
	const setStreamPosition = useGenericContentStore((state: any) => state.setStreamPosition);
	const setStreamProgress = useGenericContentStore((state: any) => state.setStreamProgress);

	const assignController = [
		{ text: "Play", icon: IconPlay, disabled: false },
		{ text: "Cancel", icon: "", disabled: false },
	];

	const screenManagerController = [
		{ text: "Pause", icon: IconPause, disabled: true },
		{ text: "Resume", icon: IconPlay, disabled: true },
		{ text: "Stop", icon: IconStop, disabled: true },
		{ text: "Restart", icon: IconRestart, disabled: true },
		{ text: "Sync", icon: IconLink, disabled: true },
	];

	useEffect(() => {
		if (parentProductId && parentProductId !== Pages.controller) {
			fetchTitleDetails(parentProductId, true);
		}
	}, [parentProductId]);

	useEffect(() => {
		let unmounted = false;
		if (location === Pages.assignScreen && !vamDetails) {
			(async () => {
				const result = movieStoreValue.titleDetails.titles[parentProductId];
				if (!unmounted) {
					if (!result?.metadata?.images) return;

					if (result?.images) {
						getImages();
					}

					if (result?.metadata) {
						setMetadata(result?.metadata);

						const portraitImage = result?.metadata.images
							.filter((val: any) => val.width === 600 && val.height === 900)
							.map((val: any) => val.url);

						setPackshot(portraitImage[0]);
					}
				}
			})();
		}
		return () => {
			unmounted = true;
		};
	}, [route.params, movieStoreValue]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			if (vamDetails) {
				setMetadata(vamDetails);
				setBackgroundImage(vamDetails.image);
			}
		}
		return () => {
			unmounted = true;
		};
	}, [vamDetails]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			if (location === Pages.assignScreen) {
				fetchTitleDetails(parentProductId, false);
			}
		}
		return () => {
			unmounted = true;
		};
	}, []);

	const getImages = async () => {
		const result = movieStoreValue.titleDetails.titles[parentProductId];

		let TBG = result?.images.tbg;

		try {
			// @ts-ignore
			let imageToUse = TBG;

			if (imageToUse) {
				setBackgroundImage(`${imageToUse.imageUrl}?width=1600&disablewebp=false`);
				return;
			}
			setBackgroundImage("");
		} catch (err) {
			AppLogger.log(err);
		}
	};

	const onSearch = () => {
		//@ts-ignore
		navigation.navigate(routeSearchScreen);
	};

	const closeScreenManger = () => {
		if (redirectTo.includes(direction.player)) {
			setStreamPosition(0);
			setStreamProgress(0);
		}

		//@ts-ignore
		navigation.navigate(routeHome);
	};

	const redirect = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		if (vamId) {
			navigation.navigate(routeVamPlayer, {
				parentProductId,
				vamId,
				location: redirectTo.replace(`-${direction.player}`, ""),
				redirectTo: direction.main,
			});
			//@ts-ignore
			window.onScreenManager = false;
			return;
		}

		navigation.navigate(routePlayer, { location: direction.main, parentProductId, resumeProgress: currentResumeProgress });
		//@ts-ignore
		window.onScreenManager = false;
	};

	const onBack = () => {
		if (redirectTo.includes(direction.player)) {
			redirect();
			return;
		}

		if (redirectTo === direction.tdp) {
			//@ts-ignore
			navigation.navigate(routeTitleDetails, { parentProductId });
			return;
		}

		if (customListId && navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		if (redirectTo === direction.studio && !customListId) {
			//@ts-ignore
			navigation.navigate(routeNewCuration);
			return;
		}

		pushMainAppStateToBrowserHistory(MainAppState.titleDetails);
		navigationTitleScreen.navigate(routeTitleDetails, { parentProductId: parentProductId });
	};

	return (
		<View style={{ flex: 1, backgroundColor: light_black }}>
			{location === Pages.main && redirectTo !== direction.player && !vamId && (
				<MainLayoutWithSideNav
					screenTitle={globalAny.language.header_screen_manager}
					navTitle={"ScreenManager"}
					searchIcon={true}
					onPressRightIcon={onSearch}
				>
					<View style={{ marginLeft: 115, paddingTop: 112 }}>
						<ScreenManager
							location={location}
							controller={screenManagerController}
							controllerText={globalAny.language.select_screen_to_manage}
							route={route}
						/>
					</View>
				</MainLayoutWithSideNav>
			)}

			{location === Pages.main && (redirectTo === direction.player || vamId) && (
				<AssignScreen>
					<TopAppBar
						leftIcon={closeIcon}
						screenTitle={globalAny.language.header_screen_manager}
						leftIconWidth={92}
						onPressLeftTitleIcon={onBack}
						leftTitleIcon={backIcon}
						onPressLeftIcon={closeScreenManger}
						onPressRightIcon={props.onPressRightIcon}
					></TopAppBar>
					{/* @ts-ignore */}
					<StyledContainerWithMargin>
						<ScreenManager
							location={location}
							controller={screenManagerController}
							controllerText={globalAny.language.select_screen_to_manage}
							route={route}
						/>
					</StyledContainerWithMargin>
				</AssignScreen>
			)}

			{location === Pages.assignScreen && (
				<AssignScreen>
					<TopAppBar
						leftIcon={closeIcon}
						screenTitle={globalAny.language.assign_media_screen}
						screenTitleBody={metadata?.title + ((metadata?.ratingReason && " | " + metadata?.ratingReason) || "")}
						leftIconWidth={92}
						onPressLeftTitleIcon={onBack}
						leftTitleIcon={backIcon}
						rightImage={true}
						rightImageLandscape={backgroundImage}
						rightImagePortrait={packshot}
						onPressLeftIcon={closeScreenManger}
						onPressRightIcon={props.onPressRightIcon}
					></TopAppBar>
					{/* @ts-ignore */}
					<StyledContainer>
						<ScreenManager
							location={location}
							controllerText={globalAny.language.select_screen_to_play}
							parentProductId={parentProductId}
							vamId={vamId}
							addMarginLeft={true}
							title={metadata?.title}
							bgImage={backgroundImage}
							devicesToPlayMovie={[]}
							controller={assignController}
							route={route}
						/>
					</StyledContainer>
				</AssignScreen>
			)}
		</View>
	);
};

export default NewScreenManager;

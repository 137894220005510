import styled, { css } from "styled-components/native";
import { black, mid_blue, spacing_l, spacing_m, spacing_xs, white, white_smoke } from "../../StyleHelpers";
import { ChannelButtonProps, LoaderProps, Props, StyledProps } from "./model";
import { direction, Pages } from "../../Types";

//#region Index Components Style
export const StyledContainer = styled.View`
	padding: 124px 0 0 0;
	z-index: -1;
`;

export const StyledContainerWithMargin = styled.View`
	margin: 0 112px;
	padding: 124px 0 0 0;
	z-index: -1;
`;
//#endregion

//#region Screen Manager Style
export const StyledParentContainer = styled.View`
	height: 100vh;
`;

export const StyledMainContainer = styled.View<Props>`
	height: 100vh;
	padding-bottom: 10%;
	z-index: -2;

	${(props) =>
		!props.addMarginLeft &&
		css`
			margin-left: ${spacing_l};
		`}

	${(props) =>
		props.addMarginLeft &&
		css`
			margin-left: 92;
			margin-right: 51;
		`}
`;

export const StyledCard = styled.View`
	width: 100%;
	padding: 0 ${spacing_l} ${spacing_l} 0;
`;

export const StyledMargin = styled.View`
	margin: 0 ${spacing_l} 0 0;
`;

export const StyledNoDevices = styled.View`
	margin: 63px 0 10% 20px;
`;
export const StyledWithDevices = styled.View`
	margin: 0px 0 10% 0px;
`;

export const StyledContentContainer = styled.View<any>`
	${(props) =>
		props.wheelPosition === "right" &&
		css`
			margin: 0 120px 0 30px;
		`}

	${(props) =>
		props.wheelPosition !== "right" &&
		css`
			margin: 0 30px 0 0;
		`}

	${(props) =>
		props.addMarginLeft &&
		css`
			padding-right: 51;
		`}
`;

export const StyledText = styled.View<StyledProps>`
	height: 100%;
	flex-direction: row;
	align-items: center;

	${(props) =>
		props.wheelPosition !== "right" &&
		css`
			justify-content: space-between;
		`}

	${(props) =>
		props.wheelPosition === "right" &&
		css`
			justify-content: start;
		`}
`;

export const StyledTextContainer = styled.View<any>`
	height: 75px;

	${(props) =>
		props.isSync &&
		css`
			justify-content: center;
			align-items: center;
		`}

	${(props) =>
		props.location !== Pages.assignScreen &&
		props.wheelPosition !== "right" &&
		css`
			margin-left: 145px;
		`}

	${(props) =>
		props.location !== Pages.assignScreen &&
		props.wheelPosition === "right" &&
		css`
			margin-left: 0px;
			padding-left: 0px;
		`}
`;

export const StyledController = styled.View<any>`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	${(props) =>
		props.isAssignToScreen &&
		props.isPlayButton &&
		css`
			display: flex;
		`}
`;

export const StyledImage = styled.View<any>`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 0.625rem;
	padding: 0 1rem;

	${(props) =>
		props.isAssignToScreen &&
		props.isPlayButton &&
		css`
			justify-content: start;
			gap: 0.5rem;
		`}
`;

export const StyledButtonText = styled.Text`
	font-size: 23px;
	margin-left: -3px;
`;

export const StyledOtherDevicesHeader = styled.Text`
	color: ${white_smoke};
	font-size: 30;
`;

export const StyledOtherDevicesBody = styled.Text`
	color: ${white_smoke};
	font-size: 25;
	opacity: 0.5;
`;

export const StyledControllerText = styled.Text<any>`
	color: white;
	font-size: 25;

	${(props) =>
		props.direction &&
		css`
			text-align: center;
			width: 100%;
		`}
`;

export const StyledEmptySpace = styled.View`
	width: 11.45vh;
	height: 100%;
	margin-right: 20px;
`;

export const StyledControllerContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const StyledLoading = styled.View<Props>`
	border-radius: 16px;
	overflow: hidden;
	z-index: -1;

	${(props) =>
		props.addMarginLeft &&
		css`
			margin: ${spacing_l} 0 0 0;
			width: 98.4%;
			height: 194px;
		`}
	${(props) =>
		!props.addMarginLeft &&
		css`
			margin: ${spacing_l} 0 0 0;
			width: 98.4%;
			height: 194px;
		`}
`;

export const StyledSync = styled.View<any>`
	display: flex;
	width: 100%;

	${(props) =>
		props.wheelPosition !== "right" &&
		css`
			flex-direction: row;
			margin-left: 147px;
		`}

	${(props) =>
		props.wheelPosition === "right" &&
		css`
			flex-direction: row-reverse;
			margin-left: 0;
			padding-right: 30px;
		`}
`;

export const StyledSyncBody = styled.View`
	justify-content: center;
`;

export const StyledCancelContainer = styled.View`
	width: 172px;
`;

export const StyledAddScreenContainer = styled.View`
	width: 210px;
`;

export const StyledCancelButton = styled.View<any>`
	${(props) =>
		(props.redirectTo === direction.player || props.redirectTo === "main-player") &&
		css`
			margin-right: 120px;
		`}

	width: 14.02vw;
`;

export const StyledControllerButton = styled.View<any>`
	${(props) =>
		props.location !== Pages.assignScreen &&
		css`
			margin-left: 145px;
		`}

	${(props) =>
		props.location === Pages.assignScreen &&
		css`
			margin-left: 110px;
		`}
	display: flex;
	flex-direction: row;
`;

export const StyledOtherDevicesContainer = styled.View`
	margin-top: 20px;
`;

export const StyledOtherDevicesLoader = styled.View<LoaderProps>`
	overflow: hidden;
	width: ${(props) => props.width};
	height: ${(props) => props.height};

	${(props) =>
		props.marginBottomLarge &&
		css`
			border-radius: 12px;
			margin-bottom: ${spacing_m};
		`}
	${(props) =>
		!props.marginBottomLarge &&
		css`
			border-radius: 8px;
			margin-bottom: ${spacing_xs};
		`}
`;

export const StyledRightTextContainer = styled.View<any>`
	${(props) =>
		props.assignScreen !== Pages.assignScreen &&
		css`
			display: flex;
			flex-direction: row-reverse;
			padding-right: 230px;
			width: 100%;
		`}

	${(props) =>
		props.syncRibbon &&
		css`
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding-left: 230px;
		`}
		
	${(props) =>
		props.direction &&
		css`
			justify-content: center;
			padding: 0px 0px 0px 205px;
		`}
`;
//#endregion

//#region Channel Button style
export const StyledMain = styled.View<ChannelButtonProps>`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 194px;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	background-color: black;
`;

export const StyledHover = styled.View<any>`
	width: 100%;
	position: absolute;
	height: 194px;
	z-index: 999;
	border-radius: 8px;
	border-color: 1px solid rgba(90, 90, 90, 0.5);
	border-width: 1px;
	border-color: 0.6s ease;

	${(props) =>
		props.selected &&
		css`
			display: block;
			border-color: ${mid_blue};
			border-width: 2px;
			background-image: linear-gradient(90deg, rgba(35, 98, 190, 0.5) 0%, rgba(35, 98, 190, 0) 100%);
		`}

	${(props) =>
		props.isHovered &&
		css`
			display: block;
			border-color: ${white};
			border-width: 2px;
		`}
`;

export const StyledInfo = styled.View`
	display: flex;
	width: 408px;
	height: 194px;
	max-width: 408px;
	padding: ${spacing_m};
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 28px;
	flex-shrink: 0;
`;

export const StyledIcon = styled.View`
	height: 100%;
	width: 100%;
	justify-content: center;
`;

export const StyledProgressBar = styled.View`
	width: 100%;
`;

export const StyledHeader = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	align-self: stretch;
`;

export const StyledHeaderText = styled.Text`
	color: white;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const StyledAction = styled.Text`
	color: white;
	font-size: 18;
	opacity: 0.6;
`;

export const StyledTBGContainer = styled.View`
	position: absolute;
	display: flex;
	width: 100%;
	height: 194px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
`;

export const StyledTBG = styled.View<ChannelButtonProps>`
	width: 388px;
	height: 194px;
	border-radius: 8px;
	${(props) =>
		props.isTBG &&
		props.bgImage &&
		css`
			background-image: url(${props.bgImage});
			background-repeat: no-repeat;
			background-size: cover;
			background-color: lightgray;
			background-position: 50% /;
		`}

	${(props) =>
		!props.bgImage &&
		css`
			background-color: #333333;
		`}
`;

export const StyledTBGGradient = styled.View`
	z-index: 1;
	height: 194px;
	width: 194px;
	flex-shrink: 0;
	background-image: linear-gradient(90deg, ${black} 0%, rgba(0, 0, 0, 0) 100%);
`;

export const StyledStatus = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	align-self: stretch;
`;

export const StyledPackshot = styled.View`
	display: flex;
	width: 140px;
	height: 194px;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
`;

export const StyledPackshotImage = styled.View<ChannelButtonProps>`
	display: flex;
	width: 108px;
	height: 162px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 8px;
	${(props) =>
		props.bgImage &&
		css`
			background-image: url(${props.bgImage});
			background-repeat: no-repeat;
			background-size: cover;
			background-color: lightgray;
			background-position: 50% /;
		`}
`;
//#endregion

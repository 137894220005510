import { View, Text } from "react-native";
import { navy_blue, royal_blue, spacing_l, white } from "../../StyleHelpers";
import { Button } from "react-native-paper";
import icon from "../../assets/TopNavBarIcon/icon_nav_proceed.png";
import Image from "../Image/Image";
import styled from "styled-components/native";
import ITouchableButtonProps from "../../models/ITouchableButtonProps";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { useState } from "react";

interface Props {
	title?: string;
	disabled?: boolean;
	rightIcon?: string;
	bgColor?: string;
	onPress?: () => void;
	isHovered?: any;
}

const StyledBtnPaper = styled(Button)<Props>`
	background-color: ${(props) => (props.isHovered ? navy_blue : royal_blue)};
	margin-bottom: ${spacing_l};
	border-radius: 16px;
`;

const StyledBottomInnerContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	flex-basis: 100%;
`;

const StyledTextContainer = styled.Text<any>`
	height: 4.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5625rem;
`;

const LegalButton = (props: ITouchableButtonProps) => {
	const [isHovered, setIsHovered] = useState(false);
	return (
		//@ts-ignore
		<StyledBtnPaper
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			isHovered={props.disabled ? false : isHovered}
			mode="contained-tonal"
			onPress={() => props.onPress}
			labelStyle={{ width: "100%" }}
			contentStyle={{ height: "72px", width: "100%" }}
			type={ComponentTypeEnum.Secondary}
			{...props}
			disabled={props.disabled}
		>
			{/* @ts-ignore	 */}
			<StyledBottomInnerContainer>
				<View>
					<StyledTextContainer>
						<Text style={{ opacity: props.disabled ? 0.5 : 1, color: white }}>{props.title}</Text>
					</StyledTextContainer>
				</View>
				<View>
					<Image
						style={{ opacity: props.disabled ? 0.5 : 1 }}
						source={{ uri: props.rightIcon || icon }}
						resizeMode={"contain"}
						width={24}
						height={24}
					/>
				</View>
			</StyledBottomInnerContainer>
		</StyledBtnPaper>
	);
};

export default LegalButton;

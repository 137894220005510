import { CONTENT_BASEURL_V9 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const getCategories = async (page: string, transactionalFilters: any, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V9 + "categories/" + page + "?demo=false&imax=false&transactionalFilters=" + transactionalFilters, {
			headers: getHeaders(true),
			signal,
		})
			.then((resJson) => {
				resolve(resJson.categories);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

export const getFeatures = async (categoryId: any, transactionalFilters: any, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V9 + "features/" + categoryId + "?transactionalFilters=" + transactionalFilters, {
			headers: getHeaders(true),
			signal,
		})
			.then((resJson) => resolve({ featureList: resJson.featureList }))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

export const getFeaturesShared = async (categoryId: any, transactionalFilters: any, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V9 + "features/" + categoryId + "/shared?transactionalFilters=" + transactionalFilters, {
			headers: getHeaders(),
			signal,
		})
			.then((resJson) => resolve({ featureList: resJson.featureList }))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

export const getCategoriesShared = async (page: string, transactionalFilters: any, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V9 + "categories/shared/" + page + "?demo=false&imax=false&transactionalFilters=" + transactionalFilters, {
			headers: getHeaders(),
			signal,
		})
			.then((resJson) => {
				resolve(resJson.categories);
			})
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

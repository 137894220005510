import { Code_OK } from "../networking/networking";
import { CONTENT_BASEURL_V7 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { globalAny } from "../utils/Utils";
import { AsyncStorageKeys } from "../Types";
import ENV_CONFIG from "../config/ENV_CONFIG";

export const getApiKey = async () => {
	const apiKey = localStorage.getItem(AsyncStorageKeys.apiKey);
	const isOverride = localStorage.getItem(AsyncStorageKeys.isOverride) == "true";
	const regionOverride = localStorage.getItem(AsyncStorageKeys.RegionOverride);
	const masterUrl = CONTENT_BASEURL_V7 + "apikey/" + ENV_CONFIG.MASTER_API_KEY;
	const apiKeyUrl = isOverride ? `${masterUrl}/country/${regionOverride}` : masterUrl;

	let promise = new Promise((resolve, reject) => {
		if (apiKey !== null) {
			globalAny.APIKEY = apiKey;
			resolve(apiKey);
		}

		fetchWrapper(apiKeyUrl)
			.then((resJson) => {
				if (resJson.responseCode === Code_OK) {
					localStorage.setItem(AsyncStorageKeys.apiKey, resJson.apiKey);
					if (resJson.country) localStorage.setItem(AsyncStorageKeys.RegionOverride, resJson.country);
					globalAny.APIKEY = resJson.apiKey; // Not removed for backwards compatibility
					resolve(resJson.apiKey);
				} else {
					reject(resJson.responseCode);
				}
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const checkApiKey = (region: any) => {
	const isOverride = localStorage.getItem(AsyncStorageKeys.isOverride) == "true";
	const masterUrl = CONTENT_BASEURL_V7 + "apikey/" + ENV_CONFIG.MASTER_API_KEY;
	const apiKeyUrl = isOverride ? `${masterUrl}/country/${region}` : masterUrl;

	let promise = new Promise((resolve, reject) => {
		fetchWrapper(apiKeyUrl)
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

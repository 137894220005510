import { ProgressBar } from "react-native-paper";
import { mid_blue, spacing_l, purple_blue, white, aloha, love } from "../../StyleHelpers";
import { Text, ActivityIndicator, TouchableOpacity } from "react-native";
import IconActive from "../../assets/ControllerIcon/Signal.svg";
import IconLink1 from "../../assets/ControllerIcon/Link1.svg";
import IconLink2 from "../../assets/ControllerIcon/Link2.svg";
import IconLink3 from "../../assets/ControllerIcon/Link3.svg";
import Image from "../../components/Image/Image";
import React, { useEffect, useState } from "react";
import { GroupSync } from "../../models/GroupSync";
import { globalAny } from "../../utils/Utils";
import Marquee from "../../components/Marquee";
import { ChannelButtonProps } from "./model";
import {
	StyledAction,
	StyledHeader,
	StyledHeaderText,
	StyledHover,
	StyledIcon,
	StyledInfo,
	StyledMain,
	StyledPackshot,
	StyledPackshotImage,
	StyledProgressBar,
	StyledStatus,
	StyledTBG,
	StyledTBGContainer,
	StyledTBGGradient,
} from "./styled";

const groupColor = [
	{ group: 1, color: purple_blue, icon: IconLink1 },
	{ group: 2, color: aloha, icon: IconLink2 },
	{ group: 3, color: love, icon: IconLink3 },
];

const ScreenManagerChannelButtons = (props: ChannelButtonProps) => {
	const [groupSyncColor, setGroupSyncColor] = useState<GroupSync>();
	const [loadingSpinner, setSetLoadingSpinner] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (props.link) {
			groupColor.forEach((item) => {
				if (item.group === props.groupSync) {
					setGroupSyncColor({ ...item, color: item.color, icon: item.icon });
				}
			});
		}

		if (!props.link) {
			setGroupSyncColor({ group: 0, color: mid_blue, icon: "" });
		}
	}, [props.link, props.groupSync]);

	useEffect(() => {
		if (props.actionText === globalAny.language.loading) {
			setSetLoadingSpinner(true);
			return;
		}
		setSetLoadingSpinner(false);
	}, [props.actionText]);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	return (
		<TouchableOpacity onPress={props.onPress}>
			{/* @ts-ignores */}
			<StyledMain
				selected={props.selected}
				//@ts-ignore
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onTouchEnd={handleMouseLeave}
			>
				<StyledHover isHovered={isHovered} selected={props.selected} />
				{/* @ts-ignores  */}
				<StyledTBGContainer>
					{/* @ts-ignores  */}
					<StyledTBG bgImage={props.bgImage} isTBG={props.isTBG}>
						<StyledTBGGradient />
					</StyledTBG>
				</StyledTBGContainer>
				{/* @ts-ignores */}
				<StyledInfo>
					{/* @ts-ignores */}
					<StyledHeader>
						{props.link && <Image source={{ uri: groupSyncColor?.icon }} width={32} height={32}></Image>}
						{/* @ts-ignores */}
						<StyledHeaderText>
							<Text numberOfLines={1} ellipsizeMode="tail">
								{props.headerText}
							</Text>
						</StyledHeaderText>
					</StyledHeader>
					{/* @ts-ignores */}
					<StyledStatus>
						{/* @ts-ignores */}
						<StyledAction>{props.actionText}</StyledAction>
						{/* @ts-ignores */}
						{props.title?.length > 12 && props.selected ? (
							<Marquee title={props.title} width={"408px"} />
						) : (
							<Text
								style={{
									color: "white",
									fontSize: 25,
									fontWeight: "500",
									marginBottom: spacing_l,
								}}
								numberOfLines={1}
							>
								{props.title}
							</Text>
						)}
						{/* @ts-ignores */}
						<StyledProgressBar>
							<ProgressBar
								progress={props.progressPercent || 0}
								color={groupSyncColor?.color}
								style={{
									borderRadius: 10,
									height: 8,
									backgroundColor: "rgb(51,51,51)",
								}}
							/>
						</StyledProgressBar>
					</StyledStatus>
				</StyledInfo>
				{/* @ts-ignores  */}
				<StyledPackshot>
					{!props.bgImage && props.title !== globalAny.language.online && props.reserveImage && (
						// @ts-ignores
						<StyledIcon>
							<Image source={{ uri: props.reserveImage }} color={white} width={107.3} height={161.5} style={{ borderRadius: 8 }} />
						</StyledIcon>
					)}

					{!props.bgImage &&
						props.title === globalAny.language.online &&
						props.actionText !== globalAny.language.loading &&
						!props.indicator && (
							// @ts-ignores
							<StyledIcon>
								<Image source={{ uri: IconActive }} width={32} height={32} />
							</StyledIcon>
						)}

					{loadingSpinner && (
						// @ts-ignores
						<StyledIcon>
							<ActivityIndicator size={"large"} color={"white"} />
						</StyledIcon>
					)}

					{!props.isTBG && props.bgImage && (
						// @ts-ignores
						<StyledPackshotImage bgImage={props.bgImage} />
					)}
				</StyledPackshot>
			</StyledMain>
		</TouchableOpacity>
	);
};

export default ScreenManagerChannelButtons;

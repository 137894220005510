import styled from "styled-components/native";

export const StyledContainer = styled.View`
	flex-direction: row;
	align-items: center;
`;

export const StyledTextContainer = styled.View<{ noSpacing: boolean }>`
	flex-direction: row;
	align-items: center;
	margin-bottom: 0.5rem;
	margin-top: ${(props) => (props?.noSpacing ? 0 : "2rem")};
`;

export const StyledText = styled.Text`
	font-size: 25;
	color: white;
`;

export const StyledLoader = styled.View`
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
	width: 368px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledTitle = styled.Text`
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
`;

import styled, { css } from "styled-components/native";
import { StyledProps } from "./model";

export const StyledSlider = styled.View<StyledProps>`
	margin-right: 1.5rem;
	position: relative;
	display: flex;
	align-items: flex-start;
	background-color: #2c2c2c;
	border-radius: 16px;
	height: auto;

	${(props) =>
		props.index === 0 &&
		css`
			margin-left: 151px;
		`}

	${(props) =>
		props.isStudioAccess &&
		css`
			margin-bottom: 88px;
		`}
	${(props) =>
		props.isLandscape &&
		props.isBackUpImage &&
		css`
			width: 9.4vw;
			aspect-ratio: 2 / 3;
		`}
		

	${(props) =>
		props.isLandscape &&
		!props.isBackUpImage &&
		css`
			width: 25vw;
			aspect-ratio: 16 / 9;
		`}

	${(props) =>
		!props.isLandscape &&
		css`
			width: 15vw;
			aspect-ratio: 2 / 3;
		`}
`;

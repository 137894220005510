import styled from "styled-components/native";

export const StyledBannerContainer = styled.View<{ isPreview: boolean }>`
	padding-top: 2rem;
	margin-right: ${(props) => (props.isPreview ? "scroll" : "hidden")};
`;

export const StyledLibraryFooter = styled.View`
	margin-bottom: 3rem;
`;

import { light_black, logoHeight, logoWidth, pageTitleFontSize, white_smoke, spacing_l } from "../../StyleHelpers";
import { StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import styled, { css } from "styled-components/native";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import Image from "../Image/Image";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import searchIcon from "../../assets/TopNavBarIcon/icon_nav_search.png";
import Text from "../Text/Text";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import { TextInput } from "react-native-paper";
import closeIcon from "../../assets/Icons/x.svg";
import { useEffect, useRef, useState } from "react";
import useGenericContentStore from "../../store/genericContent.store";
import { AsyncStorageKeys, WheelPosition } from "../../Types";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";
import ENVIRONMENT from "../../config/ENVIRONMENT";

interface Props {
	demoOptions?: any;
	screenTitle?: any;
	screenTitleBody?: any;
	leftIcon?: any;
	leftTitleIcon?: any;
	titleLogo?: any;
	rightIcon?: any;
	rightLogo?: any;
	rightImage?: boolean;
	rightImageLandscape?: any;
	rightImagePortrait?: any;
	addTitleMarginLeft?: boolean;
	leftIconWidth?: any;
	search?: boolean;
	placeholder?: string;
	onChangeText?: any;
	inputValue?: string;
	autoFocus?: boolean;
	isNav?: boolean;
	isSingleLogo?: boolean;
	isSettings?: boolean;
	isHideLeftIcon?: boolean;
	isShowClose?: boolean;
	isPreview?: boolean;
	onPressLeftIcon?: () => void;
	onPressLeftTitleIcon?: () => void;
	onPressRightIcon?: () => void;
	onPressInputIcon?: () => void;
}

interface StyledProps {
	leftIconWidth?: any;
}

let pressTimer: NodeJS.Timeout;
let pressCount = 0;

const StyledContainer = styled.View<Props>`
	flex-direction: column;
	${(props) =>
		!props.screenTitle && !props.titleLogo && !props.search && props?.demoOptions
			? css`
					background-color: transparent;
				`
			: css`
					background-color: rgba(0, 0, 0, 0.8);
				`}
	backdrop-filter: blur(10px);
	justify-content: center;
	z-index: 1000;
	width: 100%;
	top: 0;
	position: fixed;
	height: 112px;
`;

const StyledTextContainer = styled.View<Props>`
	justify-content: center;
	align-items: left;
	flex: 1;
	${(props) =>
		props.addTitleMarginLeft &&
		css`
			margin-left: ${spacing_l};
		`}
`;

const StyledText = styled.Text`
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	width: 90%;
`;

const StyledIconContainer = styled.View<StyledProps>`
	height: 112px;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.leftIconWidth &&
		css`
			width: ${props.leftIconWidth};
		`}

	${(props) =>
		!props.leftIconWidth &&
		css`
			width: 120px;
		`}
`;

const StyledCloseContainer = styled.View<StyledProps>`
	height: 112px;
	width: 120px;
`;

const StyledIconRenderer = styled.Image<StyledProps>`
	height: 56px;
	width: 56px;
`;

const StyledLoading = styled.View`
	width: 662px;
	height: 64px;
	overflow: hidden;
	border-radius: 16px;
`;

const TopAppBar = (props: Props) => {
	const textRef: any = useRef();
	const [clickCount, setClickCount] = useState(1);
	const setIsHide = useGenericContentStore((state: any) => state.setIsHide);
	const setIsDebugVisible = useGenericContentStore((state: any) => state.setIsDebugVisible);
	const wheelPosition = localStorage.getItem(AsyncStorageKeys.wheelPosition);

	const iconRender = () => <StyledIconRenderer leftIconWidth={props.leftIconWidth} source={{ uri: props.leftIcon ? props.leftIcon : backIcon }} />;
	const titleRender = () =>
		!props?.isHideLeftIcon ? (
			<View style={styles.centerImage}>
				<TouchableOpacity onPress={props.onPressLeftTitleIcon}>
					{/* @ts-ignores */}
					<StyledIconContainer leftIconWidth={props.leftIconWidth}>
						{/* @ts-ignores */}
						<StyledIconRenderer
							leftIconWidth={props.leftIconWidth}
							source={{ uri: props.leftTitleIcon ? props.leftTitleIcon : backIcon }}
						/>
					</StyledIconContainer>
				</TouchableOpacity>
			</View>
		) : (
			<StyledCloseContainer />
		);

	const showAdvanceSettings = () => {
		const newCount = clickCount + 1;
		setClickCount(newCount);
		if (clickCount === 10) {
			setIsHide(false);
			return setClickCount(1);
		}
	};

	const renderTitleLogo = () => {
		return props.titleLogo ? (
			<View style={props.isPreview ? styles.stylesPreview : {}}>
				<TouchableWithoutFeedback onPressIn={handleLogoPressIn}>
					<Image
						source={{ uri: props.titleLogo ? props.titleLogo : ridevueLogo }}
						resizeMode={"contain"}
						width={logoWidth}
						height={logoHeight}
					/>
				</TouchableWithoutFeedback>
			</View>
		) : (
			<></>
		);
	};

	const renderTitle = () => {
		return (
			//@ts-ignore
			<StyledTextContainer addTitleMarginLeft={props.addTitleMarginLeft}>
				{!props.screenTitle && !props.titleLogo && !props.search ? (
					// @ts-ignores
					<StyledLoading>
						<ImgProgressBar />
					</StyledLoading>
				) : (
					<></>
				)}

				{props.screenTitle ? (
					<View>
						{/* @ts-ignores */}
						<StyledText>
							{props.isSettings && DEFAULT_ENV !== ENVIRONMENT.PRODUCTION ? (
								<Text
									onPress={showAdvanceSettings}
									fontSize={pageTitleFontSize}
									style={[styles.screenLabel, props.screenTitleBody ? { fontSize: 33 } : { fontSize: 50 }]}
									numberOfLines={1}
									ellipsizeMode="tail"
								>
									{props.screenTitle}
								</Text>
							) : (
								<Text
									fontSize={pageTitleFontSize}
									style={[styles.screenLabel, props.screenTitleBody ? { fontSize: 33 } : { fontSize: 50 }]}
									numberOfLines={1}
									ellipsizeMode="tail"
								>
									{props.screenTitle}
								</Text>
							)}

							<Text fontSize={pageTitleFontSize} style={[styles.titleBody]} numberOfLines={1} ellipsizeMode="">
								{props.screenTitleBody}
							</Text>
						</StyledText>
					</View>
				) : (
					<></>
				)}
				{renderTitleLogo()}
				{props.search ? (
					// @ts-ignores
					<TextInput
						mode="outlined"
						style={styles.searchTextInput}
						placeholder={props.placeholder}
						onChangeText={props.onChangeText}
						outlineColor={"gray"}
						activeOutlineColor={white_smoke}
						textColor={white_smoke}
						outlineStyle={styles.outlineStyle}
						placeholderTextColor={"gray"}
						value={props.inputValue}
						focusable={props.autoFocus}
						ref={textRef}
						right={
							props.inputValue ? (
								// @ts-ignores
								<TextInput.Icon
									icon={closeIcon}
									onPress={props.onPressInputIcon}
									style={{ marginTop: 15, marginRight: 50 }}
									iconColor={white_smoke}
									size={30}
								/>
							) : (
								<></>
							)
						}
					/>
				) : (
					<></>
				)}
			</StyledTextContainer>
		);
	};

	const renderWheelPositionRightLogo = () => {
		return (
			<>
				{props.rightImage && wheelPosition !== WheelPosition.RIGHT ? (
					<Image
						source={props.rightImageLandscape ? { uri: props.rightImageLandscape } : { uri: props.rightImagePortrait }}
						style={[
							styles.rightImage,
							props.rightImageLandscape ? { width: 156, height: 88, marginRight: 122 } : { width: 64, height: 96 },
						]}
					/>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderRightLogo = () => {
		return (
			<View style={styles.centerImage}>
				{/* @ts-ignores */}
				{props.rightIcon ? (
					<TouchableOpacity onPress={props.onPressRightIcon}>
						<Image source={{ uri: props.rightIcon ? props.rightIcon : searchIcon }} width={122} height={112} style={styles.backIcon} />
					</TouchableOpacity>
				) : (
					<></>
				)}
				{props.rightLogo ? (
					//@ts-ignores
					<TouchableWithoutFeedback onPressIn={handleLogoPressIn}>
						<Image
							source={{ uri: props.rightLogo ? props.rightLogo : ridevueLogo }}
							resizeMode={"contain"}
							width={props.isSingleLogo ? 46 : logoWidth}
							height={props.isSingleLogo ? 46 : logoHeight}
						/>
					</TouchableWithoutFeedback>
				) : (
					<></>
				)}
				{renderWheelPositionRightLogo()}
			</View>
		);
	};

	const renderRightPackshot = () => {
		if (!props.rightImage) return;

		return (
			<View style={styles.centerImage}>
				{/* @ts-ignores */}
				{props.rightImage ? (
					<Image
						source={props.rightImageLandscape ? { uri: props.rightImageLandscape } : { uri: props.rightImagePortrait }}
						style={[
							styles.rightImage,
							props.rightImageLandscape ? { width: 156, height: 88, marginRight: 55 } : { width: 64, height: 96 },
						]}
					/>
				) : (
					<></>
				)}
			</View>
		);
	};

	/**
	 * Set setTimeout to 5 seconds after timeout it will
	 * set the `setDialogVisible` to true
	 */
	const handleLogoPressIn = () => {
		pressCount = pressCount + 1;
		if (pressCount === 10) {
			setIsDebugVisible(true);
			pressCount = 0;
		}
	};

	const renderCloseContainer = () => {
		return !props.screenTitle && !props.titleLogo && !props.search ? <StyledCloseContainer /> : <></>;
	};

	const renderTopAppBarRight = () => {
		return (
			<View style={styles.innerContainer}>
				{renderRightLogo()}
				{renderTitle()}
				{renderRightPackshot()}
				{props.search || (!props.isNav && props.screenTitle) ? titleRender() : <></>}
				{props.isShowClose ? (
					<View style={styles.centerImage}>
						{/* @ts-ignore */}
						<TouchableWithoutFeedback onPress={props.onPressLeftIcon} onPressIn={handleLogoPressIn}>
							{/* @ts-ignores */}
							<StyledIconContainer leftIconWidth={!props.screenTitle && !props.search && props.leftIconWidth}>
								{/* @ts-ignores */}
								{props.screenTitle || props.titleLogo || props.search ? iconRender() : <></>}
							</StyledIconContainer>
						</TouchableWithoutFeedback>
					</View>
				) : (
					<></>
				)}
			</View>
		);
	};

	const renderTopAppBarLeft = () => {
		return (
			<View style={styles.innerContainer}>
				{props.isShowClose ? (
					<View style={styles.centerImage}>
						{/* @ts-ignore */}

						<TouchableWithoutFeedback onPress={props.onPressLeftIcon} onPressIn={handleLogoPressIn}>
							{/* @ts-ignores */}
							<StyledIconContainer leftIconWidth={!props.screenTitle && !props.search && props.leftIconWidth}>
								{/* @ts-ignores */}
								{props.screenTitle || props.titleLogo || props.search ? iconRender() : <></>}
							</StyledIconContainer>
						</TouchableWithoutFeedback>
					</View>
				) : (
					renderCloseContainer()
				)}
				{props.search || (!props.isNav && props.screenTitle) ? titleRender() : <></>}
				{renderTitle()}
				{renderRightLogo()}
			</View>
		);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted && props.autoFocus === true) {
			textRef.current.focus();
		}

		return () => {
			mounted = false;
		};
	}, [props.autoFocus]);

	return (
		// @ts-ignores
		<StyledContainer screenTitle={props.screenTitle} titleLogo={props.titleLogo} search={props.search}>
			{wheelPosition === WheelPosition.RIGHT ? renderTopAppBarRight() : renderTopAppBarLeft()}
		</StyledContainer>
	);
};
export default TopAppBar;

export const styles = StyleSheet.create({
	innerContainer: {
		flexDirection: "row",
	},
	backIcon: {
		resizeMode: "contain",
	},
	rightImage: {
		marginRight: 93,
		borderRadius: 8,
		borderWidth: 1,
		borderColor: "white",
		borderStyle: "solid",
	},
	screenLabel: {
		borderColor: "white",
		textAlign: "left",
	},

	titleBody: {
		fontSize: 18,
		textAlign: "left",
		opacity: 0.8,
	},
	centerImage: {
		textAlign: "center",
		justifyContent: "center",
		width: 120,
		alignItems: "center",
	},
	searchTextInput: {
		height: 66,
		backgroundColor: light_black,
		fontSize: 24,
		color: white_smoke,
		paddingLeft: 30,
	},
	outlineStyle: {
		borderRadius: 33,
	},
	stylesPreview: {
		height: 112,
		marginLeft: 40,
		justifyContent: "center",
	},
});

import AsyncStorage from "@react-native-async-storage/async-storage";

export const setCacheItem = async (key: string, value: any, isJson: boolean) => {
	let result = isJson ? JSON.stringify(value) : value;
	const expiresAt = getExpireDate(240);

	const stringifiedResult = JSON.stringify({ data: result, expiresAt });

	await AsyncStorage.setItem(key, stringifiedResult);
};

export const getCacheItem = async (key: string, isJson: boolean) => {
	let cacheValue: any = await AsyncStorage.getItem(key);

	if (!cacheValue) {
		return null;
	}

	cacheValue = JSON.parse(cacheValue);

	if (cacheValue !== null && cacheValue.expiresAt && new Date(cacheValue.expiresAt) < new Date()) {
		await AsyncStorage.removeItem(key);
		return null;
	}

	let result;
	if (cacheValue.data) {
		result = isJson ? JSON.parse(cacheValue.data) : cacheValue.data;
	}

	return result;
};

export const removeCacheItem = (key: string) => {
	return AsyncStorage.removeItem(key);
};

const getExpireDate = (expireInMinutes: number) => {
	const now = new Date();
	const expireTime = new Date(now);
	expireTime.setMinutes(now.getMinutes() + expireInMinutes);

	return expireTime;
};

import { Text } from "react-native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { useNavigation } from "@react-navigation/native";
import { routeNewCuration } from "../../Routes";
import { globalAny } from "../../utils/Utils";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { useState } from "react";

import { StyledButton, StyledContainer, StyledFooterContainer } from "./styled";
import { FooterProps } from "./model";

const FooterBanner = (props: FooterProps) => {
	const navigation = useNavigation();
	const [borderEvent, setBorderEvent] = useState(false);

	const goToStudioAccessHandler = () => {
		//@ts-ignore
		navigation.navigate(routeNewCuration);
	};

	return (
		//@ts-ignore
		<StyledFooterContainer>
			<TouchableOpacity onMouseEnter={() => setBorderEvent(true)} onMouseLeave={() => setBorderEvent(false)}>
				{/* @ts-ignore */}
				<StyledContainer borderEvent={borderEvent} banner={props.banner}>
					<Text style={{ width: "50%", color: "white", fontSize: 25 }}>{globalAny.language.banner_text}</Text>
					{/* @ts-ignore */}
					<StyledButton>
						<TouchableButton
							addMarginRight={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={goToStudioAccessHandler}
						>
							{globalAny.language.go_behind_the_scenes}
						</TouchableButton>
					</StyledButton>
				</StyledContainer>
			</TouchableOpacity>
		</StyledFooterContainer>
	);
};

export default FooterBanner;

import styled, { css } from "styled-components/native";
import { light_black, spacing_l, spacing_s, white } from "../../StyleHelpers";
import { Props } from "./model";

export const StyledContainer = styled.View`
	height: 100vh;
	background-color: ${light_black};
`;

export const Spacer = styled.View`
	margin-bottom: 3%;
`;

export const TopSpacer = styled.View`
	margin-top: 3%;
`;

export const StyledGridPackshots = styled.View`
	margin-bottom: 3%;
	padding-top: 115px;
	padding-bottom: 20px;
`;

//Banner Style
export const StyledStudioAccessBanner = styled.View<Props>`
	flex-direction: row;
	right: 0;
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
		`}
	${(props) =>
		!props.banner &&
		css`
			background-color: ${white};
		`}
	height: 35vh;
`;

export const StyledTextContainer = styled.View`
	padding: 8vh 0 0 ${spacing_l};
	background-color: ${light_black};
	width: 40vw;
	height: 40vh;
	z-index: 9;
`;

export const StyledText = styled.Text`
	width: 20vw;
	height: 10vh;
	color: ${white};
	font-size: 1.5vw;
	margin-bottom: ${spacing_s};
	margin-left: 120px;
`;

export const StyledImage = styled.View`
	height: 100%;
	background-image: linear-gradient(to left, transparent, ${light_black});
	width: 45%;
`;

export const StyledGradient = styled.View`
	width: 100%;
	height: 90px;
	background-image: linear-gradient(to bottom, transparent, ${light_black});
	position: absolute;
	bottom: 0;
`;

import { CONTENT_BASEURL_V6, CONTENT_BASEURL_V7 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

/**
 *
 * @param productId - string parentProductId
 * @returns - vam details
 */
export const getVamPlaylist = (productId: string) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "vam/" + productId + "/streamtype/2", { headers: getHeaders(true) })
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

/**
 * Returns the vam metadata for the specified Parent Product
 * /content/v7/Vam/{parentProductId}/playerdetails
 * @param vamID
 */
export const getVamPlayerDetails = (vamID: number) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V7 + "vam/" + vamID + "/playerDetails?subType=vtt", { headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

/**
 * Returns the vam metadata for the specified Parent Product
 * /content/v6/Vam/{parentProductId}/streamtype/{streamType}
 * @param parentProductId
 */
export const getVamMetadata = (parentProductId: number) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "vam/" + parentProductId + "/streamtype/2", { headers: getHeaders(true) })
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

/**
 * Returns the vam details for the specified unique id
 * /content/v6/Vam/{uniqueContentId}/details/streamtype/{streamType}
 * @param uniqueContentId
 */
export const getVamDetails = (uniqueContentId: number) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "vam/" + uniqueContentId + "/details/streamtype/2", { headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

enum ComponentTypeEnum {
	Primary = "Primary",
	Secondary = "Secondary",
	Tertiary = "Tertiary",
	Processing = "Processing",
	Error = "Error",
	Dark = "Dark",
}

export default ComponentTypeEnum;

import { View, Text } from "react-native";
import { palladium, spacing_l } from "../../StyleHelpers";
import styled from "styled-components/native";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import TouchableButton from "../TouchableButton";
import H4 from "../Typography/H4";
import H5 from "../Typography/H5";
import ImgProgressBar from "../Loaders/ImgProgressBar";

interface Props {
	header?: string;
	body?: string;
	buttonText?: string;
	hideButton?: boolean;
	onPress?: () => void;
}

export const StyledContainer = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding: 0px 0px ${spacing_l} 0px;
`;

export const StyledButton = styled.View`
	width: 344px;
	height: 72px;
`;

export const StyledLoadHeader = styled.View`
	width: 400px;
	height: 24px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: 20px;
`;

export const StyledLoadBody = styled.View`
	width: 200px;
	height: 18px;
	border-radius: 16px;
	overflow: hidden;
`;

export const StyledTextContainer = styled.View`
	width: 26.04vw;
`;

export const StyledText = styled.Text`
	color: ${palladium};
`;

export const StyledTextMain = styled.View`
	flex: 1;
`;
// @ts-ignore
const GroupContainer = (props: Props) => {
	return (
		// @ts-ignores
		<StyledContainer>
			{props.body && props.header ? (
				<View>
					<Text>
						<H4>{props.header}</H4>
					</Text>
					<Text>
						<H5 fontColor={palladium}>
							{/* @ts-ignores */}
							<StyledTextContainer>
								{/* @ts-ignores */}
								<StyledText>{props.body}</StyledText>
							</StyledTextContainer>
						</H5>
					</Text>
				</View>
			) : (
				<></>
			)}
			{!props.body ? (
				// @ts-ignores
				<View>
					{/* @ts-ignores */}
					<StyledLoadHeader>
						<ImgProgressBar />
					</StyledLoadHeader>
					{/* @ts-ignores */}
					<StyledLoadBody>
						<ImgProgressBar />
					</StyledLoadBody>
				</View>
			) : (
				<></>
			)}
			{/* @ts-ignores */}
			<StyledButton>
				{!props.hideButton ? (
					<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Secondary} onPress={props.onPress}>
						{props.buttonText}
					</TouchableButton>
				) : (
					<></>
				)}
			</StyledButton>
		</StyledContainer>
	);
};

export default GroupContainer;

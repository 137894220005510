import styled from "styled-components/native";

interface Props {}

const StyledContainer = styled.View`
	width: 80%;
	height: 100%;
	left: 0;
	z-index: -1;
	background-image: linear-gradient(to left, transparent, black);
	position: absolute;
`;

const GradientLeftSide = (props: Props) => {
	return (
		// @ts-ignores
		<StyledContainer></StyledContainer>
	);
};

export default GradientLeftSide;

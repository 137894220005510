import styled from "styled-components/native";
import { BtnProps } from "./model";

export const StyledButton = styled.TouchableOpacity<BtnProps>`
	position: relative;
	width: 110px;
	height: 50px;
	border-radius: 8px;
	border: 2px solid #dddddd;
	background: ${(props) => (props.btnDisabled ? "#141414" : "#3561b8")};
	@media (max-height: 610px) {
		width: 70px;
		height: 30;
	}
`;

export const StyledInnerButton = styled.TouchableOpacity<BtnProps>`
	height: 102%;
	width: 100%;
	border-radius: 8px;
	background: ${(props) => (props.btnDisabled ? "#DDDDDD" : "#D4D4D4")};
	justify-content: center;
	align-items: center;
	@media (max-height: 610px) {
		height: 100%;
		width: 60%;
	}
`;

export const StyledText = styled.Text`
	color: #000;
	font-size: 20px;
	font-weight: 700;
	@media (max-height: 610px) {
		font-size: 14px;
	}
`;

import ProgressSteps, { Content } from "@joaosousa/react-native-progress-steps";

type StepperProps = {
	marker: any;
	currentStep: number;
	steps: any;
};

export const Stepper = (props: StepperProps) => {
	return <ProgressSteps marker={props.marker} orientation="horizontal" currentStep={props.currentStep} steps={props.steps} />;
};

import { create } from "zustand";

type AcceptDataState = {
	acceptData: boolean;
	setAcceptData: (value: boolean) => void;
};

const useAcceptDataStore = create<AcceptDataState>((set) => ({
	acceptData: false,
	setAcceptData: (value) => set({ acceptData: value }),
}));

export default useAcceptDataStore;

import styled, { css } from "styled-components/native";
import TextInput from "../../components/TextInput";
import ComponentThemeType from "../../models/ComponentTheme";
import DropDown from "../../components/DropDown";
import { useState, useEffect } from "react";
import { regionListByCountry, countryList } from "../../services/paymentService";
import { globalAny, isInCarScreen } from "../../utils/Utils";

interface Props {
	show?: boolean;
}

const StyledContainer = styled.View<Props>`
	margin-top: 40px;
	${(props) =>
		props.show &&
		css`
			display: block;
		`}
	${(props) =>
		!props.show &&
		css`
			display: none;
		`}
`;

const StyledDropdownContainerFirst = styled.View`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
`;

const StyledDropdownContainerSecond = styled.View`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 999;
`;

const StyleHalfInput = styled.View`
	width: 49%;
`;

const StyleTextInput = styled.View`
	width: 100%;
`;

const BillingInformation = (props: any) => {
	const [countryData, setCountryData] = useState([]);
	const [states, setStates] = useState("");
	const [address, setAddress] = useState(props.address);
	const [address2, setAddress2] = useState(props.address2);
	const [city, setCity] = useState(props.city);
	const [postalCode, setPostalCode] = useState(props.postalCode);
	const [show, setShow] = useState(false);

	//@ts-ignore
	useEffect(async () => {
		const { countries }: any = await countryList();
		const data = countries.map((item: any) => {
			return {
				id: item.code,
				label: item.name,
				value: item.name,
			};
		});
		setCountryData(data.filter((item: any) => !!item));
	}, []);

	const onSelectCountry = async (country: any) => {
		if (!country) return;
		let { regions }: any = await regionListByCountry(country.id);
		regions = regions.map((state: any) => {
			return {
				id: state.code,
				label: state.name,
				value: state.name,
				country: country.id,
			};
		});
		setStates(regions);
	};

	useEffect(() => {
		if (props.step === 1) {
			setTimeout(() => {
				setShow(true);
			}, 350);
			return;
		}

		setShow(false);
	}, [props.step]);

	return (
		//@ts-ignore
		<StyledContainer show={show}>
			<>
				{/* @ts-ignore */}
				<StyleTextInput>
					<TextInput
						componentTheme={ComponentThemeType.VinFast}
						label={globalAny.language.address_1}
						placeholder={globalAny.language.street}
						onChangeText={(text) => {
							if (isInCarScreen()) return;
							props?.setAddress(text);
							setAddress(text);
						}}
						onChange={(e) => {
							if (!isInCarScreen()) return;
							props?.setAddress(e.nativeEvent.text);
							setAddress(e.nativeEvent.text);
						}}
						onEndEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setAddress(e.nativeEvent.text);
							setAddress(e.nativeEvent.text);
						}}
						onSubmitEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setAddress(e.nativeEvent.text);
							setAddress(e.nativeEvent.text);
						}}
						onBlur={() => {
							if (!isInCarScreen()) return;
							props?.setAddress(address);
							setAddress((value: any) => value);
						}}
						autoFocus={true}
						capitalizeFirstCharacter={true}
						value={address}
					/>
				</StyleTextInput>
				{/* @ts-ignore */}
				<StyleTextInput>
					<TextInput
						componentTheme={ComponentThemeType.VinFast}
						label={globalAny.language.address_2}
						placeholder={globalAny.language.address_optional}
						onChangeText={(text) => {
							if (isInCarScreen()) return;
							props?.setAddress2(text);
							setAddress2(text);
						}}
						onChange={(e) => {
							if (!isInCarScreen()) return;
							props?.setAddress2(e.nativeEvent.text);
							setAddress2(e.nativeEvent.text);
						}}
						onEndEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setAddress2(e.nativeEvent.text);
							setAddress2(e.nativeEvent.text);
						}}
						onSubmitEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setAddress2(e.nativeEvent.text);
							setAddress2(e.nativeEvent.text);
						}}
						onBlur={() => {
							if (!isInCarScreen()) return;
							props?.setAddress2(address2);
							setAddress2(address2);
						}}
						capitalizeFirstCharacter={true}
						value={address2}
					/>
				</StyleTextInput>
				{/**@ts-ignore */}
				<StyledDropdownContainerFirst>
					{/**@ts-ignore */}
					<StyleHalfInput>
						<DropDown
							componentTheme={ComponentThemeType.VinFast}
							label={globalAny.language.country}
							dropDownPlaceholder={globalAny.language.select_one}
							dropDownData={countryData}
							onItemSelect={(item: any) => {
								onSelectCountry(item);
								props?.setCountry(item.id);
								props?.setCountryName(item.value);
								props?.setStateName("");
								props?.setState("");
							}}
						/>
					</StyleHalfInput>
					{/**@ts-ignore */}
					<StyleHalfInput>
						<DropDown
							disabled={!states}
							componentTheme={ComponentThemeType.VinFast}
							label={globalAny.language.state}
							dropDownPlaceholder={globalAny.language.select_one}
							dropDownData={states}
							onChangeValue={states}
							onItemSelect={(item: any) => {
								props?.setState(item.id);
								props?.setStateName(item.value);
							}}
						/>
					</StyleHalfInput>
				</StyledDropdownContainerFirst>
				{/**@ts-ignore */}
				<StyledDropdownContainerSecond>
					{/**@ts-ignore */}
					<StyleHalfInput>
						<TextInput
							componentTheme={ComponentThemeType.VinFast}
							label={globalAny.language.city}
							placeholder={globalAny.language.city}
							onChangeText={(text) => {
								if (isInCarScreen()) return;
								props?.setCity(text);
								setCity(text);
							}}
							onChange={(e) => {
								if (!isInCarScreen()) return;
								props?.setCity(e.nativeEvent.text);
								setCity(e.nativeEvent.text);
							}}
							onEndEditing={(e) => {
								if (!isInCarScreen()) return;
								props?.setCity(e.nativeEvent.text);
								setCity(e.nativeEvent.text);
							}}
							onSubmitEditing={(e) => {
								if (!isInCarScreen()) return;
								props?.setCity(e.nativeEvent.text);
								setCity(e.nativeEvent.text);
							}}
							onBlur={() => {
								if (!isInCarScreen()) return;
								props?.setCity(city);
								setCity(city);
							}}
							capitalizeFirstCharacter={true}
							value={city}
						/>
					</StyleHalfInput>
					{/**@ts-ignore */}
					<StyleHalfInput>
						<TextInput
							componentTheme={ComponentThemeType.VinFast}
							label={globalAny.language.zip_postal_code}
							placeholder={globalAny.language.zip_postal_code}
							onChangeText={(text) => {
								if (isInCarScreen()) return;
								props?.setPostalCode(text);
								setPostalCode(text);
							}}
							onChange={(e) => {
								if (!isInCarScreen()) return;
								props?.setPostalCode(e.nativeEvent.text);
								setPostalCode(e.nativeEvent.text);
							}}
							onEndEditing={(e) => {
								if (!isInCarScreen()) return;
								props?.setPostalCode(e.nativeEvent.text);
								setPostalCode(e.nativeEvent.text);
							}}
							onSubmitEditing={(e) => {
								if (!isInCarScreen()) return;
								props?.setPostalCode(e.nativeEvent.text);
								setPostalCode(e.nativeEvent.text);
							}}
							onBlur={() => {
								if (!isInCarScreen()) return;
								props?.setPostalCode(postalCode);
								setPostalCode(postalCode);
							}}
							capitalizeFirstCharacter={true}
							value={postalCode}
						/>
					</StyleHalfInput>
				</StyledDropdownContainerSecond>
			</>
		</StyledContainer>
	);
};

export default BillingInformation;

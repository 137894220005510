export interface DebugDetails {
	Territory: string;
	LanguageSelected: string;
	HardwareID: string;
	IPAddress: string;
	DeviceModelName: string;
	BrowserNameAndVersion: string;
	AppVersion: string;
	ScreenSize: string;
}
export const initialDetails: DebugDetails = {
	Territory: "",
	LanguageSelected: "",
	HardwareID: "",
	IPAddress: "",
	DeviceModelName: "",
	BrowserNameAndVersion: "",
	AppVersion: "",
	ScreenSize: "",
};

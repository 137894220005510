import { globalAny } from "../../utils/Utils";
import { light_black } from "../../StyleHelpers";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import styled from "styled-components/native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import DeviceButton from "../../components/DeviceButton";
import { useState } from "react";
import { DeviceSelectionPages, ProfileName } from "../../Types";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100vh;
`;

const StyledOptionContainer = styled.View`
	top: 180px;
	height: 60vh;
	margin-left: 14%;
	margin-right: 14%;
	gap: 10px;
`;

const HalfDeviceButtonContainer = styled.View`
	margin-bottom: 44px;
	height: 60vh;
	font-size: 25px;
	gap: 24px;
`;

const StyledButtonContainer = styled.View`
	flex-direction: row;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	margin: 0 14.1% 0 14.1%;
`;

const FlexDeviceButtonContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 24px;
`;

const ScreenSelection = (props: any) => {
	const [selectedScreen, setSelectedScreen] = useState<any>(null);

	return (
		//@ts-ignore
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={globalAny.language.select_your_screen}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={props?.onPressLeftTitleIcon}
				onPressLeftIcon={props?.onPressLeftIcon}
				isSingleLogo={true}
			/>
			{/* @ts-ignore */}
			<StyledOptionContainer>
				{/* @ts-ignore */}
				<HalfDeviceButtonContainer>
					{/* @ts-ignore */}
					<FlexDeviceButtonContainer>
						<DeviceButton
							text={"Front Screen (MHU)"}
							setSelectedScreen={() => setSelectedScreen(ProfileName.FrontScreen)}
							selected={selectedScreen === ProfileName.FrontScreen}
							value={ProfileName.FrontScreen}
							disabled={!props?.isFrontAvailable}
							width={"23%"}
						/>
						<DeviceButton
							text={"Rear Left Screen"}
							setSelectedScreen={() => setSelectedScreen(ProfileName.LeftScreen)}
							selected={selectedScreen === ProfileName.LeftScreen}
							value={ProfileName.LeftScreen}
							width={"23%"}
							disabled={!props?.isLeftAvailable}
						/>
						<DeviceButton
							text={"Rear Right Screen"}
							setSelectedScreen={() => setSelectedScreen(ProfileName.RightScreen)}
							selected={selectedScreen === ProfileName.RightScreen}
							value={ProfileName.RightScreen}
							width={"23%"}
							disabled={!props?.isRightAvailable}
						/>
						{!props?.isInCarScreen() ? (
							<DeviceButton
								text={"Other Device..."}
								setSelectedScreen={() => setSelectedScreen(ProfileName.OtherDevice)}
								selected={selectedScreen === ProfileName.OtherDevice}
								value={ProfileName.OtherDevice}
								width={"23%"}
								borderStyle={true}
							/>
						) : (
							<></>
						)}
					</FlexDeviceButtonContainer>
				</HalfDeviceButtonContainer>
			</StyledOptionContainer>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				<TouchableButton
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => {
						if (selectedScreen === ProfileName.OtherDevice) {
							props?.setSelectedView(DeviceSelectionPages.OtherDevices);
							return;
						}

						props?.onDeviceSelected(selectedScreen);
					}}
					disabled={!selectedScreen}
				>
					{globalAny.language.continue}
				</TouchableButton>
			</StyledButtonContainer>
		</StyledContainer>
	);
};

export default ScreenSelection;

import { Portal, Provider } from "react-native-paper";
import { TouchableOpacity } from "react-native";
import H3 from "../Typography/H3";
import H5 from "../Typography/H5";
import H4 from "../Typography/H4";
import Image from "../Image/Image";
import TouchableButton from "../TouchableButton";
import closeIcon from "../../assets/Icons/close_icon.png";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { spacing_m } from "../../StyleHelpers";
import H6 from "../Typography/H6";
import parse from "html-react-parser";
import Spinner from "../Spinner";
import { AsyncStorageKeys } from "../../Types";
import {
	customizeStyle,
	LoadingStyle,
	StyleContentContainer,
	StyledBottomContainer,
	StyledBottomInnerContainer,
	StyledBottomText,
	StyledButtonNarrow,
	StyledCloseContent,
	StyledInnerContent,
	StyledInnerContentNarrow,
	StyledLegal,
	StyledLegalText,
	StyledMargin,
	StyledModal,
	StyledSpinner,
	StyledSubText,
	StyledText,
	StyledTextContainer,
	StyledTextContainerNarrow,
	StyledTextLoadingContainer,
	StyleModalContainer,
} from "./styled";
import { CustomDialogProps } from "./model";

const CustomizeDialogBox = (props: CustomDialogProps) => {
	const isCentral = localStorage.getItem(AsyncStorageKeys.customerZone) === "0A";
	const renderMiddleButton = () => {
		return (
			<>
				{!props.SingleButton && props.HasMiddleButton ? (
					<TouchableButton
						style={{ width: "100%", marginBottom: 10 }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorMiddle}
						onPress={props.onPressMiddle}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorMiddle === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{props.ButtonTextMiddle}
							{props.hasSubTextMiddle ? (
								//@ts-ignore
								<StyledSubText>{props.ButtonSubTextMiddle}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderLeftButton = () => {
		if ((!props.SingleButton && props.HasFirstButton && props.isTDP) || (!props.SingleButton && !props.isTDP)) {
			return (
				<TouchableButton
					style={{ width: "100%", marginBottom: spacing_m }}
					componentTheme={ComponentThemeType.VinFast}
					type={props.TypeButtonColorLeft}
					onPress={props.onPressLeft}
				>
					<StyledText fontColor={props.TypeButtonColorLeft === ComponentTypeEnum.Tertiary ? "black" : "white"}>
						{props.ButtonTextLeft}
					</StyledText>
				</TouchableButton>
			);
		}

		return <></>;
	};

	const renderButtons = () => {
		return (
			<>
				{renderLeftButton()}
				{renderMiddleButton()}
				{props.hasSubTextLeft && props.HasMiddleButton ? (
					//@ts-ignore
					<StyledSubText>{props.ButtonSubTextLeft}</StyledSubText>
				) : (
					<></>
				)}
				{!props.HideButton ? (
					<TouchableButton
						style={{ width: "100%" }}
						componentTheme={ComponentThemeType.VinFast}
						type={props.TypeButtonColorRight}
						onPress={props.onPressRight}
					>
						{/**@ts-ignore */}
						<StyledText fontColor={props.TypeButtonColorRight === ComponentTypeEnum.Tertiary ? "black" : "white"}>
							{/**@ts-ignore */}
							<StyledTextLoadingContainer>
								{/**@ts-ignore */}
								<StyledSpinner>
									{props.isProcessing ? (
										<Spinner
											show={true}
											size="small"
											color={props.TypeButtonColorRight === ComponentTypeEnum.Tertiary ? "black" : "white"}
											isStyle={true}
										/>
									) : (
										<></>
									)}
								</StyledSpinner>
								{props.ButtonTextRight}
							</StyledTextLoadingContainer>
							{props.hasSubTextRight ? (
								//@ts-ignore
								<StyledSubText>{props.ButtonSubTextRight}</StyledSubText>
							) : (
								<></>
							)}
						</StyledText>
					</TouchableButton>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderModal = () => {
		return (
			<StyledModal visible={true} contentContainerStyle={customizeStyle}>
				{/* @ts-ignore */}
				<StyleModalContainer>
					{/* @ts-ignore */}
					<StyleContentContainer width={props.Width} height={props.Height}>
						{/* @ts-ignore */}
						<StyledCloseContent>
							{props.CloseButton && (
								<TouchableOpacity onPress={props.onDismiss}>
									<Image source={{ uri: closeIcon }} width={30} height={30} />
								</TouchableOpacity>
							)}
						</StyledCloseContent>
						{/* @ts-ignore */}
						<StyledInnerContent>
							{/* @ts-ignore */}
							<StyledTextContainer>
								{/* @ts-ignore */}
								<StyledMargin>
									{/* @ts-ignore */}
									<H3>{props.Header}</H3>
								</StyledMargin>
								{props.Body ? (
									//@ts-ignore
									<StyledMargin>
										{/* @ts-ignore */}
										{props.SingleButton ? <H4>{props.Body}</H4> : <H5>{props.Body}</H5>}
									</StyledMargin>
								) : (
									<></>
								)}
								{props.Footer && (
									// @ts-ignore
									<StyledMargin>
										<H6>{parse(props.Footer)}</H6>
									</StyledMargin>
								)}
							</StyledTextContainer>

							{renderButtons()}
						</StyledInnerContent>
						{/* @ts-ignore */}
						<StyledBottomContainer>
							{props.BottomTextLeft ? (
								// @ts-ignore
								<StyledBottomInnerContainer>
									{/* @ts-ignore */}
									<StyledBottomText>{props.BottomTextLeft}</StyledBottomText>
									{/* @ts-ignore */}
									<StyledBottomText>{props.BottomTextRight}</StyledBottomText>
								</StyledBottomInnerContainer>
							) : (
								<></>
							)}
						</StyledBottomContainer>
						{(props.isLegalTerm && !props.HasMiddleButton) || (props.isLegalTerm && props.HasFirstButton) ? (
							<TouchableOpacity onPress={props.onPressTermsAndService}>
								{/* @ts-ignore */}
								<StyledLegal>
									<StyledLegalText>{props.legalTextTitle}</StyledLegalText>

									<StyledLegalText underline={true}>{props.legalButtonTextTitle}</StyledLegalText>
								</StyledLegal>
							</TouchableOpacity>
						) : (
							<></>
						)}
					</StyleContentContainer>
				</StyleModalContainer>
			</StyledModal>
		);
	};

	const renderNarrowModal = () => {
		return (
			<StyledModal visible={true} contentContainerStyle={customizeStyle}>
				{/* @ts-ignore */}
				<StyleModalContainer>
					{/* @ts-ignore */}
					<StyleContentContainer>
						{/* @ts-ignore */}
						<StyledCloseContent>
							{props.CloseButton && (
								<TouchableOpacity onPress={props.onDismiss}>
									<Image source={{ uri: closeIcon }} width={30} height={30} />
								</TouchableOpacity>
							)}
						</StyledCloseContent>
						{/* @ts-ignore */}
						<StyledInnerContentNarrow>
							{/* @ts-ignore */}
							<StyledButtonNarrow>{renderButtons()}</StyledButtonNarrow>

							{/* @ts-ignore */}
							<StyledTextContainerNarrow>
								{/* @ts-ignore */}
								<H3>{props.Header}</H3>
								{props.Body ? (
									<>
										{/* @ts-ignore */}
										{props.SingleButton ? <H4>{props.Body}</H4> : <H5>{props.Body}</H5>}
									</>
								) : (
									<></>
								)}
								{props.Footer && <H6>{parse(props.Footer)}</H6>}
							</StyledTextContainerNarrow>
						</StyledInnerContentNarrow>
						{/* @ts-ignore */}
						<StyledBottomContainer>
							{props.BottomTextLeft ? (
								// @ts-ignore
								<StyledBottomInnerContainer>
									{/* @ts-ignore */}
									<StyledBottomText>{props.BottomTextLeft}</StyledBottomText>
									{/* @ts-ignore */}
									<StyledBottomText>{props.BottomTextRight}</StyledBottomText>
								</StyledBottomInnerContainer>
							) : (
								<></>
							)}
						</StyledBottomContainer>
						{(props.isLegalTerm && !props.HasMiddleButton) || (props.isLegalTerm && props.HasFirstButton) ? (
							<TouchableOpacity onPress={props.onPressTermsAndService}>
								{/* @ts-ignore */}
								<StyledLegal>
									{/* @ts-ignore */}
									<StyledLegalText>{props.legalTextTitle}</StyledLegalText>
									<StyledLegalText underline={true}>{props.legalButtonTextTitle}</StyledLegalText>
								</StyledLegal>
							</TouchableOpacity>
						) : (
							<></>
						)}
					</StyleContentContainer>
				</StyleModalContainer>
			</StyledModal>
		);
	};

	const renderComponent = () => (isCentral || props?.isDebug ? renderModal() : renderNarrowModal());

	return (
		<Provider>
			<Portal>
				{props.Header ? (
					renderComponent()
				) : (
					<StyledModal visible={true} contentContainerStyle={LoadingStyle}>
						<Spinner show={true} isStyle={false} size={100} />
					</StyledModal>
				)}
			</Portal>
		</Provider>
	);
};

export default CustomizeDialogBox;

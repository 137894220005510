import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/autoplay/autoplay.scss"; // Autoplay module
import { Pagination, Autoplay } from "swiper";
import { useWindowDimensions } from "react-native";
import { PageScreenNavigationProp, routePage, routeTitleDetails } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import Image from "../../components/Image/Image";
import { useEffect, useState } from "react";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { calculateImageWidth } from "../../utils/Utils";
import useGenericContentStore from "../../store/genericContent.store";
import { Pages } from "../../Types";
import { HeroProps } from "./model";
import { FeatureLineOne, FeatureLineTwo, FeatureText, StyledHeroContainer, StyledMain, styles, swiperConfig } from "./styled";

const HeroBanner = (props: HeroProps) => {
	const delay = 8000;
	const navigationTitleScreen = useNavigation<any>();
	const navigationPageScreen = useNavigation<PageScreenNavigationProp>();
	const [borderEvent, setBorderEvent] = useState(false);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const [sttLoaded, setSttLoaded] = useState(false);
	const windowsWidth = useWindowDimensions();
	const defaultScreen = windowsWidth.width <= 1336;

	const onPress = (navigationLinkType: string, navigationLink: any) => {
		if (props?.isPreview) setNavigateEvent(Pages.preview);
		setSelectedTabs(0);
		switch (navigationLinkType) {
			case "ParentProduct":
				navigationTitleScreen.push(
					routeTitleDetails,
					props?.isPreview ? { parentProductId: navigationLink, preview: true } : { parentProductId: navigationLink },
				);
				break;
			case "Page":
				navigationPageScreen.push(routePage, { uniqueId: navigationLink });
				break;
		}
	};

	const convertARGBtoRGBA = (code: any) => {
		if (!code) return;

		const hashtagLessCode = code.replace("#", "");
		const rgb = hashtagLessCode.substring(2);
		const alpha = hashtagLessCode.substring(0, 2);

		return `#${rgb}${alpha}`;
	};

	useEffect(() => {
		setSttLoaded(true);
	}, [props.heroBanner]);

	const renderFeatureText = (obj: any) => {
		const featureText = obj.featureText.filter((feature: any) => feature.text);
		const stt = obj?.sttUrl;
		let lineOne: any;
		let lineTwo: any;

		lineOne = featureText.find((feature: any) => feature.name === "Line1");
		lineTwo = featureText.find((feature: any) => feature.name === "Line2");

		if (!lineOne.text.trim().length && !stt) return <></>;

		return (
			<FeatureText>
				{stt ? (
					<Image
						source={{
							uri: `${stt}?width=${1920}&disablewebp=false`,
						}}
						resizeMode="contain"
						style={defaultScreen ? styles.defaultStt : styles.stt}
						onLoad={() => setSttLoaded(true)}
					/>
				) : (
					<FeatureLineOne hexCode={convertARGBtoRGBA(lineOne.hexCode)}>{lineOne.text}</FeatureLineOne>
				)}
				{lineTwo ? <FeatureLineTwo hexCode={convertARGBtoRGBA(lineTwo.hexCode)}>{lineTwo.text}</FeatureLineTwo> : <></>}
			</FeatureText>
		);
	};

	return (
		//@ts-ignore
		<StyledHeroContainer>
			{/* @ts-ignore */}
			<StyledMain>
				<Swiper
					{...swiperConfig}
					allowTouchMove={true}
					autoplay={{
						delay: delay,
						disableOnInteraction: false,
					}}
					modules={[Autoplay, Pagination]}
				>
					{props?.heroBanner?.map((obj: any) => {
						return (
							<SwiperSlide key={`hero-banner${obj.featureId}`}>
								<TouchableOpacity
									activeOpacity={1}
									onPress={() => onPress(obj?.navigationLinkType, obj?.navigationLink)}
									onMouseEnter={() => setBorderEvent(true)}
									onMouseLeave={() => setBorderEvent(false)}
								>
									{renderFeatureText(obj)}
									{sttLoaded && (
										<Image
											source={{
												uri: `${obj?.featureImageUrl}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
											}}
											resizeMode="cover"
											style={borderEvent ? styles.onHover : styles.onLeave}
										/>
									)}
								</TouchableOpacity>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</StyledMain>
		</StyledHeroContainer>
	);
};

export default HeroBanner;

import { dark_gray, white_smoke } from "../../StyleHelpers";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	onHover: {
		marginLeft: 152,
		marginRight: 20,
		aspectRatio: 4 / 1,
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray,
	},
	onLeave: {
		marginLeft: 152,
		marginRight: 20,
		borderRadius: 16,
		aspectRatio: 4 / 1,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray,
	},
});

export const swiperConfig = {
	style: { width: "100%", backgroundColor: "transparent" },
	slidesPerView: 1,
	spaceBetween: 60,
	pagination: {
		clickable: true,
	},
};

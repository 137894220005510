import { light_black } from "../../StyleHelpers";
import { useEffect, useState } from "react";
import { SettingsNavigationProp, routeNewSettings, routeHome, routeDataManagementSettingsDetails, routeDataManagementSettings } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import OtherDataComponent from "../../components/OtherDataComponent";
import LegalTermsConditions from "../../components/LegalTermsConditions";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import TopAppbar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import styled from "styled-components/native";
import AcceptDataComponent from "../../components/AcceptDataComponent";
import { Legals } from "../../models/Legals";
import { AsyncStorageKeys, Pages, SubPages, Type } from "../../Types";
import useGenericContentStore from "../../store/genericContent.store";
import { AcceptOtherData } from "../../models/AcceptOtherData";
import { globalAny } from "../../utils/Utils";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";
import usePrivacyPolicy from "../../hooks/usePrivacyPolicy";

const StyledContainer = styled.View`
	flex: 1;
	background-color: ${light_black};
`;

const SettingsDataManagement = (props: any) => {
	const { location, subLocation } = props.route.params;
	const [state, setState] = useState("");
	const [legal, setLegal] = useState(false);
	const [googleScreen, setGoogleScreen] = useState(false);
	const [videoAnalyticsScreen, setVideoAnalyticsScreen] = useState(false);
	const [otherData, setOtherData] = useState(false);
	const [googleData, setGoogleAnalyticsData] = useState(false);
	const [videoAnalyticsData, setVideoAnalyticsData] = useState(false);
	const [transactionsValue, setTransactionsValue] = useState(false);
	const [legalText, setLegalText] = useState<Legals>();
	const navigationSettingsScreen = useNavigation<SettingsNavigationProp>();
	const transactionHistory = usePaymentTransactionStore((state: any) => state.transactionHistory);
	const navigation = useNavigation();
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const [acceptOtherData, setAcceptOtherData] = useState<AcceptOtherData>();
	const [googleAnalytics, setGoogleAnalytics] = useState("");
	const [videoAnalytics, setVideoAnalytics] = useState("");
	const [sonyPictures, setSonyPictures] = useState("");
	const [sonyCompany, setSonyCompany] = useState("");
	const [acceptDataTitle, setAcceptDataTitle] = useState("");
	const [acceptDataDescription, setAcceptDataDescription] = useState("");
	const [otherDataTitle, setOtherDataTitle] = useState("");
	const [otherDataDescription, setOtherDataDescription] = useState("");
	const [legalData, setLegalData] = useState<Legals>();
	const privacyPolicy = usePrivacyPolicy();
	const [enabledSonyPicture, setEnabledSonyPicture] = useState(false);
	const [enabledSonyGroup, setEnabledSonyGroup] = useState(false);
	const [enabledGoogleAnalytics, setEnabledGoogleAnalytics] = useState(false);
	const [enabledVideoAnalytics, setEnabledVideoAnalytics] = useState(false);

	useEffect(() => {
		if (location === Pages.data) setSelectedTabs(2);
	}, []);

	useEffect(() => {
		if (transactionHistory) {
			setTransactionsValue(transactionHistory);
		}

		if (transactionHistory.length === undefined && location === Pages.transactions) {
			navigationSettingsScreen.navigate(routeNewSettings, {});
		}
	}, [transactionHistory]);

	useEffect(() => {
		(async () => {
			const resultLegal = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheLegalDataCreate) ?? "{}");
			const resultAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			if (resultLegal && resultAcceptOtherData) {
				setLegalData(resultLegal);
				setAcceptOtherData(resultAcceptOtherData);
			}
		})();
	}, []);

	useEffect(() => {
		if (!googleData && !videoAnalyticsData) {
			setOtherData(false);
		}

		if (googleData && videoAnalyticsData) {
			setOtherData(true);
		}
	}, [googleData, videoAnalyticsData]);

	useEffect(() => {
		setGoogleAnalyticsData(otherData);
		setVideoAnalyticsData(otherData);
	}, [otherData]);

	useEffect(() => {
		try {
			setEnabledSonyPicture(initSonyPicture());
			setEnabledSonyGroup(initSonyCompany());
		} catch {
			setEnabledSonyPicture(false);
			setEnabledSonyGroup(false);
		}

		try {
			setEnabledGoogleAnalytics(initialData("Google Analytics", "option-read-more-1"));
			setEnabledVideoAnalytics(initialData("Video Analytics", "option-read-more-1"));
		} catch {
			setEnabledGoogleAnalytics(false);
			setEnabledVideoAnalytics(false);
		}
	}, []);

	const fetchAcceptData = () => {
		let titleData = acceptOtherData?.sections
			? acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title")
			: null;
		let titleDescription = acceptOtherData?.sections
			? acceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description")
			: null;
		if (!titleData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			titleData = cacheAcceptOtherData?.sections
				? cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-title")
				: null;
		}
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			titleDescription = cacheAcceptOtherData?.sections
				? cacheAcceptOtherData?.sections[0]?.options[0]?.optionsWordings?.find((val: any) => val?.key === "option-description")
				: null;
		}
		setAcceptDataTitle(titleData?.textValue);
		setAcceptDataDescription(titleDescription?.textValue);
	};

	const fetchOtherData = () => {
		let titleData = acceptOtherData?.sections
			? acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title")
			: null;
		let titleDescription = acceptOtherData?.sections
			? acceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-description")
			: null;
		if (!titleData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			titleData = cacheAcceptOtherData?.sections
				? cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-title")
				: null;
		}
		if (!titleDescription) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			titleDescription = cacheAcceptOtherData?.sections
				? cacheAcceptOtherData?.sections[0]?.childSections[0]?.sectionWording?.find((val: any) => val?.key === "section-description")
				: null;
		}

		setOtherDataTitle(titleData?.textValue);
		setOtherDataDescription(titleDescription?.textValue);
	};

	useEffect(() => {
		switch (subLocation) {
			case SubPages.termsOfUse:
				onLegal(globalAny.language.terms_of_use, 6);
				return;
			case SubPages.termsOfService:
				onLegal(globalAny.language.terms_of_service, 3);
				return;
			case SubPages.sonyPictures:
				onPressSonyPictures();
				return;
			case SubPages.sonyGroup:
				onPressSonyCompany();
				return;
			case SubPages.googleAnalytics:
				onPressGoogle();
				return;
			case SubPages.videoAnalytics:
				onPressVideoAnalytics();
				return;
		}

		switch (location) {
			case Pages.legal:
				setState(globalAny.language.legal_terms);
				break;
			case Pages.acceptData:
				fetchAcceptData();
				setState("");
				setState(globalAny.language.data_management);
				break;
			case Pages.otherData:
				fetchOtherData();
				setState("");
				setState(globalAny.language.data_management);
				break;
			case Pages.privacy:
				setState(globalAny.language.privacy_policy);
				break;
			case Pages.transactions:
				setState(globalAny.language.transaction_history);
				break;
		}
	}, [location, subLocation]);

	const onSubmit = () => {
		navigationSettingsScreen.push(routeNewSettings, {});
	};

	const onBackPress = () => {
		if (location === Pages.privacy || (location === Pages.legal && !subLocation) || (location === Pages.otherData && !subLocation)) {
			navigationSettingsScreen.navigate(routeNewSettings, {});
			return;
		}

		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}

		navigationSettingsScreen.navigate(routeNewSettings, {});
	};

	const onBackPressOtherData = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
		}
	};

	const onLegal = async (keyValue: string, type: number) => {
		let data = legalData?.legalContents?.filter((val: any) => val?.type === type);
		if (!data) {
			const cacheLegalData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheLegalDataCreate) ?? "{}");
			data = cacheLegalData?.legalContents?.filter((val: any) => val?.type === type);
		}
		if (data) {
			switch (keyValue) {
				case globalAny.language.promotional_terms:
					setState(globalAny.language.promotional_terms);
					setLegalText(data[0]?.htmlContent);
					setLegal(true);
					break;
				case globalAny.language.terms_of_use:
					setState(globalAny.language.terms_of_use);
					setLegalText(data[0]?.htmlContent);
					setLegal(true);
					break;
				case globalAny.language.terms_of_service:
					setState(globalAny.language.terms_of_service);
					setLegalText(data[0]?.htmlContent);
					setLegal(true);
					break;
			}
		}
	};

	const onNext = () => {
		setGoogleScreen(false);
		setVideoAnalyticsScreen(false);
		//@ts-ignore
		navigation.push(routeDataManagementSettings, { location: Pages.otherData });
	};

	const initSonyPicture = () => {
		let data = acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-1");
		if (!data) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			data = cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-1");
		}
		return data[0]?.textValue;
	};

	const initSonyCompany = () => {
		let data = acceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-2");
		if (!data) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			data = cacheAcceptOtherData?.sections[0]?.sectionWording?.filter((val: any) => val?.key === "section-read-more-2");
		}
		return data[0]?.textValue;
	};

	const onPressSonyPictures = () => {
		setSonyPictures(initSonyPicture());
		setState("Sony Pictures");
	};

	const onPressSonyCompany = () => {
		setSonyCompany(`
		  <span style="display: flex; align-items: center;">
			<label target="_blank" style="display: flex; align-items: center; margin-right: 500px; margin-top:-100px;">
			  ${initSonyCompany()}
			</label>
		  </span>
		`);
		setState("Sony Group Companies");
	};

	const onPressGoogle = () => {
		setGoogleAnalytics(initialData("Google Analytics", "option-read-more-1"));
		setGoogleScreen(true);
	};

	const onPressVideoAnalytics = () => {
		setVideoAnalytics(initialData("Video Analytics", "option-read-more-1"));
		setVideoAnalyticsScreen(true);
	};

	const initialData = (name: any, key: any) => {
		let initialData = acceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === name);
		if (!initialData) {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheAcceptOtherDataSettings) ?? "{}");
			initialData = cacheAcceptOtherData?.sections[0]?.childSections[0].options?.filter((val: any) => val?.name === name);
		}
		const data = initialData[0]?.optionsWordings?.filter((val: any) => val?.key === key);
		const title = initialData[0]?.optionsWordings?.filter((val: any) => val?.key === "option-title");
		name === "Google Analytics" ? setGoogleAnalytics(data[0]?.textValue) : setVideoAnalytics(data[0]?.textValue);
		setState(title[0]?.textValue);
		return data[0]?.textValue;
	};

	const onClose = () => {
		//@ts-ignore
		navigation.navigate(routeHome);
	};

	return (
		// @ts-ignores
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={state}
				rightLogo={ridevueLogo}
				onPressLeftIcon={onClose}
				onPressLeftTitleIcon={onBackPress}
				isSingleLogo={true}
			/>
			{location === Pages.legal && !legal && (
				<LegalTermsConditions
					// onPressPromotionalTerms={() => onLegal(globalAny.language.promotional_terms, 7)}

					onPressTermsOfUse={() =>
						//@ts-ignore
						navigation.push(routeDataManagementSettingsDetails, { location: Pages.legal, subLocation: SubPages.termsOfUse })
					}
					onPressTermsOfService={() =>
						//@ts-ignore
						navigation.push(routeDataManagementSettingsDetails, { location: Pages.legal, subLocation: SubPages.termsOfService })
					}
				/>
			)}

			{location === Pages.legal && legal && <PrivacyPolicy text={legalText}></PrivacyPolicy>}

			{location === Pages.acceptData && !subLocation && (
				<AcceptDataComponent
					onPressPrimaryButton={onNext}
					onPressSecondaryButton={onBackPress}
					onPressSonyCompany={() =>
						//@ts-ignore
						navigation.push(routeDataManagementSettingsDetails, { location: Pages.acceptData, subLocation: SubPages.sonyGroup })
					}
					onPressSonyPictures={() =>
						//@ts-ignore
						navigation.push(routeDataManagementSettingsDetails, { location: Pages.acceptData, subLocation: SubPages.sonyPictures })
					}
					type={Type.Settings}
					title={acceptDataTitle}
					description={acceptDataDescription}
					sonyPictures={enabledSonyPicture}
					sonyCompany={enabledSonyGroup}
				/>
			)}

			{location === Pages.otherData && !subLocation && (
				<OtherDataComponent
					onSubmit={(val) => setOtherData(val)}
					onChangeGoogleData={(val) => setGoogleAnalyticsData(val)}
					onChangeVideoAnalyticsData={(val) => setVideoAnalyticsData(val)}
					onPressPrimaryButton={onSubmit}
					onPressSecondaryButton={() => onBackPressOtherData()}
					onPressGoogle={() =>
						//@ts-ignore
						navigation.push(routeDataManagementSettingsDetails, { location: Pages.otherData, subLocation: SubPages.googleAnalytics })
					}
					onPressVideoAnalytics={() =>
						//@ts-ignore
						navigation.push(routeDataManagementSettingsDetails, { location: Pages.otherData, subLocation: SubPages.videoAnalytics })
					}
					isSettings={true}
					title={otherDataTitle}
					description={otherDataDescription}
					googleAnalytics={enabledGoogleAnalytics}
					videoAnalytics={enabledVideoAnalytics}
				/>
			)}

			{location === Pages.otherData && googleScreen && <PrivacyPolicy text={googleAnalytics}></PrivacyPolicy>}

			{location === Pages.otherData && videoAnalyticsScreen && <PrivacyPolicy text={videoAnalytics}></PrivacyPolicy>}

			{location === Pages.acceptData && subLocation === SubPages.sonyPictures && <PrivacyPolicy text={sonyPictures}></PrivacyPolicy>}

			{location === Pages.acceptData && subLocation === SubPages.sonyGroup && <PrivacyPolicy text={sonyCompany}></PrivacyPolicy>}

			{location === Pages.privacy && <PrivacyPolicy text={privacyPolicy}></PrivacyPolicy>}

			{location === Pages.transactions && <PrivacyPolicy transactionHistory={transactionsValue}></PrivacyPolicy>}
		</StyledContainer>
	);
};

export default SettingsDataManagement;

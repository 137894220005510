import Image from "../Image/Image";
import iconChevron from "../../assets/Icons/icon_chevron-right.svg";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import { Props } from "./model";
import { StyledContainer, StyledLoader, StyledText, StyledTextContainer, StyledTitle } from "./styled";

const PlaylistTitle = (props: Props) => {
	const hasTitle = props.title.trim().length;

	return (
		// @ts-ignore
		<StyledTextContainer noSpacing={props.noSpacing}>
			{/* @ts-ignore */}
			<StyledText>
				{hasTitle && !props?.isContinueWatching ? (
					<TouchableOpacity onPress={props.onPress} style={props.removeCursor ? { cursor: "default" } : ""}>
						{/* @ts-ignore */}
						<StyledContainer>
							{/* @ts-ignore */}
							<StyledTitle>{props.title}</StyledTitle>
							<Image source={{ uri: iconChevron }} width={24} height={24} />
						</StyledContainer>
					</TouchableOpacity>
				) : (
					<></>
				)}

				{hasTitle && props?.isContinueWatching ? (
					//@ts-ignore
					<StyledContainer>
						{/* @ts-ignore */}
						<StyledTitle>{props.title}</StyledTitle>
					</StyledContainer>
				) : (
					<></>
				)}

				{!props.title && !props.hasCategoryTranslation && (
					// @ts-ignore
					<StyledLoader>
						<ImgProgressBar />
					</StyledLoader>
				)}
			</StyledText>
		</StyledTextContainer>
	);
};

export default PlaylistTitle;

import { Text, View } from "react-native";
import { black, spacing_l } from "../../StyleHelpers";
import GenresComponent from "../../components/GenresComponent";
import { globalAny } from "../../utils/Utils";
import { StyledCast, StyledColumn1, StyledColumn2, StyledColumnText, StyledContainer, StyledOverflow, StyledText, styles } from "./styled";

const Details = (props: any) => {
	const data = [
		globalAny.language.directed_by,
		globalAny.language.starring,
		globalAny.language.audio_languages,
		globalAny.language.available_subtitles,
	];

	return (
		<View style={{ flex: 1, backgroundColor: black }}>
			{/* @ts-ignore */}
			<StyledContainer isPreview={props?.isPreview}>
				{/* @ts-ignore */}
				<StyledOverflow isPreview={props?.isPreview}>
					<GenresComponent rating={props.rating} genres={props.genres} />
					{/* @ts-ignore */}
					<Text style={[styles.metadata, { fontSize: 18, marginBottom: spacing_l }]}>{props.ratingReason}</Text>
					<Text style={[styles.metadata, { fontSize: 25, marginBottom: spacing_l }]}>{props?.synopsis}</Text>
					{/* @ts-ignore */}
					{data.map((item: any, index: any) => {
						return (
							// @ts-ignore
							<StyledText key={`metadata-content-${item}`}>
								{/* @ts-ignore */}
								<StyledColumn1>
									{/* @ts-ignore */}
									<StyledColumnText>
										<Text style={[styles.metadata, { fontSize: 18, marginBottom: spacing_l }]}>{item}</Text>
									</StyledColumnText>
								</StyledColumn1>
								{/* @ts-ignore */}
								<StyledColumn2>
									{/* @ts-ignore */}
									<StyledColumnText>
										{index === 0 ? (
											<Text key={`metadata-director-1`} style={[styles.metadata, { fontSize: 18, marginBottom: spacing_l }]}>
												{props.director.join(", ")}
											</Text>
										) : (
											""
										)}

										{index === 1 ? (
											// @ts-ignore
											<StyledCast>
												<Text key={`metadata-cast-1}`} style={[styles.metadata, { fontSize: 18 }]}>
													{props.cast.join(", ")}
												</Text>
											</StyledCast>
										) : (
											""
										)}
										{index === 2 ? <Text style={[styles.metadata, { fontSize: 18 }]}>{props.audioLang}</Text> : ""}
										{index === 3 ? <Text style={[styles.metadata, { fontSize: 18 }]}>{props.subtitle}</Text> : ""}
									</StyledColumnText>
								</StyledColumn2>
							</StyledText>
						);
					})}
					<Text style={[styles.metadata, { fontSize: 18, marginBottom: "5%" }]}>{props.copyright}</Text>
				</StyledOverflow>
			</StyledContainer>
		</View>
	);
};

export default Details;

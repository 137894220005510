import styled, { css } from "styled-components/native";
import { spacing_m, spacing_xs } from "../../StyleHelpers";
import { Props } from "./model";
import { StyleSheet } from "react-native";

export const landscapeWidth = "21.56vw";

export const StyledProgress = styled.View<Props>`
	border-radius: 16px;
	overflow: hidden;
	z-index: -1;

	${(props) =>
		props.isLandscape &&
		props.backupImage &&
		css`
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		`}

	${(props) =>
		props.isLandscape &&
		!props.backupImage &&
		css`
			width: 100%;
			height: auto;
			aspect-ratio: 16 / 9;
		`}

	${(props) =>
		!props.isLandscape &&
		css`
			width: 100%;
			height: 100%;
			aspect-ratio: 2 / 3;
		`}
`;

export const StyledProgressBar = styled.View`
	z-index: 1;
	bottom: 10px;
	width: 100%;
	padding: 0 20px 10px 20px;
	position: absolute;
`;

export const StyledTitle = styled.View`
	width: ${landscapeWidth};
	margin-top: ${spacing_xs};
	margin-bottom: ${spacing_m};
`;

export const StyledTitleText = styled.Text`
	color: white;
	font-size: 21px;
`;

export const StyledIconContainer = styled.View`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const styles = StyleSheet.create({
	container: {
		width: "100%",
		height: "100%",
	},
	containerClips: {
		width: "100%",
	},
	landscape: {
		width: landscapeWidth,
		height: "auto",
		aspectRatio: 16 / 9,
	},
	portrait: {
		width: "100%",
		height: "auto",
		aspectRatio: 2 / 3,
	},
	imageIcon: {
		width: 56,
		height: 56,
		position: "absolute",
		backgroundColor: " rgba(0, 0, 0, .5)",
		borderRadius: 32,
	},
	progressBar: {
		borderRadius: 10,
		height: 8,
		backgroundColor: "rgba(255,255,255, .5)",
	},
});

import { AsyncStorageKeys } from "../../Types";
import { globalAny } from "../../utils/Utils";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import TopAppbar from "../../components/TopAppBar";
import { useEffect, useState } from "react";
import { View } from "react-native";
import styled from "styled-components/native";
import { light_black } from "../../StyleHelpers";
import { useNavigation } from "@react-navigation/native";
import parse from "html-react-parser";

const StyledContainer = styled.View`
	height: 100vh;
	z-index: -1;
	padding-top: 180px;
`;

const StyledOverflow = styled.View`
	height: 100%;
	overflow: scroll;
	padding-left: 20px;
	padding-right: 20px;
`;

const StyledText = styled.Text`
	font-size: 25px;
	color: white;
	margin-bottom: 100px;
`;

const StyledWrapper = styled.View`
	z-index: -1;
	padding: 0 14.1% 0 14.1%;
`;

const PrivacyPolicyScreen = () => {
	const navigation = useNavigation<any>();
	const [privacyPolicyText, setPrivacyPolicyText] = useState("");

	const onBackPress = () => {
		if (navigation.canGoBack()) {
			navigation.goBack();
			return;
		}
		window.location.assign("/");
	};

	useEffect(() => {
		(async () => {
			const resultLegal = JSON.parse(localStorage.getItem(AsyncStorageKeys.cacheLegalDataCreate) ?? "{}");
			if (resultLegal) {
				let [data] = resultLegal?.legalContents?.filter((val: any) => val?.type === 2) ?? [];
				setPrivacyPolicyText(data?.htmlContent);
			}
		})();
	}, []);

	return (
		<View style={{ zIndex: -1, backgroundColor: light_black }}>
			<TopAppbar
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={globalAny.language.privacy_policy}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={onBackPress}
				isSingleLogo={true}
			/>
			{/* @ts-ignore */}
			<StyledWrapper>
				{/* @ts-ignore */}
				<StyledContainer>
					{/* @ts-ignore */}
					<StyledOverflow>
						{/* @ts-ignore */}
						<StyledText>{parse(privacyPolicyText)}</StyledText>
					</StyledOverflow>
				</StyledContainer>
			</StyledWrapper>
		</View>
	);
};

export default PrivacyPolicyScreen;

import { globalAny } from "../../utils/Utils";
import { Props } from "./model";
import { StyledGradient, StyledStudioAccessBanner, StyledTextContainer } from "./styled";

const Banner = (props: Props) => {
	return (
		//@ts-ignore
		<StyledStudioAccessBanner banner={props.banner}>
			{/* @ts-ignore */}
			<StyledTextContainer>
				{/* @ts-ignore */}
				<StyledText>{globalAny.language.banner_text}</StyledText>
			</StyledTextContainer>
			{/* @ts-ignore */}
			<StyledImage />
			<StyledGradient />
		</StyledStudioAccessBanner>
	);
};

export default Banner;

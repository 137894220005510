import { View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/native";
import H4 from "../Typography/H4";
import FontWeightType from "../../models/FontWeightType";
import { light_black, spacing_l } from "../../StyleHelpers";
import useGenericContentStore from "../../store/genericContent.store";

interface Props {
	children: any;
	tabsToRender: any;
	paddingLeft?: any;
	disabled?: any;
	isSettings?: boolean;
}

interface PropsCommonAttributes {
	shouldHighlight?: boolean;
}

const StyledTextContent = styled.View<PropsCommonAttributes>`
	padding: 20px 25px;
	position: relative;
	z-index: 999;
`;

const StyledTouchableOpacity = styled.TouchableOpacity<PropsCommonAttributes>`
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	background: ${(props) => (props.shouldHighlight ? "#182B47" : "transparent")};
`;

const StyledBackgroundView = styled.View`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #2362be;
	opacity: 0.3;
	z-index: 0;
`;

const StyledBottomBorder = styled.View`
	height: 4px;
	background-color: #2362be;
	width: 100%;
`;

const StyledContainer = styled.View<Props>`
	position: -webkit-sticky;
	position: sticky;
	flex-direction: row;
	background-color: ${light_black};
	top: ${(props) => (props.isSettings ? "0px" : "112px")};
	z-index: 1;
	padding-left: ${(props) => props.paddingLeft};

	${(props) =>
		props.isSettings &&
		css`
			border-bottom-width: 1px;
			border-color: #5a5a5a;
			width: 105%;
			padding-left: ${spacing_l};
		`}
`;

const TabsContainer = styled.View<Props>`
	${(props) =>
		props.isSettings &&
		css`
			margin-left: 90px;
		`}
`;

const TabsView = (props: Props) => {
	const [contentToRender, setContentToRender] = useState(0);
	const selectedTabs = useGenericContentStore((state: any) => state.selectedTabs);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);

	useEffect(() => {
		if (selectedTabs) {
			setContentToRender(selectedTabs);
		}
	}, [selectedTabs]);

	return (
		// @ts-ignore
		<TabsContainer isSettings={props.isSettings}>
			{/* @ts-ignore */}
			<StyledContainer paddingLeft={props.paddingLeft} isSettings={props.isSettings}>
				{props.tabsToRender.map((val: any, idx: any) => (
					// @ts-ignore
					<StyledTouchableOpacity
						key={`tabs-name-${val.title}`}
						shouldHighlight={idx === selectedTabs}
						onPress={() => {
							setContentToRender(idx);
							setSelectedTabs(idx);
						}}
						disabled={val.disabled}
					>
						{/* @ts-ignore */}
						<StyledTextContent>
							<H4 fontWeight={FontWeightType.Medium}>
								<Text style={{ opacity: val.disabled ? 0.5 : 1 }}>{val.title}</Text>
							</H4>
						</StyledTextContent>
						{idx === selectedTabs && <StyledBackgroundView />}
						{idx === selectedTabs && <StyledBottomBorder />}
					</StyledTouchableOpacity>
				))}
			</StyledContainer>

			<View style={{ width: "100%" }}>{props.children[selectedTabs]}</View>
		</TabsContainer>
	);
};

export default TabsView;

import { create } from "zustand";
import { devtools } from "zustand/middleware";
const useToggleStore = create(
	devtools((set) => ({
		isToggleQR: false,
		setIsToggleQR: (isToggleQR: boolean) => set({ isToggleQR: isToggleQR }),
		isToggleAutoLogin: false,
		setIsToggleAutoLogin: (isToggleAutoLogin: boolean) => set({ isToggleAutoLogin: isToggleAutoLogin }),
		isToggleMQTT: false,
		setIsToggleMQTT: (isToggleMQTT: boolean) => set({ isToggleMQTT: isToggleMQTT }),
		isToggleAdvanceSettings: false,
		setIsToggleAdvanceSettings: (isToggleAdvanceSettings: boolean) => set({ isToggleAdvanceSettings: isToggleAdvanceSettings }),
	})),
);
export default useToggleStore;

import { StyleSheet } from "react-native";

export const PRESSED_BUTTON_COLOR = "#313131";

//Global Color
export const wisteria = "#9575cd";
export const deep_aqua = "#0a8470";
export const deep_brown = "#360000";
export const mid_blue = "#2362be";
export const palladium = "#b2b2b2";
export const light_peach = "#f4d1d1";
export const cocoa = "#804848";
export const light_black = "#141414";
export const tealish = "#1bbc9b";
export const white = "#fff";
export const black = "#000";
export const peacock_blue = "#0045ac";
export const navy_blue = "#0A1D39";
export const royal_blue = "rgba(39, 86, 156, 0.3)";
export const salmon = "#ef877f";
export const dull_red = "#c4443a";
export const white_smoke = "#f2f2f2";
export const macaroni_and_cheese = "#fec32d";
export const muted_pink = "#e16490";
export const lipstick = "#d32b6c";
export const underpass_shrine = "#cc431f";
export const void_color = "rgba(10, 29, 57, 0.95)";
export const gray = "#1e1e1e";
export const separator_gray = "#3f3f3f";
export const toastSuccess = "#008131";
export const toastWarning = "#D07000";
export const toastError = "#FF0000";
export const shakaPlayColor = "#1e4cad";
export const highlightColor = "#008BEF";
export const silver = "#c0c0c0";
export const gray_one = "#030303";
export const royal_blue_hex = "#002356";
export const scorpion = "#5A5A5A";
export const dark_gray = "#363636";
export const dark = "rgba(51, 51, 51, 1)";

//Sync group color
export const purple_blue = "#8c2bd3";
export const aloha = "#1bbc9b";
export const love = "#e16490";

//Global Spacing
export const spacing_xxs = "4px";
export const spacing_xs = "8px";
export const spacing_s = "16px";
export const spacing_m = "24px";
export const spacing_l = "32px";
export const spacing_xl = "40px";
export const spacing_xxl = "48px";

//Global Spacing vw
export const spacing_xxs_vw = "0.20vw";
export const spacing_xs_vw = "0.41vw";
export const spacing_s_vw = "0.83vw";
export const spacing_m_vw = "1.25vw";
export const spacing_l_vw = "1.66vw";
export const spacing_xl_vw = "2.08vw";
export const spacing_xxl_vw = "2.5vw";

//Global Spacing vh
export const spacing_xxs_vh = "0.43vh";
export const spacing_xs_vh = "0.86vh";
export const spacing_s_vh = "1.72vh";
export const spacing_m_vh = "2.58vh";
export const spacing_l_vh = "3.44vh";
export const spacing_xl_vh = "4.30vh";
export const spacing_xxl_vh = "6.24vh";

//Media Query Breakpoint
export const sm = "640px";
export const md = "768px";
export const lg = "1024px";
export const xl = "1280px";
export const x2l = "1536px";

//Packshit size
export const portraitWidthGlobal = 268;
export const portraitHeightGlobal = 401;
export const landscapeWidthGlobal = 556;
export const landscapeHeightGlobal = 315;

//Global Minimum Width
export const minWidth = "1024px";

// TextInputWithButtons component base sizes
export const textInputWithButtonsFontSize = 36;
export const textInputWithButtonsButtonWidth = 512;
export const textInputWithButtonsButtonHeight = 128;
export const textInputWithButtonsSmallIconWidth = 191;
export const textInputWithButtonsSmallIconHeight = 128;
export const textInputWithButtonsLargeIconWidth = 439;
export const textInputWithButtonsLargeIconHeight = 135;
export const textInputWithButtonsBoxWidth = 904;
export const textInputWithButtonsBoxHeight = 128;
export const textInputWithButtonsButtonUnderlay = "#121212";

//CreateAccount base sizes
export const textInputWidth = 904;
export const textInputHeight = 128;
export const subLabelWidth = 904;
export const subLabelHeight = 0;
export const topLabelWidth = 904;
export const topLabelHeight = 128;
export const labelFontSize = 36;
export const subLabelFontSize = 16;
export const subLabelFontsize = 15;
export const monthInputWidth = topLabelWidth / 6;
export const yearInputWidth = topLabelWidth / 3;
export const dateMargin = 36.5;
export const labelMargin = 21;
export const legalTitleFontSize = 32;
export const legalBodyFontSize = 25;
export const displayLegalDocIconHeight = 76;
export const displayLegalDocIconWidth = 76;

//Create Account Styles
export const legalTextOpacity = 0.6;

// profileName screen base sizes
export const profileNameSelectHeaderFontSize = 44;
export const profileNameSelectButtonWidth = 480;
export const profileNameSelectButtonHeight = 216;
export const profileNameSelectIconWidth = 48;

// header base sizes
export const headerStandardFontSize = 28;
export const headerSmallFontSize = 20;
export const headerPauseButtonWidth = 200;
export const headerPauseButtonHeight = 80;
export const headerDriveModeButtonWidth = 80;
export const headerDriveModeButtonHeight = 70;
export const stopAllMarginLeft = "5%";
export const pageTitleFontSize = 36;
export const logoHeight = 46;
export const logoWidth = 297.26;

//Splash Screen
export const LargelogoHeight = "30%";
export const LargelogoWidth = "100%";

// home base sizes
export const homeScreenIconFontSize = 28;
export const homeIconContainerSize = 360;
export const homeIconWidth = 36;
export const homeIconContainerHeight = 432;
export const homeIconImageHeight = 287.3;
export const homeIconLinearGradientHeight = 65;
export const carouselHeaderTextSize = 24;

// title details base sizes
export const titleDetailsTitleHeaderFontSize = 56;
export const titleDetailsDescriptionFontSize = 28;
export const titleDetailsProfileNameFontSize = 24;
export const titleDetailsSeeMoreFontSize = 28;
export const titleDetailsPlayButtonFontSize = 36;
export const titleDetailsSelectScreensHeader = 24;
export const titleDetailsUserDeviceToggleWidth = 240;
export const titleDetailsUserDeviceToggleHeight = 136;
export const titleDetailsUserDeviceToggleBorderWidth = 6;
export const titleDetailsIconWidth = 80;
export const titleDetailsMoreButtonWidth = 207;
export const titleDetailsMoreButtonHeight = 128;
export const titleDetailsPlayButtonWidth = 464;
export const titleDetailsPlayButtonHeight = 128;
export const titleDetailsDeviceActiveBorderColor = "#2ca3f1";
export const titleDetailsDeviceInactiveBorderColor = "#707070";
export const titleDetailsSelectScreensViewHeight = 216;

// title metadata base sizes
export const titleMetadataTitleHeaderFontSize = 56;
export const titleMetadataDescriptionFontSize = 28;
export const titleMetadataButtonFontSize = 10;
export const titleMetadataTrailerButtonFontSize = 36;
export const titleMetadataBackButtonFontSize = 36;
export const titleMetadataTrailerButtonWidth = 464;
export const titleMetadataTrailerButtonHeight = 128;
export const titleMetadataBackButtonWidth = 240;
export const titleMetadataBackButtonHeight = 120;
export const titleMetadataIconWidth = 80;

// quick nav base sizes
export const quickNavButtonFontSize = 36;
export const quickNavButtonWidth = 304;
export const quickNavButtonHeight = 408;
export const quickNavIconWidth = 77.7;
export const quickNavIconHeight = 64;

// packshot base sizes
export const portraitPackshotWidth = 288;
export const portraitPackshotHeight = 432;
export const landscapePackshotWidth = 637;
export const landscapePackshotHeight = 358;
export const vamPackshotWidth = 266;
export const vamPackshotHeight = 150;
export const extrasPackshotWidth = 392;
export const extrasPackshotHeight = 221;
export const extrasBackingPlateWidth = 394;
export const extrasBackingPlateHeight = 307;
export const extrasPlayIconWidth = 80;
export const extrasTextFontSize = 28;

// back button
export const backButtonWidth = 17.2;
export const backButtonHeight = 30;
export const backButtonFontSize = 24;

// grid base sizes
export const gridViewScreenParentCategoryFontSize = 28;
export const gridViewScreenCurrentCategoryFontSize = 36;
export const gridViewScreenContentContainerWidth = 1600;

// see more button
export const seeMoreButtonWidth = 270;
export const seeMoreButtonTextSize = 32;
export const seeMoreButtonArrowHeight = 128;
export const seeMoreIconWidth = 80;

// Arrow Buttons
export const arrowButtonWidth = 72;
export const arrowButtonHeight = 128;
export const arrowIconWidth = 27.6;
export const arrowIconHeight = 48;

// Error Pop up
export const errorPopupTitleFontSize = 44;
export const errorPopupButtonFontSize = 36;
export const errorPopupBodyFontSize = 28;
export const errorPopupButtonWidth = 512;
export const errorPopupButtonHeight = 128;

// Screen Manager
export const screenManagerChannelButtonWidth = 624;
export const screenManagerChannelButtonHeight = 368;
export const screenManagerChannelButtonPackshotWidth = 192;
export const screenManagerChannelButtonPackshotHeight = 286;
export const screenManagerChannelButtonTitleWidth = 344;
export const screenManagerChannelButtonTitleHeight = 120;
export const screenManagerControllerPackshotWidth = 256;
export const screenManagerControllerPackshotHeight = 384;
export const screenManagerSubtitleFontSize = 24;
export const screenManagerActiveDevicesFontSize = 24;
export const screenManagerControllerActiveDevicesFontSize = 28;
export const screenManagerTitleFontSize = 32;
export const screenManagerControllerTitleFontSize = 36;
export const screenManagerControllerIconLabelFontSize = 32;
export const screenManagerIconSize = 176;
export const screenManagerControllerWidth = 1648;
export const screenManagerControllerHeight = 448;
export const screenManagerPackshotWidth = 192;
export const screenManagerNoContentContainerWidth = 1536;
export const screenManagerNoContentContainerHeight = 400;
export const screenManagerNoContentContainerTextFontSize = 36;
export const screenManagerNoContentContainerSubtextFontSize = 28;
export const screenManagerNoContentContainerLinearGradientLightColor = "#343434";
export const screenManagerNoContentContainerLinearGradientDarkColor = "#181818";

// Play Icon Margins
export const playIconMarginLeft = 48;
export const playIconMarginRight = 70;

// Eye Button
export const eyeWidth = 60;
export const eyeHight = 60;

export const backIconMarginTop = 20;

export const StyleHelper = StyleSheet.create({
	whiteBackground: {
		backgroundColor: "white",
	},
	backButton: {
		backgroundColor: "#17181b",
		borderColor: "#3c4043",
		borderWidth: 1,
		borderRadius: 24,
		marginTop: 20,
		marginLeft: 5,
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		zIndex: 2,
	},
	backButtonText: {
		color: "white",
	},
	descriptionText: {
		alignSelf: "stretch",
		color: "#fcfcfc",
	},
	playContentButton: {
		backgroundColor: "#121212",
		justifyContent: "center",
		borderRadius: 10,
		borderColor: "#3c4043",
	},
	playIcon: {
		resizeMode: "contain",
	},
	backIcon: {
		resizeMode: "contain",
	},
	bottomButton: {
		backgroundColor: "#121212",
		justifyContent: "center",
		borderRadius: 10,
		borderColor: "#3c4043",
	},
	profileNameScreenTitle: {
		marginTop: 44,
		marginBottom: 40,
	},
	packshotSeparator: {
		marginRight: 32,
	},
	ScreenLabel: {
		lineHeight: 42,
		letterSpacing: 0.58,
		fontSize: 40,
		borderColor: "white",
		textAlign: "left",
		flex: 1,
	},
});

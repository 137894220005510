import styled from "styled-components/native";
import { globalAny } from "../../utils/Utils";
import { white_smoke } from "../../StyleHelpers";
import ComponentThemeType from "../../models/ComponentTheme";
import TouchableButton from "../TouchableButton";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";

const StyledExitPreviewButton = styled.View`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 6rem;
	background: rgba(0, 0, 0, 0.9);
	display: flex;
	flex-direction: row;
	padding: 0.9rem 5.75rem 0.9rem 5.75rem;
	align-items: center;
	justify-content: space-between;
`;

const StyledLabel = styled.Text`
	display: flex;
	width: 25.9375rem;
	height: 2.5rem;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;
	color: ${white_smoke};
	font-size: 1.5625rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.75rem;
	height: 100%;
`;

const StyledButtonContainer = styled.View`
	width: auto;
	height: 100%;
`;

const StyledText = styled.View`
	margin-top: -2;
`;

const ExitPreviewButton = (props: any) => {
	return (
		//@ts-ignore
		<StyledExitPreviewButton>
			{/* @ts-ignore */}
			<StyledLabel>{globalAny.language.preview_mode}</StyledLabel>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Primary} onPress={() => props.onPress()}>
					{/* @ts-ignore */}
					<StyledText>{globalAny.language.exit_preview}</StyledText>
				</TouchableButton>
			</StyledButtonContainer>
		</StyledExitPreviewButton>
	);
};

export default ExitPreviewButton;

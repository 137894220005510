import { ActivityIndicator, useWindowDimensions } from "react-native";
import { light_black } from "../../StyleHelpers";
import styled from "styled-components/native";
import ridevueLogo from "../../assets/Logo/logos.svg";
import Image from "../../components/Image/Image";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100%;
`;

const StyledMain = styled.View`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-left: 14.1%;
	margin-right: 14.1%;
	margin-bottom: 70px;
	border-radius: 16px;
	top: 15vh;
	overflow: hidden;
`;

const ButtonContainer = styled.View`
	height: 72px;
	overflow: hidden;
	border-radius: 16px;
	margin-left: 10px;
	margin-right: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 100%;
`;

const StyledButtonContainerWrapper = styled.View`
	display: flex;
	flex-direction: column;
	width: 71.8%;
	position: absolute;
	bottom: 25%;
	margin: 0 14.1% 0 14.1%;
	gap: 10;
`;

const StyledButtonContainer = styled.View`
	flex-direction: row;
	width: 100%;
`;

const LandingLoader = () => {
	const windowDimensions = useWindowDimensions();
	return (
		// @ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			<StyledMain>
				<Image
					source={{ uri: ridevueLogo }}
					windowDimensions={windowDimensions}
					width={windowDimensions.width * 0.7}
					height={windowDimensions.height * 0.25}
					style={{ marginTop: 41, marginBottom: "16.5625vw" }}
					resizeMode="contain"
				/>
			</StyledMain>
			{/* @ts-ignore */}
			<StyledButtonContainerWrapper>
				<ActivityIndicator size={80} color={"white"} />
			</StyledButtonContainerWrapper>
		</StyledContainer>
	);
};

export default LandingLoader;

import { TRANSACTION_TYPES } from "../networking/networking";
import * as apiMoviesContentService from "../services/apiMoviesContentService";
import { getMetadata, getOwnedStatus } from "./metadataService";
import { getVamPlaylist } from "../services/vamService";

export const featureContent = async (category: string, transactionalFilters: string) => {
	const getCategoryId = await apiMoviesContentService.getHeroBanner(category, transactionalFilters);
	const result: any = await apiMoviesContentService.getFeaturedLists(getCategoryId[0]?.id, transactionalFilters);
	return result.featureList;
};

export const playlistContent = async (playlistId: any, transactionalFilters: string) => {
	const result = await apiMoviesContentService.getPlaylistSummary(playlistId, transactionalFilters);
	return result;
};

export const vamPlaylistContent = async (playlistId: any, transactionalFilters: string) => {
	const result = await apiMoviesContentService.getVamPlaylistSummary(playlistId, transactionalFilters);
	return result;
};

export const metadataContent = async (parentProductId: any) => {
	const owned: any = await getOwnedCountryId(parentProductId);
	const ownedstatus = owned.ownedstatus;
	const metaDataApi = getMetadata(parentProductId, null, owned?.countryId);
	const imagesApi = apiMoviesContentService.getTitleDetailsImages(parentProductId);
	const vamPlaylist = getVamPlaylist(parentProductId);

	const [metadata, images, vam] = await Promise.all([metaDataApi, imagesApi, vamPlaylist]);

	return { metadata, images, ownedstatus, vam };
};

export const metadataContentPreview = async (parentProductId: any) => {
	const metaDataApi = getMetadata(parentProductId);
	const imagesApi = apiMoviesContentService.getTitleDetailsImages(parentProductId);

	const [metadata, images] = await Promise.all([metaDataApi, imagesApi]);

	return { metadata, images };
};

export const getOwnedCountryId = async (parentProductId: any) => {
	const ownedstatus: any = await getOwnedStatus(parentProductId, [TRANSACTION_TYPES.EST, TRANSACTION_TYPES.SVOD, TRANSACTION_TYPES.TVOD]);
	const owned: any = ownedstatus.find((own: any) => own.owned);
	const countryId = owned ? owned.countryId : "";

	return { countryId, ownedstatus };
};

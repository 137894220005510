import "./index.css";
import { Provider as PaperProvider } from "react-native-paper";
import { Platform } from "react-native";
import App from "./App";
import ReactDOM from "react-dom";

ReactDOM.render(
	<PaperProvider>
		{Platform.OS === "web" ? (
			<style type="text/css">
				{`
						@font-face {
							font-family: 'MaterialCommunityIcons';
							src: url(${require("react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf").default}) format('truetype');
						}
						@font-face {
							font-family: 'FontAwesome5_Regular';
							src: url(${require("react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf").default}) format('truetype');
						@font-face {
							font-family: 'FontAwesome5_Solid';
							src: url(${require("react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf").default}) format('truetype');
						}
					`}
			</style>
		) : null}
		<App />
	</PaperProvider>,
	document.getElementById("root"),
);

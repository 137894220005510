import { AsyncStorageKeys } from "../Types";
import { USER_BASEURL_V7, USER_BASEURL_V6, USER_BASEURL_V10 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const accountCredits = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "Credits", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const accountCreditsExpiration = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "CreditsExpiration", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const accountSubscription = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V7 + "Info", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				if (resJson.activeSubscriptions) {
					const [activeSubscriptions] = resJson.activeSubscriptions;
					localStorage.setItem(AsyncStorageKeys.subscription, JSON.stringify(activeSubscriptions));
				}
				resolve(resJson);
			})
			.catch((err) => reject(err.message));
	});
	return promise;
};

export const signInCode = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V6 + "signincode/create", { method: "POST", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

export const automaticVoucherRedemption = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			USER_BASEURL_V10 +
				"automaticvoucherredemption" +
				`?deviceModel=${localStorage.getItem(AsyncStorageKeys.hwid) ? "mercedez-benz" : "WEB"}` +
				`&udid=${localStorage.getItem(AsyncStorageKeys.deviceIdentifier) ?? "WEB-MERCEDES"}` +
				`&hardwareId=${localStorage.getItem(AsyncStorageKeys.hwid) ?? ""}` +
				`&isHwidEncrypted=${localStorage.getItem(AsyncStorageKeys.hwid) !== null}`,
			{ method: "GET", headers: getHeaders(false) },
		)
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

export const infoEmail = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V7 + "info/email", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

export const infoSubscription = async () => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(USER_BASEURL_V7 + "info/subscription", { method: "GET", headers: getHeaders(true) })
			.then((resJson) => {
				const endDate = new Date(resJson?.subscriptionEndDate);
				const today = new Date();

				resJson?.subscriptionEndDate && today < endDate
					? localStorage.setItem(AsyncStorageKeys.subscription, JSON.stringify(resJson))
					: localStorage.removeItem(AsyncStorageKeys.subscription);

				resolve(resJson);
			})
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

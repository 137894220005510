import styled, { css } from "styled-components/native";
import { peacock_blue, spacing_m, spacing_xl, white_smoke } from "../../StyleHelpers";
import { Modal as ModalPaper } from "react-native-paper";

export const StyledModal = styled(ModalPaper)`
	background-color: rgba(0, 0, 0, 0.9);
	align-items: center;
	position: fixed;
	z-index: 1001;
`;

export const StyledInnerContent = styled.View`
	justify-content: center;
	align-items: center;
	padding: 10px ${spacing_xl} 0px ${spacing_xl};
`;

export const StyledInnerContentNarrow = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	padding: 10px ${spacing_xl} 0px ${spacing_xl};
`;

export const StyledButtonNarrow = styled.View`
	flex-direction: column;
	align-items: center;
	flex: 1 0 0;
`;

export const StyledCloseContent = styled.View`
	align-items: end;
	margin-top: 10px;
	margin-right: 10px;
`;

export const StyledTextContainer = styled.View`
	text-align: left;
	opacity: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
`;

export const StyledTextContainerNarrow = styled.View`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.5rem;
	flex: 1 0 0;
`;

export const StyledMargin = styled.Text`
	margin-bottom: 30px;
`;

export const StyledBottomContainer = styled.View`
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
	margin: 5px 0px 20px 0px;
`;

export const StyledBottomInnerContainer = styled.View`
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: 100%;
`;

export const StyledBottomText = styled.Text`
	text-align: center;
	flex: 1;
	color: white;
`;

export const StyledText = styled.Text<any>`
	text-align: center;
	font-size: 25px;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	${(props) => css`
		color: ${props.fontColor};
	`}
`;

export const StyledSubText = styled.Text`
	color: white;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: ${spacing_m};
`;

export const StyledTextLoadingContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

export const StyledSpinner = styled.View`
	margin-bottom: 12;
`;

export const StyledLegal = styled.View`
	justify-content: center;
	align-items: center;
	background-color: ${peacock_blue};
	width: 100%;
	height: 94px;
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
	margin-top: 10px;
`;

export const StyledLegalText = styled.Text<any>`
	color: ${white_smoke};
	font-size: 1.2rem;
	text-decoration-line: ${(props) => (props.underline ? "underline" : "none")};
`;

export const StyleModalContainer = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const StyleContentContainer = styled.View<any>`
	border-radius: 16;
	width: ${(props) => (props.width ? props.width : "68rem")};
	height: ${(props) => (props.height ? props.height : "auto")};
	background: #1f262b;
`;

export const LoadingStyle = {
	zIndex: 1001,
};

export const customizeStyle = {
	width: "100%",
	height: "100%",
	zIndex: 1001,
};

import { useState } from "react";
import { globalAny, capitalize, formatVoucherCode } from "../../utils/Utils";
import { useNavigation } from "@react-navigation/native";
import { SettingsNavigationProp, routeNewSettings, routeHome } from "../../Routes";
import { Code_OK } from "../../networking/networking";
import { redeemVoucherCode, validateVoucher as validateVoucherAPI } from "../../services/loginService";
import { voucherValidate } from "../../utils/ValidateResponse";
import { AsyncStorageKeys } from "../../Types";
import { light_black } from "../../StyleHelpers";
import CustomizeDialogBox from "../../components/DialogMessageBox/CustomizeDialogBox";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TextInput from "../../components/TextInput";
import ComponentThemeType from "../../models/ComponentTheme";
import FormRow from "../../components/Layout/FormRow";
import TouchableButton from "../../components/TouchableButton";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import FlexRow from "../../components/Layout/FlexRow";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import Toast from "../../components/Toast";
import styled from "styled-components/native";
import Wrapper from "../../components/Layout/Wrapper";
import { accountCredits, accountCreditsExpiration } from "../../services/accountService";
import { purchaseHistory } from "../../services/paymentService";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100%;
`;

const StyledMain = styled.View`
	margin: 8.8% 0 2% 0;
`;

const StyledTextInputSpace = styled.View`
	margin-bottom: 5%;
`;

const RedeemVoucher = (props: any) => {
	const navigationSettingsScreen = useNavigation<SettingsNavigationProp>();
	const navigation = useNavigation();
	const [modalHeader, setModalHeader] = useState("");
	const [modalBody, setModalBody] = useState("");
	const [customizeModal, setCustomizeModal] = useState(false);
	const [voucher, setVoucher] = useState("");
	const [visible, setVisible] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [type, setType] = useState("");

	const onDismissSnackBar = () => setVisible(false);

	const onEnterVoucher = (event: any) => {
		const formattedVoucher = formatVoucherCode(event.toUpperCase());
		setVoucher(formattedVoucher);
		if (formattedVoucher.length > 0) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};
	const onSubmit = async () => {
		if (voucher.length < 1) {
			setVoucher("");
			setToastMessage(globalAny.language.voucher_empty);
			setLabelText(globalAny.language.ok);
			setVisible(true);
			return;
		}
		checkingVoucher();
	};

	const checkingVoucher = async () => {
		setType("loading");
		setDisabled(true);
		setLabelText("");
		setToastMessage(globalAny.language.validate_voucher);
		setVisible(true);
		let validated: any;
		let redeemVoucher: any;
		const session = await AsyncStorage.getItem(AsyncStorageKeys.session);
		try {
			validated = await validateVoucherAPI(voucher);
			if (validated) setVisible(false);
			if (validated.response) {
				redeemVoucher = await redeemVoucherCode(voucher, session);

				if (redeemVoucher.responseCode === Code_OK) {
					const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), purchaseHistory()]);
					// @ts-ignore
					const [userCreditsData, userCreditsExpirationData, purchaseHistoryData] = promiseResult.map((i) => i.value);

					CacheStorageUtils.setCacheItem(
						AsyncStorageKeys.accountDetails,
						[userCreditsData, userCreditsExpirationData, purchaseHistoryData],
						true,
					);
					setType("success");
					setVisible(false);
					setModalHeader(globalAny.language.success_redeem_voucher);
					setModalBody(`%d ${globalAny.language.credit_added}`.replace("%d", redeemVoucher.additionalCredits));
					setCustomizeModal(true);
					return;
				}
				setType("error");
				setToastMessage(globalAny.language.error_redeem);
				setLabelText(globalAny.language.label_retry);
				setVisible(true);
			} else {
				setType("error");
				setVoucher("");
				setToastMessage(voucherValidate(validated.responseCode));
				setLabelText(globalAny.language.ok);
				setVisible(true);
			}
		} catch (e) {
			setType("error");
			setToastMessage(globalAny.language.error_redeem);
			setLabelText(globalAny.language.label_retry);
			setVisible(true);
		}
	};

	const onBackPress = () => {
		navigationSettingsScreen.navigate(routeNewSettings, {});
	};

	const onClose = () => {
		//@ts-ignore
		navigation.navigate(routeHome);
	};

	const successSent = () => {
		//@ts-ignore
		navigationSettingsScreen.navigate(routeNewSettings);
	};

	const toastType = (type: any) => {
		switch (type) {
			case "success":
				return ComponentTypeEnum.Primary;
			case "loading":
				return ComponentTypeEnum.Processing;
			case "error":
				return ComponentTypeEnum.Error;
		}
	};

	return (
		// @ts-ignore
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={capitalize(globalAny.language.redeem_voucher)}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={onBackPress}
				onPressLeftIcon={onClose}
				isSingleLogo={true}
			></TopAppbar>
			<Wrapper>
				{/* @ts-ignore */}
				<StyledMain>
					{/* @ts-ignore */}
					<FormRow>
						{/* @ts-ignore */}
						<StyledTextInputSpace>
							<TextInput
								componentTheme={ComponentThemeType.VinFast}
								label={globalAny.language.enter_voucher}
								placeholder={globalAny.language.voucher_code}
								value={voucher}
								onChangeText={(event) => onEnterVoucher(event)}
								autoFocus={true}
							/>
						</StyledTextInputSpace>
						{/* @ts-ignore */}
						<FlexRow>
							<TouchableButton
								componentTheme={ComponentThemeType.VinFast}
								type={ComponentTypeEnum.Primary}
								onPress={() => onSubmit()}
								disabled={disabled}
							>
								{globalAny.language.redeem}
							</TouchableButton>
						</FlexRow>
					</FormRow>
				</StyledMain>
			</Wrapper>

			<Toast
				type={toastType(type)}
				visible={visible}
				text={toastMessage}
				label={labelText}
				onDismissSnackBar={onDismissSnackBar}
				onPress={labelText === globalAny.language.label_retry ? checkingVoucher : onDismissSnackBar}
			/>

			{customizeModal && (
				<CustomizeDialogBox
					Header={modalHeader}
					Body={modalBody}
					ButtonTextRight={globalAny.language.confirm}
					SingleButton={true}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorRight={ComponentTypeEnum.Primary}
					onPressRight={successSent}
				></CustomizeDialogBox>
			)}
		</StyledContainer>
	);
};
export default RedeemVoucher;

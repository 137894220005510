import { useNavigation } from "@react-navigation/native";
import { routeTitleDetails, VamScreenManagerNavigationProp, routeVamScreenManager, routeVamPlayer } from "../../Routes";
import { mid_blue, white_smoke } from "../../StyleHelpers";
import { ProgressBar } from "react-native-paper";
import { getMetadata } from "../../services/metadataService";
import { AsyncStorageKeys, Pages, direction } from "../../Types";
import Image from "../Image/Image";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import playIcon from "../../assets/Icons/Play-Clips.svg";
import lockIcon from "../../assets/Icons/lock_icon.svg";
import useGenericContentStore from "../../store/genericContent.store";
import { useEffect, useMemo, useState } from "react";
import { calculateImageWidth, convertDuration } from "../../utils/Utils";
import { getProgressVideo } from "../../services/progressVideoService";
import { v4 as uuidv4 } from "uuid";
import useMqttStore from "../../store/useMqtt.store";
import { Props } from "./model";
import { StyledIconContainer, StyledProgress, StyledProgressBar, StyledTitle, StyledTitleText, styles } from "./styled";
import { getOwnedCountryId } from "../../services/movieContentService";

const CardPackshot = (props: Props) => {
	const navigation = useNavigation<any>();
	const navigationVamScreenManager = useNavigation<VamScreenManagerNavigationProp>();
	const setVamDetails = useGenericContentStore((state: any) => state.setVamDetails);
	const setMovieTitle = useGenericContentStore((state: any) => state.setMovieTitle);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const isRenderHome = useGenericContentStore((state: any) => state.isRenderHome);
	const [borderEvent, setBorderEvent] = useState(false);
	const [currentProgress, setCurrentProgress] = useState(0);
	const [ownedImage, setOwnedImage] = useState("");
	const setPlaySessionId = useMqttStore((state: any) => state.setPlaySessionId);

	/**
	 * get page to navigate
	 * @param isVamAggregate
	 * @param isClips
	 */
	const getNavigatePage = (isVamAggregate: any, isClips: any) => {
		if (isVamAggregate) return Pages.vamAggregatePage;

		return isClips ? Pages.main : Pages.shortClips;
	};

	const onPress = async () => {
		const {
			productId: parentProductId,
			vamId,
			image,
			title,
			isAssignToScreen,
			isRedeem,
			isStudioAccess,
			isClips,
			isVamAggregate,
			customListId,
		} = props;

		if (vamId && (isRedeem || isStudioAccess)) {
			const metadata: any = await getMetadata(parentProductId);
			const movieTitle = metadata?.title ? `${metadata.title} - ${title}` : title;
			const isTDP = !isClips || isStudioAccess ? direction.studio : direction.tdp;
			const handleAssignToScreen = (parentProductId: any, vamId: any, isTDP: any, customListId: any) => {
				navigationVamScreenManager.push(routeVamScreenManager, {
					location: Pages.assignScreen,
					parentProductId,
					vamId,
					redirectTo: isTDP,
					customListId: isVamAggregate ? customListId : "",
				});
			};

			setMovieTitle(movieTitle);
			setVamDetails({ parentProductId, vamId, image, title: movieTitle });
			setNavigateEvent(getNavigatePage(isVamAggregate, isClips));
			if (isAssignToScreen) {
				handleAssignToScreen(parentProductId, vamId, isTDP, customListId);
				return;
			}

			const playSessionId = uuidv4();
			setPlaySessionId(playSessionId);
			localStorage.setItem(AsyncStorageKeys.playSessionId, playSessionId);
			navigation.push(routeVamPlayer, { parentProductId, vamId, location: direction.main, redirectTo: isTDP });
			return;
		}

		if (props?.isHome) setNavigateEvent("");

		if (parentProductId && !isStudioAccess) {
			setSelectedTabs(0);
			navigation.push(routeTitleDetails, props?.isPreview ? { parentProductId, preview: true } : { parentProductId });
		}
	};

	const renderProgress = () => {
		if ((!props.isLandscape && !props.isClips) || props.isLandscape || props.isClips) {
			return (
				//@ts-ignore
				<StyledProgress backupImage={props.backupImage} isLandscape={props.isLandscape}>
					<ImgProgressBar />
				</StyledProgress>
			);
		}
	};

	const renderTitle = () => {
		if (props.isClips || props.isStudioAccess) {
			return (
				// @ts-ignore
				<StyledTitle>
					{/* @ts-ignore */}
					<StyledTitleText>{props.title}</StyledTitleText>
				</StyledTitle>
			);
		}

		return <></>;
	};

	const getContainerStyle = () => (props.isClips ? styles.containerClips : styles.container);

	const getPackshotImage = () => {
		if (ownedImage) {
			return { uri: `${ownedImage}?width=${calculateImageWidth(props?.isLandscape, false)}&disablewebp=false` };
		}

		return { uri: `${props.image ?? props.backupImage}?width=${calculateImageWidth(props?.isLandscape, false)}&disablewebp=false` };
	};

	const getPackshotStyle = () => {
		if (props.isClips) return styles.landscape;

		return props.isLandscape ? styles.container : styles.portrait;
	};

	const getPackshotBorder = () => {
		if (props.hideBorder) return { borderRadius: 16, position: "absolute" };

		return {
			borderRadius: 16,
			position: "absolute",
			transition: borderEvent ? "outline-color 0.6s ease" : "",
			outline: `2px solid ${borderEvent ? white_smoke : "rgba(255, 255, 255, 0.30)"}`,
			outlineOffset: -2,
		};
	};

	const getVamIcon = () => {
		if (props.isRedeem) return { uri: playIcon };

		if (props.isStudioAccess) return { uri: playIcon };

		return { uri: props.vamStatus === 2 ? lockIcon : playIcon };
	};

	const renderPackshot = useMemo(
		() => (
			<>
				<TouchableOpacity
					disabled={props.disabled || props.vamStatus === 2}
					onPress={() => onPress()}
					style={getContainerStyle()}
					onMouseEnter={() => setBorderEvent(true)}
					onMouseLeave={() => setBorderEvent(false)}
				>
					{renderProgress()}
					<Image source={getPackshotImage()} resizeMode="contain" style={[getPackshotStyle(), getPackshotBorder()]} />

					{props.vamId ? (
						// @ts-ignore
						<StyledIconContainer contentId={props.vamId}>
							<Image source={getVamIcon()} style={styles.imageIcon} resizeMode="contain" />
						</StyledIconContainer>
					) : (
						<></>
					)}

					{props.isProgressBar ? (
						// @ts-ignore
						<StyledProgressBar>
							<ProgressBar progress={currentProgress ?? 0} color={mid_blue} style={styles.progressBar} />
						</StyledProgressBar>
					) : (
						<></>
					)}
				</TouchableOpacity>
				{renderTitle()}
			</>
		),
		[currentProgress, props.isAssignToScreen, isRenderHome],
	);

	useEffect(() => {
		const controller = new AbortController();
		if (props.isProgressBar) {
			(async () => {
				const owned: any = await getOwnedCountryId(props.productId);
				const countryId = owned?.countryId ?? "";
				const metadata: any = await getMetadata(props.productId, controller.signal, countryId);
				const streamDetails: any = await getProgressVideo(props.productId, controller.signal);
				const currentProgress: any = Number(streamDetails?.streamProgress) / (convertDuration(metadata?.duration) * 1000) || 0;
				const image = metadata.images.find((image: any) => image.isLandscape === false && image.width === 300);
				setOwnedImage(image.url);
				setCurrentProgress(currentProgress);
			})();
		}

		return () => {
			setCurrentProgress(0);
		};
	}, []);

	return renderPackshot;
};

export default CardPackshot;

import { useState, useEffect } from "react";
import { ScrollView, useWindowDimensions, TouchableWithoutFeedback } from "react-native";
import { globalAny, pushCreateAccountStateToBrowserHistory } from "../../utils/Utils";
import {
	CodeSignInScreenNavigationProp,
	CreateAccountScreenNavigationProp,
	HomeScreenNavigationProp,
	SignInScreenNavigationProp,
	routeCodeSignIn,
	routeCreateAccount,
	routeHome,
	routePreview,
	routeSignIn,
} from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { light_black } from "../../StyleHelpers";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import ridevueLogo from "../../assets/Logo/logos.svg";
import Image from "../../components/Image/Image";
import styled from "styled-components/native";
import LandingScreenLoader from "../../components/Loaders/LandingLoader";
import useLoginStore from "../../store/useLogin.store";
import useCreateAccount from "../../store/useCreateAccount.store";
import useGenericContentStore from "../../store/genericContent.store";
import { AsyncStorageKeys, Pages } from "../../Types";
import Toast from "../../components/Toast";
import { automaticVoucherRedemption } from "../../services/accountService";
import useLegalContentStore from "../../store/useLegalContent.store";
import { AppLogger } from "../../utils/AppLogger";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100%;
`;

const StyledMain = styled.View`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 14.1% 70px 14.1%;
	border-radius: 16px;
	top: 15vh;
	overflow: hidden;
`;

const StyledButtonContainer = styled.View`
	flex-direction: column;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	gap: 10px;
	margin: 0 14.1% 0 14.1%;
`;

const StyledRowButtonContainer = styled.View`
	flex-direction: row;
`;

const StyledText = styled.View`
	align-self: stretch;
	color: #f2f2f2;
	text-align: center;
	font-size: 1.3125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.4375rem;
`;

let pressCount = 0;

const LandingScreen = (props: any) => {
	const windowDimensions = useWindowDimensions();
	const navigationSignInScreen = useNavigation<SignInScreenNavigationProp>();
	const navigationCodeSignInScreen = useNavigation<CodeSignInScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const navigation = useNavigation<any>();
	const navigationCreateAccountScreen = useNavigation<CreateAccountScreenNavigationProp>();
	const createAccountState = useCreateAccount((state: any) => state.createAccountState);
	const isLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isInitialLoading = useLoginStore((state: any) => state.isInitialLoading);
	const setIsDebugVisible = useGenericContentStore((state: any) => state.setIsDebugVisible);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const isMaintenance = useGenericContentStore((state: any) => state.isMaintenance);
	const [loading, setLoading] = useState(true);
	const [isCreateAccountEligible, setIsCreateAccountEligible] = useState(false);
	const showHWIDError = useGenericContentStore((state: any) => state.showHWIDError);
	const setShowHWIDError = useGenericContentStore((state: any) => state.setShowHWIDError);
	const fetchLegals = useLegalContentStore((state: any) => state.fetchLegals);
	const fetchAcceptOtherData = useLegalContentStore((state: any) => state.fetchAcceptOtherData);

	const SignIn = () => {
		navigationSignInScreen.navigate(routeSignIn, {});
	};

	const CodeSignIn = () => {
		navigationCodeSignInScreen.navigate(routeCodeSignIn, {});
	};

	const CreateAccount = () => {
		navigationCreateAccountScreen.navigate(routeCreateAccount, { location: Pages.legal });
		pushCreateAccountStateToBrowserHistory(createAccountState);
	};

	/**
	 * Set setTimeout to 5 seconds after timeout it will
	 * set the `setDialogVisible` to true
	 */
	const handleLogoPressIn = () => {
		pressCount = pressCount + 1;
		if (pressCount === 10) {
			setIsDebugVisible(true);
			pressCount = 0;
		}
	};

	const onPressTryItNow = () => {
		setNavigateEvent(Pages.preview);
		navigation.navigate(routePreview, { disableAutoLogin: true });
	};

	const renderCreateEligibleButton = () => {
		return (
			//@ts-ignore
			<StyledRowButtonContainer>
				<TouchableButton
					addMarginRight={true}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => CreateAccount()}
				>
					{globalAny.language.create_account}
				</TouchableButton>
				<TouchableButton
					addMarginLeft={true}
					componentTheme={ComponentThemeType.VinFast}
					type={ComponentTypeEnum.Primary}
					onPress={() => SignIn()}
				>
					{globalAny.language.sign_in}
				</TouchableButton>
			</StyledRowButtonContainer>
		);
	};

	useEffect(() => {
		const isMaster = localStorage.getItem(AsyncStorageKeys.masteruser) == "true";

		setIsCreateAccountEligible(isMaster);
		setLoading(false);
	}, [isInitialLoading]);

	useEffect(() => {
		if (isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.navigate(routeHome, {});
		}
	}, [isLoggedIn]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				await fetchLegals(AsyncStorageKeys.legals);
				await fetchAcceptOtherData(AsyncStorageKeys.acceptOtherData);
			} catch (err) {
				AppLogger.log(err);
			}
			const automaticVoucher: any = await automaticVoucherRedemption();
			if (automaticVoucher?.code) {
				setLoading(false);
				return;
			}
			setLoading(false);
		})();
	}, []);

	if (loading || isMaintenance) {
		return <LandingScreenLoader />;
	}

	return (
		// @ts-ignore
		<StyledContainer>
			<ScrollView>
				{/* @ts-ignore */}
				<StyledMain>
					{/* @ts-ignore */}
					<TouchableWithoutFeedback onPressIn={handleLogoPressIn}>
						{/* @ts-ignore */}
						<Image
							source={{ uri: ridevueLogo }}
							windowDimensions={windowDimensions}
							width={windowDimensions.width * 0.7}
							height={windowDimensions.height * 0.25}
							style={
								windowDimensions.height <= 400
									? { marginTop: 10, marginBottom: "16.5625vw" }
									: { marginTop: 41, marginBottom: "16.5625vw" }
							}
							resizeMode="contain"
						/>
					</TouchableWithoutFeedback>
				</StyledMain>
			</ScrollView>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				{/* @ts-ignore */}
				{!isCreateAccountEligible ? <StyledText>{globalAny.language.login_main_user_to_register}</StyledText> : <></>}
				{isCreateAccountEligible ? (
					//@ts-ignore
					renderCreateEligibleButton()
				) : (
					//@ts-ignore
					<StyledRowButtonContainer>
						<TouchableButton
							addMarginRight={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => SignIn()}
						>
							{globalAny.language.sign_in_with_email}
						</TouchableButton>
						<TouchableButton
							addMarginLeft={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => CodeSignIn()}
						>
							{globalAny.language.sign_in_with_code}
						</TouchableButton>
					</StyledRowButtonContainer>
				)}
				<TouchableButton componentTheme={ComponentThemeType.VinFast} type={ComponentTypeEnum.Dark} onPress={() => onPressTryItNow()}>
					{globalAny.language.try_it_now}
				</TouchableButton>
			</StyledButtonContainer>
			{showHWIDError ? (
				<Toast
					visible={showHWIDError}
					text={globalAny.language.hwid_error}
					type={ComponentTypeEnum.Secondary}
					onDismissSnackBar={() => setShowHWIDError(false)}
				/>
			) : (
				<></>
			)}
		</StyledContainer>
	);
};

export default LandingScreen;

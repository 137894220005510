import { CONTENT_BASEURL_V6 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

/**
 * Get a specific type of image
 * @param imageTypeName - 'HES', 'STT', 'TBG'
 * @param parentProductId
 * @return {Promise<unknown>}
 */
export const getImage = (imageTypeName: string, parentProductId: string) => {
	return new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "image/" + imageTypeName + "?parentProductId=" + parentProductId, { headers: getHeaders() })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err));
	});
};

import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { PaymentAuthorizationRequest } from "../models/request/PaymentAuthorizationRequest";

const usePaymentTransactionStore = create(
	devtools((set) => ({
		purchasePrice: 0,
		setPurchasePrice: (purchasePrice: any) => set({ purchasePrice: purchasePrice }),
		rentPrice: 0,
		setRentPrice: (rentPrice: any) => set({ rentPrice: rentPrice }),
		hasRent: false,
		setHasRent: (hasRent: any) => set({ hasRent: hasRent }),
		productCheckout: {},
		setProductCheckout: (productCheckout: PaymentAuthorizationRequest) => set({ productCheckout: productCheckout }),
		transactionHistory: {},
		setTransactionValue: (transactionHistory: any) => set({ transactionHistory: transactionHistory }),
		currencySymbol: "",
		setCurrencySymbol: (currencySymbol: any) => set({ currencySymbol: currencySymbol }),
		isEnableMobilePayment: false,
		setIsEnableMobilePayment: (isEnableMobilePayment: boolean) => set({ isEnableMobilePayment: isEnableMobilePayment }),
		mobilePaymentTerritories: [],
		setMobilePaymentTerritories: (mobilePaymentTerritories: any) => set({ mobilePaymentTerritories: mobilePaymentTerritories }),
	})),
);

export default usePaymentTransactionStore;

import { STREAM_TYPES, SUBTITLE_TYPES, TRANSACTION_TYPES } from "../networking/networking";
import { AsyncStorageKeys } from "../Types";
import { CONTENT_BASEURL_V7, CONTENT_BASEURL_V6 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

const VIDEO_QUALITY = 1080;
const VIDEO_PROFILE = "h264";
const IS_3D_ENABLED = false;

/**
 *
 * @param parentProductId - The parent product ID
 * @param transactionType - EST, SVOD, TVOD, FVOD
 * @returns - Video details
 */
export const getVideo = (parentProductId: string, transactionType: number = TRANSACTION_TYPES.EST, quality: number = 1080) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V7 +
				"video/" +
				parentProductId +
				"?subType=vtt&streamtype=2&profile=h264&transactiontype=" +
				transactionType +
				"&singleAdaptationRequired=false&svodCountryId=0&subtype=vtt" +
				`&quality=${quality}` +
				"&SupportedCodecs=1,2&isDownload=false",
			{ headers: getHeaders(true) },
		)
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err));
	});

	return promise;
};

/**
 * Retrieves the video content for the given Parent Product
 * content/v6/Video/{parentProductId}
 * @param parentProductId
 * @param transactionType
 */
export const getStreamVideo = (parentProductId: string, transactionType: number, quality: number = 1080) => {
	// todo: how can I make sure it doesn't try to play when it will fail due to lack of internet connnection?
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V6 +
				"video/" +
				parentProductId +
				"?quality=" +
				quality +
				"&subType=" +
				SUBTITLE_TYPES.VTT +
				"&is3D=" +
				IS_3D_ENABLED +
				"&streamType=" +
				STREAM_TYPES.DASH +
				"&profile=" +
				VIDEO_PROFILE +
				"&transactionType=" +
				transactionType +
				"&SupportedCodecs=1,2",
			{ headers: getHeaders(true) },
		)
			.then((resJson) => resolve(resJson))
			.catch((err) => {
				reject(err);
			});
	});
	return promise;
};

/**
 *
 * @param parentProductId - number: parentProductId
 * @returns - Audio details
 */
export const getAvailableAudioLanguages = (parentProductId: number, quality: number = 1080, transactionType?: any, countryId?: any) => {
	const hwid = localStorage.getItem(AsyncStorageKeys.hwid);
	const deviceModel = hwid ? "mercedez-benz" : "Web";
	let updateTransactionType = transactionType !== 0 ? transactionType : TRANSACTION_TYPES.EST;
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V7 +
				"audio/" +
				parentProductId +
				"?quality=" +
				quality +
				"&subType=" +
				SUBTITLE_TYPES.VTT +
				"&deviceModel=" +
				deviceModel +
				"&is3D=" +
				IS_3D_ENABLED +
				"&streamType=" +
				STREAM_TYPES.DASH +
				"&transactionType=" +
				updateTransactionType +
				"&countryId=" +
				countryId +
				"&SupportedCodecs=1,2&svodCountryId=0",
			{ headers: getHeaders() },
		)
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

/**
 *
 * @param parentProductId - number: parentProductId
 * @param transactionType - number: transactionType
 * @returns - expiry details
 */
export const getPlaybackWindow = (parentProductId: number, transactionType: number, quality: number = 1080) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + `video/playbackwindow/${parentProductId}/${quality}/${transactionType}`, {
			headers: getHeaders(true),
		})
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

/**
 * Use for checking of streaming limits
 * @param transactionType - List of Transaction type
 * @returns
 */
export const getConcurrency = (transactionType: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + `concurrency/${transactionType}`, { method: "GET", headers: getHeaders(true) })
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

/**
 * Add device to concurrency
 * @param transactionType - List of Transaction type
 * @returns
 */
export const postConcurrency = (transactionType: any) => {
	let body = [transactionType];
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "concurrency", { method: "POST", headers: getHeaders(true), body: JSON.stringify(body) })
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(err));
	});
	return promise;
};

/**
 * Use to get the DubCards data
 * @param parentProductId
 * @returns
 */
export const getDubCards = (parentProductId: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + `dubcards/${parentProductId}`, { method: "GET", headers: getHeaders(true) })
			.then((resJson) => resolve(resJson))
			.catch((err) => reject(new Error(err)));
	});
	return promise;
};

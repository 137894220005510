import styled from "styled-components/native";

export const StyledContainer = styled.View`
	height: 100%;
`;

export const StyledGridPackshots = styled.View<{ isShortClips: boolean }>`
	margin-bottom: 3%;
	padding-top: 153px;
	padding-bottom: ${(props) => (props.isShortClips ? "6rem" : 0)};
`;

import { AsyncStorageKeys } from "../Types";
import { getTransactionFilters } from "../utils/Utils";
import { CONTENT_BASEURL_V7, CONTENT_BASEURL_V9, CONTENT_BASEURL_V10 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const getPlaylist = (playlistUId: string, isCustomList: boolean, signal?: any, isPreview?: boolean) => {
	const baseUrl = isPreview ? CONTENT_BASEURL_V9 : CONTENT_BASEURL_V10;
	const svodCountryId = localStorage.getItem(AsyncStorageKeys.svodCountryId) ?? 0;
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			(isCustomList ? CONTENT_BASEURL_V7 + "customlist/" : baseUrl + "playlist/") +
				playlistUId +
				`/content?transactionalFilters=${getTransactionFilters()}&svodCountryId=${svodCountryId}&streamType=2&supportedCodecs=1%2C2`,
			{
				headers: getHeaders(!isPreview),
				signal,
			},
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});

	return promise;
};

export const getPlaylistWithPage = (playlistUId: string, isCustomList: boolean, pageSize?: number, pageNumber?: number, isPreview?: boolean) => {
	const baseUrl = isPreview ? CONTENT_BASEURL_V9 : CONTENT_BASEURL_V10;
	const svodCountryId = localStorage.getItem(AsyncStorageKeys.svodCountryId) ?? 0;
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			(isCustomList ? CONTENT_BASEURL_V7 + "customlist/" : baseUrl + "playlist/") +
				playlistUId +
				"/content" +
				`?PageSize=${pageSize}&PageNumber=${pageNumber}&transactionalFilters=${getTransactionFilters()}&svodCountryId=${svodCountryId}&streamType=2`,
			{
				headers: getHeaders(!isPreview),
			},
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});

	return promise;
};

export const getCustomerPlaylist = (customListId: string, transactionalFilters: any, pageSize?: number, pageNumber?: number, signal?: any) => {
	const svodCountryId = localStorage.getItem(AsyncStorageKeys.svodCountryId) ?? 0;
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V10 +
				`playlist/${customListId}/content?PageSize=${pageSize}&PageNumber=${pageNumber}&transactionalFilters=${transactionalFilters}&svodCountryId=${svodCountryId}&streamType=2`,
			{
				headers: getHeaders(true),
				signal,
			},
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});

	return promise;
};
/**
 * Service to fetch vam playlist
 * @param customListId {string}
 * @returns Return the list of content in the given custom list
 */
export const getVamPlaylist = (customListId: string, pageSize?: number, pageNumber?: number, signal?: any) => {
	const svodCountryId = localStorage.getItem(AsyncStorageKeys.svodCountryId) ?? 0;
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(
			CONTENT_BASEURL_V7 +
				"customlist/" +
				customListId +
				`/content?PageSize=${pageSize}&PageNumber=${pageNumber}&imax=false&kids=false&svodCountryId=${svodCountryId}&streamType=2&SupportedCodecs=1%2C2`,
			{
				headers: getHeaders(false),
				signal,
			},
		)
			.then((resJson) => resolve({ products: resJson.products ?? resJson.vam }))
			.catch((err) => reject(err));
	});
	return promise;
};

import styled, { css } from "styled-components/native";
import { spacing_m, white_smoke } from "../../StyleHelpers";
import CardPackshot from "../CardPackshot";
import ImgProgressBar from "../Loaders/ImgProgressBar";
import { Dimensions } from "react-native";
import { useEffect, useState } from "react";

interface Props {
	title?: any;
	hideTitle?: boolean;
	data?: any;
	hideBorder?: boolean;
	isClips?: boolean;
	isAssignToScreen?: boolean;
	parentProductId?: any;
	isRedeem?: boolean;
	isAggregate?: boolean;
	isStudioAccess?: boolean;
	isBorderClips?: boolean;
	isExtras?: boolean;
	disabled?: boolean;
	isPreview?: boolean;
	isHome?: boolean;
	customListId?: any;
}

interface GridProps {
	gridSize?: number;
}

const StyledContainer = styled.View`
	width: 100%;
`;

const StyledTitle = styled.Text`
	font-size: 25px;
	color: ${white_smoke};
	width: 90vw;
	margin: 0 5vw ${spacing_m} 5vw;
`;

const StyledProgressTitle = styled.Text`
	width: 368px;
	height: ${spacing_m};
	border-radius: 16px;
	overflow: hidden;
	margin: 0 5vw ${spacing_m} 5vw;
`;

const StyledGridCenter = styled.View<Props>`
	${(props) =>
		props.isExtras &&
		css`
			margin-bottom: 1.2%;
		`}
`;

const StyledGrid = styled.View<GridProps>`
	display: grid;

	${(props) =>
		props?.gridSize &&
		css`
			grid-template-columns: repeat(${props.gridSize}, 1fr);
			column-gap: ${spacing_m};
			row-gap: ${spacing_m};
		`}

	width: 90vw;
	margin: 0 5vw;
`;

const StyledImage = styled.View<any>`
	${(props) =>
		props?.isClips &&
		css`
			margin: 0 auto;
		`}
`;

const CardGridPlaylist = (props: Props) => {
	const [windowWidth, setWindowWidth] = useState<any>();
	const [gridSize, setGridSize] = useState<any>();
	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
			setGridSize(calculateGridItemsByWindowWidth(Dimensions.get("window").width));
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth]);

	/**
	 * Function to calculate the number of items on grid
	 * based on window width
	 * @param width - window width size
	 * @returns {number} - number of items in grid
	 */
	const calculateGridItemsByWindowWidth = (width: any) => {
		if (width >= 1660) return props.isClips ? 4 : 6;

		if (width >= 620 && width < 1660) return 4;

		if (width < 620) return props.isClips ? 3 : 4;
	};

	return (
		//@ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			{props.title && !props.hideTitle && !props.isAggregate ? <StyledTitle>{props.title}</StyledTitle> : <></>}
			{!props.title && !props.hideTitle && !props.isAggregate ? (
				// @ts-ignore
				<StyledProgressTitle>
					<ImgProgressBar />
				</StyledProgressTitle>
			) : (
				<></>
			)}
			{props.data?.length !== 0 ? (
				//@ts-ignore
				<StyledGridCenter isExtras={props.isExtras}>
					{/* @ts-ignore */}
					<StyledGrid gridSize={gridSize}>
						{props.data?.map((item: any, index: number) => {
							return (
								// @ts-ignore
								<StyledImage isClips={props.isClips} key={`grid-products-${item.parentProductId ?? item.id}-${index}`}>
									{!props.isClips ? (
										<CardPackshot
											key={item.parentProductId}
											productId={item.parentProductId}
											image={item.packshots}
											hideBorder={props.hideBorder}
											isProgressBar={item.isProgressBar}
											isRedeem={props.isRedeem}
											disabled={props.disabled}
											isPreview={props?.isPreview}
											isHome={props?.isHome}
										/>
									) : (
										<></>
									)}
									{props.isClips ? (
										<CardPackshot
											key={item.id}
											productId={props.isAggregate ? item.productId : props.parentProductId}
											vamId={item.uniqueContentId}
											image={item.imageUrl}
											title={item.title}
											isAssignToScreen={props.isAssignToScreen}
											isRedeem={item?.vamStatus === 1 || item?.vamStatus === 3 || props.isRedeem}
											isLandscape={props.isClips}
											isClips={props.isClips}
											isStudioAccess={props.isStudioAccess}
											isVamAggregate={props.isAggregate}
											hideBorder={props.hideBorder}
											vamStatus={item.vamStatus}
											disabled={props.disabled}
											isPreview={props?.isPreview}
											isHome={props?.isHome}
											customListId={props.customListId}
										/>
									) : (
										<></>
									)}
								</StyledImage>
							);
						})}
					</StyledGrid>
				</StyledGridCenter>
			) : (
				<></>
			)}
		</StyledContainer>
	);
};

export default CardGridPlaylist;

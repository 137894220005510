import {
	routeHome,
	routeLogin,
	routeMyLibrary,
	routePlayer,
	routeScreenManager,
	routeTitleDetails,
	routeProfileNameSelect,
	routeCreateAccount,
	routeVamPlayer,
	routeTrailer,
	routeVinfastComponents,
	routeSignIn,
	routeCodeSignIn,
	routeRecoverPassword,
	routeNewSettings,
	routeRedeemVoucher,
	routeDataManagementSettings,
	routeSearchScreen,
	routeNewCuration,
	routeVamScreenManager,
	routeAggregatePage,
	routeVamAggregatePage,
	routePayment,
	routePage,
	routePreview,
	routeDataManagementSettingsDetails,
	routeCreateAccountDetails,
	routePrivacyPolicy,
	routeLegalsScreen,
	routeMobilePayment,
} from "../Routes";
import PlayerScreen from "../screens/PlayerScreen";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";
import { useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Pages, direction } from "../Types";
import LoginScreen from "../screens/LandingScreen";
import CreateAccountScreen from "../screens/CreateAccountScreen/CreateAccountScreen";
import VinfastComponentsScreen from "../screens/VinfastComponentsScreen/VinfastComponentsScreen";
import navLinking from "./navLinking";
import SignInScreen from "../screens/SignInScreen";
import RecoverPasswordScreen from "../screens/RecoverPasswordScreen";
import RedeemVoucherScreen from "../screens/RedeemVoucherScreen";
import NewSettingsScreen from "../screens/SettingsScreen";
import NewScreenManager from "../screens/ScreenManager";
import DataManagementSettings from "../screens/SettingsScreen/SettingsDataManagement";
import NewTitleDetails from "../screens/TitleDetails";
import PageScreen from "../screens/Page";
import NewHome from "../screens/Home";
import NewLibrary from "../screens/MyLibrary";
import DeviceName from "../screens/DeviceName";
import NewSearchScreen from "../screens/SearchScreen";
import StudioAccess from "../screens/StudioAccess";
import AggregatePage from "../screens/AggregatePages";
import PaymentScreen from "../screens/PaymentScreen";
import useMqttStore from "../store/useMqtt.store";
import CodeSignInScreen from "../screens/CodeSignInScreen";
import useGenericContentStore from "../store/genericContent.store";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import LegalsScreen from "../screens/LegalsScreen";
import MobilePaymentScreen from "../screens/MobilePaymentScreen";
import { isParamsAvailable } from "../utils/Utils";

const Stack = createStackNavigator();

const Navigation = () => {
	const navigationRef = useNavigationContainerRef(); //createNavigationContainerRef()
	const mqttPlayContent = useMqttStore((state: any) => state.mqttPlayContent);
	const navigateEvent = useGenericContentStore((state: any) => state.navigateEvent);
	// **
	//  * Navigates to QuickNav if Driving state is active
	//  */

	const navigateToPlayer = (mqttPlayContent: any, navigationRef: any) => {
		if (mqttPlayContent.vamId) {
			//@ts-ignore
			navigationRef.navigate(routeVamPlayer, {
				parentProductId: mqttPlayContent.contentId,
				vamId: mqttPlayContent.vamId,
				location: Pages.main,
				redirectTo: navigateEvent === Pages.shortClips ? direction.studio : direction.tdp,
			});
			return;
		}

		//@ts-ignore
		navigationRef.navigate(routePlayer, {
			parentProductId: mqttPlayContent.contentId,
			resumeProgress: mqttPlayContent.position ?? 0,
		});
	};

	useEffect(() => {
		if (mqttPlayContent && navigationRef) {
			const routeName = navigationRef.getCurrentRoute()?.name;
			if (routeName === routePlayer || routeName === routeVamPlayer) {
				navigationRef.goBack();
			}

			navigateToPlayer(mqttPlayContent, navigationRef);
		}
	}, [mqttPlayContent]);

	return (
		<NavigationContainer ref={navigationRef} linking={navLinking}>
			<Stack.Navigator
				initialRouteName={routePreview}
				screenOptions={{
					headerShown: false,
					title: "RIDEVU",
				}}
				screenListeners={{
					state: (e: any) => {
						if (!isParamsAvailable()) {
							window.location.href = "https://www.ridevu.com";
						}
					},
				}}
			>
				<Stack.Screen name={routePreview} component={PageScreen} />
				<Stack.Screen name={routeLogin} component={LoginScreen} />
				<Stack.Screen name={routeHome} component={NewHome} />
				<Stack.Screen name={routePlayer} component={PlayerScreen} />
				<Stack.Screen name={routeVamPlayer} component={PlayerScreen} />
				<Stack.Screen name={routeScreenManager} component={NewScreenManager} />
				<Stack.Screen name={routeVamScreenManager} component={NewScreenManager} />
				<Stack.Screen name={routeMyLibrary} component={NewLibrary} />
				<Stack.Screen name={routeNewSettings} component={NewSettingsScreen} />
				<Stack.Screen name={routeTrailer} component={PlayerScreen} />
				<Stack.Screen name={routeTitleDetails} component={NewTitleDetails} />
				<Stack.Screen name={routeProfileNameSelect} component={DeviceName} />
				<Stack.Screen name={routeCreateAccount} component={CreateAccountScreen} />
				<Stack.Screen name={routeCreateAccountDetails} component={CreateAccountScreen} />
				<Stack.Screen name={routeSignIn} component={SignInScreen} />
				<Stack.Screen name={routeCodeSignIn} component={CodeSignInScreen} />
				<Stack.Screen name={routeRecoverPassword} component={RecoverPasswordScreen} />
				<Stack.Screen name={routeRedeemVoucher} component={RedeemVoucherScreen} />
				<Stack.Screen name={routeDataManagementSettings} component={DataManagementSettings} />
				<Stack.Screen name={routeDataManagementSettingsDetails} component={DataManagementSettings} />
				<Stack.Screen name={routeVinfastComponents} component={VinfastComponentsScreen} />
				<Stack.Screen name={routeSearchScreen} component={NewSearchScreen} />
				<Stack.Screen name={routeNewCuration} component={StudioAccess} />
				<Stack.Screen name={routeAggregatePage} component={AggregatePage} />
				<Stack.Screen name={routeVamAggregatePage} component={AggregatePage} />
				<Stack.Screen name={routePayment} component={PaymentScreen} />
				<Stack.Screen name={routePage} component={PageScreen} />
				<Stack.Screen name={routePrivacyPolicy} component={PrivacyPolicyScreen} />
				<Stack.Screen name={routeLegalsScreen} component={LegalsScreen} />
				<Stack.Screen name={routeMobilePayment} component={MobilePaymentScreen} />
			</Stack.Navigator>
		</NavigationContainer>
	);
};

export default Navigation;

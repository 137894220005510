import { spacing_s, spacing_xs, white_smoke } from "../../StyleHelpers";
import styled from "styled-components/native";
import { formatDate, formatHoursAndMinutesFromToday, globalAny } from "../../utils/Utils";
import { View, Text } from "react-native";

interface Props {
	rating?: string;
	genres?: any;
	isRent?: boolean;
	expiryDate?: any;
}

const StyledGenres = styled.View`
	width: 100%;
	height: 46px;
	color: white;
	flex-direction: row;
	align-items: center;
	margin: 20px 0 ${spacing_s} 0;
`;

const StyledGenresContainer = styled.View`
	background: rgba(255, 255, 255, 0.1);
	padding: ${spacing_xs};
	border-radius: 4px;
	margin: 0 ${spacing_xs} 0 ${spacing_xs};
`;

const StyledText = styled.Text`
	font-size: 18px;
	color: ${white_smoke};
`;

const StyledRatingContainer = styled.Text`
	background: rgba(0, 0, 0, 0.5);
	padding: ${spacing_xs};
	border-radius: 4px;
	margin: 0 ${spacing_xs} 0 0;
	border-width: 2px;
	border-width: 1px;
	border-style: solid;
	border-color: white;
	align-items: center;
	text-align: center;
	width: 72px;
	color: white;
`;

const StyledProfileContainer = styled.Text`
	background: rgba(0, 0, 0, 0.5);
	padding: ${spacing_xs};
	border-radius: 4px;
	width: 60px;
	align-items: center;
	text-align: center;
	margin: 0 ${spacing_xs} 0 ${spacing_xs};
	color: white;
`;

const StyledExpiryContainer = styled.View`
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	gap: 8px;
	align-self: stretch;
	flex-wrap: wrap;
	margin-bottom: 16px;
`;

const StyledExpiryText = styled.Text`
	background: rgba(72, 28, 40, 0.5);
	border-radius: 4px;
	color: white;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	display: flex;
	padding: 6px 8px;
	align-items: flex-start;
	gap: 10px;
`;

const GenresComponent = (props: Props) => {
	const wording = {
		rentExpires: globalAny.language.rent_expires,
		rentViewingExpires: globalAny.language.rental_time_remaining,
		viewingExpires: globalAny.language.viewing_expires,
	};

	const onViewingExpires = (expiryDate: any) => {
		let date = new Date(new Date().toUTCString());
		const convertToDate = new Date(expiryDate.utcExpiryDate);
		let viewingExpiry = false;
		if (date.getMonth() === convertToDate.getMonth()) {
			viewingExpiry = 0 === date.getDate() - convertToDate.getDate();
			return { viewingExpiry: viewingExpiry, newDateFormat: formatDate(convertToDate) };
		}
		return { viewingExpiry: viewingExpiry, newDateFormat: formatDate(convertToDate) };
	};

	const formatExpiry = (expiryDate: any) => {
		let expiryTime;
		let hours;
		let minutes;
		const expiry = onViewingExpires(expiryDate);

		if (expiryDate?.ownershipExpiryHoursRemaining || expiryDate?.ownershipExpiryMinutesRemaining) {
			hours = Number(expiryDate?.ownershipExpiryHoursRemaining).toString();
			minutes = Number(expiryDate?.ownershipExpiryMinutesRemaining).toString();

			return wording.rentViewingExpires.replace("%1s", hours).replace("%2s", minutes);
		} else if (!expiryDate?.ownershipExpiryHoursRemaining && !expiryDate?.ownershipExpiryMinutesRemaining && expiry.viewingExpiry) {
			expiryTime = formatHoursAndMinutesFromToday(expiryDate.utcExpiryDate);

			return wording.rentViewingExpires.replace("%1s", expiryTime.hours).replace("%2s", expiryTime.minutes);
		} else {
			return `${wording.rentExpires} ${expiry.newDateFormat === "NaN/NaN/NaN" ? "" : expiry.newDateFormat}`;
		}
	};

	return (
		<>
			{/* @ts-ignore */}
			<StyledGenres>
				{/* @ts-ignore */}
				{props?.rating && <StyledRatingContainer>{props?.rating}</StyledRatingContainer>}
				{/* @ts-ignore */}
				{props?.rating && <StyledProfileContainer>HD</StyledProfileContainer>}
				{props?.genres?.map((item: any) => {
					return (
						//@ts-ignores
						<StyledGenresContainer key={`genre-component-${item}`}>
							<View>
								<Text>
									{/* @ts-ignores */}
									<StyledText>{item}</StyledText>
								</Text>
							</View>
						</StyledGenresContainer>
					);
				})}
			</StyledGenres>

			{props?.isRent ? (
				// @ts-ignore
				<StyledExpiryContainer>
					{/* @ts-ignore */}
					<StyledExpiryText>{formatExpiry(props?.expiryDate)}</StyledExpiryText>
				</StyledExpiryContainer>
			) : (
				<></>
			)}
		</>
	);
};

export default GenresComponent;

import styled, { css } from "styled-components/native";
import { light_black, spacing_l, spacing_xs } from "../../StyleHelpers";
import { Props } from "./model";

export const StyledContainer = styled.View<Props>`
	flex-direction: row;
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-repeat: no-repeat;
			background-size: cover;
		`}
	${(props) =>
		!props.banner &&
		css`
			background-color: ${light_black};
		`}
	height: 288px;
`;

export const StyledText = styled.View`
	padding: 55px 0 0 ${spacing_l};
	background-color: ${light_black};
	width: 40%;
	height: 100%;
`;

export const StyledSecondaryText = styled.View`
	padding: 0 0 0 ${spacing_l};
	background-color: ${light_black};
	width: 40%;
`;

export const StyledImage = styled.View`
	width: 60%;
	height: 100%;
	background-image: linear-gradient(to left, transparent, #141414);
`;

export const StyledGradient = styled.View`
	width: 100%;
	height: 90px;
	background-image: linear-gradient(to bottom, transparent, #141414);
	position: absolute;
	bottom: 0;
`;

export const StyledGridPackshots = styled.View`
	width: 100%;
	padding-top: ${spacing_xs};
	margin-left: -115px;
`;

import { useMemo } from "react";
import styled from "styled-components/native";
import { silver } from "../../StyleHelpers";

const ImgProgressBar = () => {
	const StyledLoader = styled.View`
		height: 100%;
		width: 100%;
		opacity: 0.2;
		background-color: ${silver};
	`;

	const renderLoader = useMemo(() => <StyledLoader />, []);

	return renderLoader;
};

export default ImgProgressBar;

import { STREAM_TYPES, SUBTITLE_TYPES, TRANSACTION_TYPES } from "../networking/networking";
import { CONTENT_BASEURL_V7, CONTENT_BASEURL_V6 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

const VIDEO_QUALITY = 1080;
const VIDEO_PROFILE = "h264";
const IS_3D_ENABLED = false;

/**
 *
 * @param parentProductId - The parent product ID
 * @returns - Trailer details
 */
export const getTrailer = (parentProductId: string) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V6 + "trailer/" + parentProductId + "?supportedCodecs=1,2", { headers: getHeaders() })
			.then((resJson) => {
				resolve(resJson);
			})
			.catch((err) => reject(err));
	});

	return promise;
};

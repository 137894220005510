import { create } from "zustand";
import { devtools } from "zustand/middleware";
const useSessionStore = create(
	devtools((set) => ({
		isLogout: false,
		setIsLogout: (isLogout: boolean) => set({ isLogout: isLogout }),
		isTimerRunning: false,
		setIsTimerRunning: (isTimerRunning: boolean) => set({ isTimerRunning: isTimerRunning }),
		isSigninLoading: false,
		setIsSigninLoading: (isSigninLoading: boolean) => set({ isSigninLoading: isSigninLoading }),
		isLoginSuccess: false,
		setIsLoginSuccess: (isLoginSuccess: boolean) => set({ isLoginSuccess: isLoginSuccess }),
	})),
);
export default useSessionStore;

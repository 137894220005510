import { black } from "../../StyleHelpers";
import { View } from "react-native";
import CardGridPlaylist from "../../components/CardGridPlaylist";
import AggregatePageLoader from "../../components/Loaders/AggregatePageLoader";
import { StyledContainerExtras } from "./styled";

const Extras = (props: any) => {
	return (
		<View style={{ flex: 1, backgroundColor: black }}>
			{/* @ts-ignore */}
			<StyledContainerExtras>
				<CardGridPlaylist
					data={props?.data}
					hideTitle={true}
					isClips={true}
					isAssignToScreen={props.id === 1}
					parentProductId={props.parentProductId}
					isRedeem={props.redeem}
					isBorderClips={true}
					isExtras={true}
				/>

				{props.extraLoader && <AggregatePageLoader isVam={true} slice={props.gridSize} />}
			</StyledContainerExtras>
		</View>
	);
};

export default Extras;

import { injectable } from "inversify";
import { MqttCommand } from "../../models/mqtt-command";
import IMqttRequest from "../../models/IMqttRequest";
import IMqttDeviceBroadcast from "../../models/IMqttDeviceBroadcast";
import { MqttReceiverEventListenersKey } from "./MqttReceiverService";
import "reflect-metadata";
import IMqttClient from "../../models/IMqttClient";
import { MqttDeviceManagerKey } from "./MqttDeviceManager";
import MqttConnectionStatus from "../../models/MqttConnectionStatus";
import { AppLogger } from "../../utils/AppLogger";

@injectable()
export class MqttBaseService {
	public _mqttDeviceBroadcast: IMqttDeviceBroadcast | null;
	protected _mqttService: IMqttClient | undefined | null;
	protected _eventListeners: any; //use events to emit/subscribe to receiver events
	public processMessageInternal!: (message: IMqttRequest) => void; //use events to emit/subscribe to receiver events
	//use events to emit/subscribe to receiver events

	constructor(mqttDeviceBroadcast: IMqttDeviceBroadcast | null) {
		this._mqttDeviceBroadcast = mqttDeviceBroadcast;
	}

	protected generateCommand(command: MqttCommand): string {
		const req = { ...this._mqttDeviceBroadcast, command: command } as IMqttRequest;

		AppLogger.log("[SEND]", req.command, req);
		return JSON.stringify(req);
	}

	protected generateCommandWithMessage(message: any, command: MqttCommand): string {
		const req = { ...message, command: command } as IMqttRequest;

		AppLogger.log("[SEND]", req.command, req);
		return JSON.stringify(req);
	}

	protected generateCommandWithBody(command: MqttCommand, body: any): string {
		const sendOnly = command === MqttCommand.PlayMovie || command === MqttCommand.PlayVam || command == MqttCommand.MediaPosition;
		const req = { ...this._mqttDeviceBroadcast, command: command, body: body, sendOnly } as IMqttRequest;

		AppLogger.log("[SEND]", req.command, req);
		return JSON.stringify(req);
	}

	public onMessage(message: string) {
		const deserializeMessage: IMqttRequest = JSON.parse(message) as IMqttRequest;
		this.processMessageInternal(deserializeMessage);
	}

	/**
	 * TODO: Switch to mitt to automate event listener implementation than doing it manually: https://github.com/developit/mitt
	 * @param key
	 * @param decodedMessage
	 */
	public emitEventListenersSubscribed(
		key: MqttCommand | MqttReceiverEventListenersKey | MqttDeviceManagerKey,
		decodedMessage: IMqttRequest | IMqttDeviceBroadcast | IMqttDeviceBroadcast[],
	) {
		// invoke all event listeners to trigger them
		if (this._eventListeners[key]) {
			this._eventListeners[key].forEach((x: any) => {
				x.fn(decodedMessage);
			});
		}
	}

	/**
	 * TODO: Switch to mitt to automate event listener implementation than doing it manually: https://github.com/developit/mitt
	 * @param key
	 * @param decodedMessage
	 */
	public addEventListener(key: MqttCommand | MqttDeviceManagerKey, fn: any): number {
		const id = Math.floor(Math.random() * 999999);
		if (!this._eventListeners[key]) {
			this._eventListeners[key] = [{ id, fn }];
		} else {
			this._eventListeners[key].push({ id, fn });
		}
		return id;
	}

	public deleteEventListener(key: MqttCommand, id: number) {
		if (!this._eventListeners[key]) {
			return;
		}

		const index = this._eventListeners[key].findIndex((x: { id: number; fn: any }) => x.id === id);
		if (index > -1) {
			this._eventListeners[key].splice(index, 1);
		}
	}

	public deleteEventListenerByCommand(key: MqttCommand | MqttDeviceManagerKey) {
		if (!this._eventListeners[key]) {
			return;
		}

		while (this._eventListeners[key].length > 0) {
			//remove all instances of the event listeners being binded. We can use this when clearing up event listeners to refresh dependencies on component event handlers
			this._eventListeners[key].splice(0, 1);
		}
	}

	// TODO: Finish implementation
	public deleteAllEventListeners() {
		Object.keys(this._eventListeners).forEach((i) => {
			while (this._eventListeners[i].length > 0) {
				//remove all instances of the event listeners being binded. We can use this when clearing up event listeners to refresh dependencies on component event handlers
				this._eventListeners[i].splice(0, 1);
			}
			delete this._eventListeners[i];
		});
	}

	public isConnected(): boolean {
		return this._mqttService?.ConnectionStatus === MqttConnectionStatus.Connected;
	}
}

import mqtt from "mqtt";
import mitt, { Emitter } from "mitt";
import IMqttClient from "../../../models/IMqttClient";
import { BaseMqttDriver } from "./BaseMqttDriver";
import { injectable } from "inversify";
import "reflect-metadata";
import { getPresignedUrl } from "../../../networking/networking";
import { AppLogger } from "../../../utils/AppLogger";

type Events = {
	reconnectionFailed: void;
};

const EventListener: Emitter<Events> = mitt();

@injectable()
class AwsIoTMqttDriverPS extends BaseMqttDriver implements IMqttClient {
	private readonly reconnectionCount: number = 0;
	constructor() {
		super();
	}

	private reconnectPresignedUrl: any;
	private readonly refreshReconnectPresigned = async () => {
		this.reconnectPresignedUrl = await getPresignedUrl();
	};

	// Connect to MQTT broker
	public connect: any = async (presignedUrl: any, onConnected: (props: any) => void) => {
		await super.connect(presignedUrl, () => {});

		try {
			await this.refreshReconnectPresigned();
			const MqttInstance = mqtt.connect(presignedUrl.presignedUrl, {
				host: presignedUrl.host,
				protocol: presignedUrl.protocol,
				path: presignedUrl.path,
				reconnectPeriod: 3000,
				transformWsUrl: (url, options, client) => {
					return this.reconnectPresignedUrl.presignedUrl;
				},
			});

			this.ClientTopic = presignedUrl.topic;
			await this.initializeMqtt(MqttInstance);

			// May need to change its underlying implementation
			this.onConnect(() => {
				AppLogger.log("Connected to AWS Pre-signed from PS DRM!");
				if (onConnected) {
					onConnected(presignedUrl.topic);
				}
			});
			this.onConnectionLost(async () => {
				await this.refreshReconnectPresigned();
			});
			this.onDisconnect(async () => {});

			this.onReconnect(async () => {});
			this.onClose(async () => {
				await this.refreshReconnectPresigned();
			});
		} catch (e) {
			AppLogger.log("Failure to connect to AWS Pre-signed from PS DRM");
			AppLogger.error(e);
		}
	};

	public onReconnectionFailedListener() {}
}

export default AwsIoTMqttDriverPS;

import styled from "styled-components/native";
import TextInput from "../../components/TextInput";
import TextInputWithDropDown from "../../components/TextInputWithDropDown";
import ComponentThemeType from "../../models/ComponentTheme";
import { useState } from "react";
import { View } from "react-native";
import { white_smoke } from "../../StyleHelpers";
import { globalAny, isInCarScreen } from "../../utils/Utils";

const StyledContainer = styled.View`
	margin-top: 40px;
`;

const StyledNameContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
`;

const StyleHalfInput = styled.View`
	width: 49%;
`;

const CardDetails = (props: any) => {
	const [cardExpiry, setCardExpiry] = useState(props.cardExpiry);
	const [firstName, setFirstName] = useState(props.firstName);
	const [lastName, setLastName] = useState(props.lastName);

	const stylesInput = {
		height: 80,
		borderRadius: 16,
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: "rgb(147,143,153)",
		paddingLeft: 20,
		backgroundColor: "transparent",
	};

	const stylesLabel = {
		backgroundColor: "#141414",
		paddingLeft: 8,
		paddingRight: 8,
		color: white_smoke,
	};

	const stylesCardLabel = {
		backgroundColor: "#141414",
		opacity: 1,
		paddingLeft: 8,
		paddingRight: 15,
		color: white_smoke,
	};

	const formatExpiry = (text: any) => {
		const formattedText = text.replace(/[^0-9, /]/g, "");
		if (text.length > 2 && text.length < 5 && text.indexOf("/") === -1) {
			props?.setCardExpiry(`${formattedText.slice(0, 2)}/${formattedText.slice(2, formattedText.length)}`);
			setCardExpiry(`${formattedText.slice(0, 2)}/${formattedText.slice(2, formattedText.length)}`);
		} else {
			if (text.length > 5) return;
			props?.setCardExpiry(formattedText);
			setCardExpiry(formattedText);
		}
	};

	return (
		//@ts-ignore
		<StyledContainer>
			{/**@ts-ignore */}
			<StyledNameContainer>
				{/**@ts-ignore */}
				<StyleHalfInput>
					<TextInput
						nativeID={"cardholderFirstName"}
						componentTheme={ComponentThemeType.VinFast}
						label={globalAny.language.first_name}
						placeholder={globalAny.language.first_name}
						autoFocus={true}
						onChangeText={(text) => {
							if (isInCarScreen()) return;
							props?.setFirstName(text);
							setFirstName(text);
							props.debouncedOnChange(text);
						}}
						onChange={(e) => {
							if (!isInCarScreen()) return;
							props?.setFirstName(e.nativeEvent.text);
							setFirstName(e.nativeEvent.text);
							props.debouncedOnChange(e.nativeEvent.text);
						}}
						onEndEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setFirstName(e.nativeEvent.text);
							setFirstName(e.nativeEvent.text);
						}}
						onSubmitEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setFirstName(e.nativeEvent.text);
							setFirstName(e.nativeEvent.text);
						}}
						onBlur={() => {
							if (!isInCarScreen()) return;
							props?.setFirstName(firstName);
							setFirstName(firstName);
						}}
						value={firstName}
						capitalizeFirstCharacter={true}
					/>
				</StyleHalfInput>
				{/**@ts-ignore */}
				<StyleHalfInput>
					<TextInput
						nativeID={"cardholderLastName"}
						componentTheme={ComponentThemeType.VinFast}
						label={globalAny.language.last_name}
						placeholder={globalAny.language.last_name}
						onChangeText={(text) => {
							if (isInCarScreen()) return;
							props?.setLastName(text);
							setLastName(text);
							props.debouncedOnChange(text);
						}}
						onChange={(e) => {
							if (!isInCarScreen()) return;
							props?.setLastName(e.nativeEvent.text);
							setLastName(e.nativeEvent.text);
							props.debouncedOnChange(e.nativeEvent.text);
						}}
						onEndEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setLastName(e.nativeEvent.text);
							setLastName(e.nativeEvent.text);
						}}
						onSubmitEditing={(e) => {
							if (!isInCarScreen()) return;
							props?.setLastName(e.nativeEvent.text);
							setLastName(e.nativeEvent.text);
						}}
						onBlur={() => {
							if (!isInCarScreen()) return;
							props?.setLastName(lastName);
							setLastName(lastName);
						}}
						value={lastName}
						capitalizeFirstCharacter={true}
					/>
				</StyleHalfInput>
			</StyledNameContainer>
			<View style={{ marginTop: 8 }}>
				<div className="floating-label-content">
					<div id="number-container" className="floating-input" style={stylesInput} />
					<label className="floating-label" id="floating-label" style={stylesCardLabel}>
						<span id="cardNumberPadding" style={{ paddingLeft: 10 }}>
							{globalAny.language.card_number}
						</span>
					</label>
				</div>
			</View>
			{/**@ts-ignore */}
			<StyledNameContainer>
				{/**@ts-ignore */}
				<StyleHalfInput>
					<TextInput
						value={cardExpiry}
						keyboardType={"numeric"}
						nativeID={"expiryDate"}
						componentTheme={ComponentThemeType.VinFast}
						label={globalAny.language.expiry_date}
						placeholder={"MM/YY"}
						onChangeText={(text) => {
							if (text.length > 5 || isInCarScreen()) return;
							props?.setCardExpiry(text);
							setCardExpiry(text);
							formatExpiry(text);
							props.debouncedOnChange();
						}}
						onChange={(e) => {
							const date = e.nativeEvent.text;
							if (date.length > 5 || !isInCarScreen()) return;
							props?.setCardExpiry(date);
							setCardExpiry(date);
							props.debouncedOnChange();
						}}
						onSubmitEditing={(e) => {
							if (!isInCarScreen()) return;
							const date = e.nativeEvent.text;
							formatExpiry(date);
						}}
						onEndEditing={(e) => {
							if (!isInCarScreen()) return;
							const date = e.nativeEvent.text;
							formatExpiry(date);
						}}
						onBlur={() => {
							if (!isInCarScreen()) return;
							formatExpiry(cardExpiry);
						}}
						maxLength={7}
					/>
				</StyleHalfInput>
				{/**@ts-ignore */}
				<StyleHalfInput>
					<div className="floating-label-content">
						<div id="securityCode-container" className="floating-input" style={stylesInput} />
						<label className="floating-label" id="floating-label-securityCode" style={stylesLabel}>
							<span id="securityCodePadding" style={{ paddingLeft: 10 }}>
								CCV/CVC
							</span>
						</label>
					</div>
				</StyleHalfInput>
			</StyledNameContainer>
		</StyledContainer>
	);
};

export default CardDetails;

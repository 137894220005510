import styled from "styled-components/native";
import { StyleSheet } from "react-native";

export const StyledContainer = styled.View`
	margin-top: 112px;
	position: fixed;
	width: 100%;
	background-color: transparent;
	height: calc(100% - 224px);
	flex: 1;
	justify-content: center;
	flex-direction: row;
`;

export const PackshotContainer = styled.View`
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 22%;
`;

export const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "black",
	},

	warningText: {
		color: "white",
		marginTop: "10px",
		textAlign: "center",
		fontWeight: "bold",
	},
});

import { Text, StyleSheet, TouchableOpacity } from "react-native";

export const Title = ({ onPress, children, stepState: { isCompleted, isActive, stepIndex } }: any) => {
	return (
		<TouchableOpacity onPress={() => onPress(stepIndex)}>
			<Text style={styles(isCompleted, isActive).stepperTitle}>{children}</Text>
		</TouchableOpacity>
	);
};

const styles = (isCompleted: any, isActive: any) =>
	StyleSheet.create({
		stepperTitle: {
			fontWeight: "400",
			fontSize: 18,
			lineHeight: 20,
			color: "rgba(242, 242, 242, 1)",
			opacity: isCompleted || isActive ? 1 : 0.4,
			marginTop: -55,
		},
	});

import { CONTENT_BASEURL_V7 } from "../utils/endpoint/baseUrl";
import { fetchWrapper } from "../utils/endpoint/fetch";
import { getHeaders } from "../utils/endpoint/header";

export const getPage = (uniqueId: string, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V7 + "page/" + uniqueId, {
			headers: getHeaders(true),
			signal,
		})
			.then((resJson) => resolve({ pages: resJson.pages }))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

export const getPageShared = (uniqueId: string, signal?: any) => {
	let promise = new Promise((resolve, reject) => {
		fetchWrapper(CONTENT_BASEURL_V7 + "page/shared/" + uniqueId, {
			headers: getHeaders(),
			signal,
		})
			.then((resJson) => resolve({ pages: resJson.pages }))
			.catch((err) => reject(new Error(err)));
	});

	return promise;
};

import IMqttClient from "../../models/IMqttClient";
import type IMqttDeviceBroadcast from "../../models/IMqttDeviceBroadcast";
import { MqttCommand } from "../../models/mqtt-command";
import { MqttBaseService } from "./MqttBaseService";
import { IMediaPlaybackOptions } from "../../models/IMediaPlaybackOptions";
import { MqttDevice, MqttPlayAudioLanguage, MqttPlayContent, MqttPlaySubtitleLanguage } from "../../models/MqttModel";
import IDisposable from "../../models/IDisposable";
import { AppLogger } from "../../utils/AppLogger";

// @injectable()
export default class MqttSenderService extends MqttBaseService implements IDisposable {
	constructor(mqttService: IMqttClient, mqttDeviceBroadcast: IMqttDeviceBroadcast) {
		super(mqttDeviceBroadcast);
		this._mqttService = mqttService;
		this._mqttDeviceBroadcast = mqttDeviceBroadcast;
	}

	public setDeviceBroadcast(deviceInfo?: any) {
		const currentDevice = deviceInfo ?? this._mqttDeviceBroadcast;
		this._mqttDeviceBroadcast = {
			...currentDevice,
			sendOnly: false,
		} as IMqttDeviceBroadcast;
	}

	public setDeviceBroadcastAsSendOnly(deviceInfo?: any) {
		AppLogger.log("[MQTT] Set device broadcast to send only.");
		const currentDevice = deviceInfo ?? this._mqttDeviceBroadcast;
		this._mqttDeviceBroadcast = {
			...currentDevice,
			sendOnly: true,
		} as IMqttDeviceBroadcast;
	}

	public dispose() {
		this._mqttService = null;
		this._mqttDeviceBroadcast = null;
		return null;
	}

	public disconnectDevice() {
		AppLogger.log("[MQTT] Sent disconnect: ", this._mqttDeviceBroadcast);
		this._mqttService?.publish(this.generateCommand(MqttCommand.DisconnectDevice));
		this._mqttService?.end(false);
	}

	public removeScreen() {
		this._mqttService?.publish(this.generateCommand(MqttCommand.DisconnectDevice));
	}

	public broadcastDevice() {
		AppLogger.log("[MQTT] Sent device-broadcast: ", this._mqttDeviceBroadcast);

		this._mqttService?.publish(this.generateCommand(MqttCommand.DeviceBroadcast));
	}

	// Test command
	public testSendCommandTitleDetails() {
		this._mqttService?.publish(
			this.generateCommandWithBody(MqttCommand.SendFromTitleDetails, {
				arg1: "value1",
				arg2: "value2",
			}),
		);
	}

	public discoverAccountDevices() {
		AppLogger.log("[MQTT] Sent discover-account: ", this._mqttDeviceBroadcast);

		this._mqttService?.publish(this.generateCommand(MqttCommand.DiscoverAccount));
	}

	public sendPingCommand(pingId: number = -1) {
		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.Ping, { id: pingId }));
	}

	public sendPingBackCommand(pingId: number) {
		AppLogger.log("pinging back. Current device: ", this._mqttDeviceBroadcast);
		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.PingBack, { id: pingId }));
	}

	// This is used to synchronize connected devices/screens across all opened screens
	public sendSyncDeviceCommand() {
		//note: once this is triggered, this is still sending/syncing the old device name info because we're not updating `this._mqttDeviceBroadcast`.
		AppLogger.log("device to broadcast to other screens: ", this._mqttDeviceBroadcast);
		this._mqttService?.publish(this.generateCommand(MqttCommand.SyncDevice));
	}

	/**
	 * Broadcasts updated device information such as device name change
	 * @param deviceWithOutdatedName
	 * @param deviceWithUpdatedName
	 */
	public broadcastUpdatedDeviceInfo(deviceWithOutdatedName: IMqttDeviceBroadcast, deviceWithUpdatedName: IMqttDeviceBroadcast) {
		this._mqttService?.publish(
			this.generateCommandWithBody(MqttCommand.BroadcastUpdatedDeviceInfo, {
				deviceWithOutdatedName,
				deviceWithUpdatedName,
			}),
		);
	}

	public sendMediaPlaybackOptionsCommand(options: IMediaPlaybackOptions) {
		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaPlaybackOptions, options));
	}

	public playMovieCommand(mqttPlayContent: MqttPlayContent) {
		AppLogger.log("[MQTT] Sent play-movie: ", mqttPlayContent);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.PlayMovie, mqttPlayContent));
	}

	public playVamCommand(mqttPlayContent: MqttPlayContent) {
		AppLogger.log("[MQTT] Sent play-vam: ", mqttPlayContent);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.PlayVam, mqttPlayContent));
	}

	/**
	 * mqtt command send for resuming movie
	 * @param mqttRequest
	 */
	public mediaPlayCommand(mqttRequest: any) {
		AppLogger.log("[MQTT] Sent media-play: ", mqttRequest);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaPlay, mqttRequest));
	}

	/**
	 * mqtt command send for pausing movie
	 * @param mqttRequest
	 */
	public mediaPauseCommand(mqttRequest: any) {
		AppLogger.log("[MQTT] Sent media-pause: ", mqttRequest);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaPause, mqttRequest));
	}

	/**
	 * mqtt command send for restart movie
	 * @param mqttRequest
	 */
	public mediaRestartCommand(mqttRequest: any) {
		AppLogger.log("[MQTT] Sent media-restart: ", mqttRequest);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaRestart, mqttRequest));
	}

	/**
	 * mqtt command send for restart movie
	 * @param mqttRequest
	 */
	public mediaStopCommand(mqttRequest: any) {
		AppLogger.log("[MQTT] Sent media-stop: ", mqttRequest);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaStop, mqttRequest));
	}

	/**
	 * mqtt command send for syncing movie
	 * @param mqttRequest
	 */
	public mediaSyncCommand(mqttPlayContent: MqttPlayContent) {
		AppLogger.log("[MQTT] Sent media-sync: ", mqttPlayContent);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaSync, mqttPlayContent));
	}

	/**
	 * mqtt command for player seek
	 * @param position
	 * @param options
	 */
	public mediaSeekCommand(mqttPlayContent: MqttPlayContent) {
		AppLogger.log("[MQTT] Sent media-seek: ", mqttPlayContent);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaSeek, mqttPlayContent));
	}

	/**
	 * mqtt send command for subtitle change
	 * @param mqttPlaySubtitleLanguage
	 */
	public mediaSubtitleCommand(mqttPlaySubtitleLanguage: MqttPlaySubtitleLanguage) {
		AppLogger.log("[MQTT] Sent media-subtitle: ", mqttPlaySubtitleLanguage);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaSubtitle, mqttPlaySubtitleLanguage));
	}

	/**
	 * mqtt send command for audio change
	 * @param mqttPlayAudioLanguage
	 */
	public mediaAudioCommand(mqttPlayAudioLanguage: MqttPlayAudioLanguage) {
		AppLogger.log("[MQTT] Sent media-audio: ", mqttPlayAudioLanguage);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaAudio, mqttPlayAudioLanguage));
	}

	/**
	 * mqtt command when device sync is done
	 * @param device
	 * @param options
	 */
	public SyncDeviceDoneCommand(device: MqttDevice | undefined, options: any) {
		this._mqttService?.publish(
			this.generateCommandWithBody(MqttCommand.SyncDeviceDone, {
				device,
				parentProductId: options?.parentProductId,
				vamId: options?.vamId,
			}),
		);
	}

	public broadcastPositionCommand(mqttPlayContent: any) {
		AppLogger.log("[MQTT] Sent media-position: ", mqttPlayContent);

		this._mqttService?.publish(this.generateCommandWithBody(MqttCommand.MediaPosition, mqttPlayContent));
	}

	public mediaTrailerCommand(trailerDetails: any) {
		this._mqttService?.publish(
			this.generateCommandWithBody(MqttCommand.PlayTrailer, {
				trailerDetails,
			}),
		);
	}

	public mediaProgressBarCommand(deviceId: any) {
		this._mqttService?.publish(
			this.generateCommandWithBody(MqttCommand.MediaProgressBar, {
				deviceId: deviceId,
			}),
		);
	}
}

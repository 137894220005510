import styled, { css } from "styled-components/native";
import { dark_gray, spacing_l, spacing_s, white_smoke } from "../../StyleHelpers";
import { FooterProps, HeroProps } from "./model";
import { StyleSheet } from "react-native";

//Hero Banner Style
export const StyledHeroContainer = styled.View<HeroProps>`
	padding: ${(props) => (props.heroBanner ? spacing_l : 0)} 0 0 0;
`;

export const StyledMain = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const FeatureText = styled.View<any>`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1.5rem;
	top: 0px;
	left: 153px;
	padding: 5vh 5vw;
	max-width: 45%;
	height: 99.5%;
	z-index: 100;
	border-radius: 16px;
	background-image: linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0) 100%);
	margin: 1px 1px 1px 1px;
`;

export const FeatureLineOne = styled.Text<any>`
	font-size: 2.5vw;
	font-weight: 500;
	font-style: normal;
	line-height: 44px;
	color: ${(props) => props?.hexCode};
	text-overflow: ellipsis;
`;

export const FeatureLineTwo = styled.Text<any>`
	font-size: 1.3125rem;
	font-weight: 500;
	font-style: normal;
	line-height: 1.5625rem;
	color: ${(props) => props?.hexCode};
`;

export const swiperConfig = {
	style: { width: "100%", backgroundColor: "transparent" },
	slidesPerView: 1,
	spaceBetween: 60,
	pagination: {
		clickable: true,
	},
};

export const styles = StyleSheet.create({
	stt: {
		width: 780,
		height: 127,
	},
	defaultStt: {
		width: 400,
		height: 100,
	},
	onHover: {
		marginLeft: 152,
		marginRight: 20,
		aspectRatio: 4 / 1,
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray,
	},
	onLeave: {
		marginLeft: 152,
		marginRight: 20,
		borderRadius: 16,
		aspectRatio: 4 / 1,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray,
	},
});

//Footer Banner Style
export const StyledContainer = styled.View<FooterProps>`
	margin: 0 2rem 3.5rem 2rem;
	padding: 55px 0 0 98px;
	background-color: ${dark_gray};
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-repeat: no-repeat;
			background-size: cover;
		`}

	${(props) =>
		props.borderEvent &&
		css`
			transition: border-color 0.6s ease;
			border-color: ${white_smoke};
		`}

	${(props) =>
		!props.borderEvent &&
		css`
			border-color: rgba(255, 255, 255, 0.3);
		`}

	border-radius: 16px;
	height: 380px;
	border-width: 2px;
`;

export const StyledButton = styled.View`
	margin-top: ${spacing_s};
	width: 368px;
	margin-bottom: 112px;
`;

export const StyledFooterContainer = styled.View`
	margin-left: 7.5rem;
	margin-top: 3.75rem;
`;

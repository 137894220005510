import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Autoplay, Pagination } from "swiper";
import { useState } from "react";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import Image from "../../components/Image/Image";
import { calculateImageWidth } from "../../utils/Utils";
import { Props } from "./model";
import { styles, swiperConfig } from "./styled";

const Banner = (props: Props) => {
	const [borderEvent, setBorderEvent] = useState(false);

	return (
		<Swiper
			{...swiperConfig}
			allowTouchMove={true}
			autoplay={{
				delay: 8000,
				disableOnInteraction: false,
			}}
			modules={[Autoplay, Pagination]}
		>
			{props.item.isBanner &&
				props.item?.products?.map((product: any) => {
					return (
						<SwiperSlide key={`swiper-product-${product.parentProductId}`}>
							<TouchableOpacity
								activeOpacity={1}
								onPress={() => props.onPress(product.parentProductId)}
								onMouseEnter={() => setBorderEvent(true)}
								onMouseLeave={() => setBorderEvent(false)}
							>
								<Image
									source={{
										uri: `${product.packshots}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
									}}
									resizeMode="stretch"
									style={borderEvent ? styles.onHover : styles.onLeave}
								/>
							</TouchableOpacity>
						</SwiperSlide>
					);
				})}
		</Swiper>
	);
};

export default Banner;

import { Container } from "inversify";
import IMqttClient from "./models/IMqttClient";
import MqttConductorService from "./services/mqtt/MqttConductorService";
import AwsIoTMqttDriverPS from "./services/mqtt/driver/AwsIoTMqttDriverPS";

const iocContainer = new Container();
iocContainer.bind<IMqttClient>("IMqttClient").to(AwsIoTMqttDriverPS);
iocContainer.bind<MqttConductorService>("MqttConductorService").to(MqttConductorService);

export { iocContainer };

import { View, Text } from "react-native";
import Marquee from "react-fast-marquee";
import { spacing_l } from "../../StyleHelpers";

interface Props {
	title?: any;
	width?: any;
}
const MarqueeText = (props: Props) => {
	return (
		<View
			style={{
				overflow: "hidden",
				width: props?.width ?? 300,
				backgroundColor: "transparent",
				marginBottom: spacing_l,
			}}
		>
			<Marquee loop={0} speed={50} direction={"left"}>
				<Text
					style={{
						color: "white",
						fontSize: 25,
						fontWeight: "500",
						paddingRight: 100,
					}}
					numberOfLines={1}
				>
					{props.title}
				</Text>
			</Marquee>
		</View>
	);
};

export default MarqueeText;
